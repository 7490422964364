import React, { useEffect, useState } from "react";
import logo from "../../../Assets/SVGs/landing/logo.svg";
import styled from "styled-components";
import { CustomDropdown } from "./CustomDropdown";
import { IconSearch, IconMenu2 } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import MenuBar from "./MenuBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { Button } from "@mantine/core";

const NonUserHeader = () => {
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("tsklng") || "az"
  );
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const changeLang = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLang(lng);
    localStorage.setItem("tsklng", lng);
  };

  const onClose = () => {
    setIsMobileMenuOpen(false);
  };

  const xs = useMediaQuery("(max-width: 1024px)");

  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname

  const handleLogoClick = (e) => {
    e.preventDefault();
    
    // Əgər cari path "/" dirsə
    if (location.pathname === "/") {
      // Səhifənin başına scroll et
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    } else {
      // Əks halda ana səhifəyə navigate et
      navigate("/");
    }
  };

  return (
    <>
      <NonUserHeaderStyled path={path}
        style={
          scrolled
            ? {
                boxShadow: "0 4px 15px 0 rgba(0, 0, 0, 0.1)",
                padding: xs ? "4px 0" : "20px 0",
              }
            : { padding : xs ? "4px 0" : "20px 0" }
        }
      >
        <div className="container">
          <MobileMenuButton
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <IconMenu2 color="#fff" size={24} />
          </MobileMenuButton>

          <LogoContainer onClick={handleLogoClick} to="/">
            <img src={logo} alt="logo" />
          </LogoContainer>

          <NavbarContainer className={isMobileMenuOpen ? "mobile-open" : ""}>
            <CustomDropdown
              buttonLabel={t("about")}
              menuItems={[
                { label: t("about"), link: "/about" },
                { label: "STEAMLAND", link: "/steamland" },
              ]}
            />
            <CustomDropdown
              buttonLabel={t("steam")}
              menuItems={[
                { label: t("steam-bank"), link: "/steam" },
                { label: t("olympic"), link: "/competitions" },
                { label: t("course"), link: "/bootcamps" },
              ]}
            />
            <CustomDropdown
              buttonLabel={t("education")}
              menuItems={[
                { label: t("study-abroad"), link: "/universities" },
                { label: t("tip"), link: "/tip", showOnlyIn: "az" },
              ]}
            />
            <CustomDropdown
              isNoneDropdown={true}
              buttonLabel={t("community")}
              link="/communities"
            />
          </NavbarContainer>

          <RightContainer>
            <IconSearch
              className="search-icon"
              style={{
                borderRight: "1px solid #fff",
                paddingRight: "10px",
                marginRight: "10px",
              }}
              color="#fff"
              size={16}
            />
            <CustomDropdown
              buttonLabel={selectedLang.toUpperCase()}
              menuItems={[
                { label: "AZ", value: "az" },
                { label: "EN", value: "en" },
                { label: "RU", value: "ru" },
                { label: "TR", value: "tr" },
              ]}
              isLanguage={true}
              onItemClick={changeLang}
            />
            <AuthButtons>
              <LoginButton component={Link} to="/login">{t("login")}</LoginButton>
              <RegisterButton component={Link} to="/register">{t("register")}</RegisterButton>
            </AuthButtons>
          </RightContainer>
        </div>
      </NonUserHeaderStyled>

      <MenuBarWrapper isOpen={isMobileMenuOpen}>
        <MenuBar onClose={onClose} />
      </MenuBarWrapper>
    </>
  );
};

export default NonUserHeader;

const NonUserHeaderStyled = styled.div`
 margin-bottom: ${(props) =>
    props.path === "/" ||
    props.path?.includes("tip") ||
    props.path?.includes("overview") ||
    props.path?.includes("week-pathway") ||
    props.path?.includes("section-pathway") ||
    props.path?.includes("task-reviews") ||
    props.path?.includes("universities") ||
    props.path?.includes("payment") ||
    props.path?.includes("agents") ||
    props.path?.includes("steamland") ||
    props.path?.includes("competition")
      ? "0px"
      : "20px"};
  width: 100%;

  background: #029199;
  z-index: 10;
  transition: all 0.5s ease;
  padding: 0.7rem 0;
  box-shadow: 0 4px 15px 0 (0, 0, 0, 0, 1);
  position: sticky; // Əlavə edildi
  top: 0; // Əlavə edildi
  left: 0;
  
  .container {
    position: relative;
    max-width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;



    @media (max-width: 1024px) {
      grid-template-columns: auto auto auto;
      justify-content: space-between;
      gap: 1rem;
      padding: 0.5rem 0;
    }

  }
`;

const LogoContainer = styled(Link)`
  // Logo container
  img {
    height: 28px;

    @media (max-width: 1024px) {
      height: 26px;
      margin-left: 10px;
    }
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #029199;
    flex-direction: column;
    padding: 1rem 0;

    &.mobile-open {
      display: none;
    }

    button {
      padding: 4px 12px;
      font-size: 14px;
    }
  }
`;
const AuthButtons = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1024px) {
    .search-icon {
      display: none;
    }
    ${AuthButtons} {
      display: none;
    }


  }
`;

const LoginButton = styled(Button)`
  background: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
  padding-left: 10px;

  &:hover {
    background: transparent;
    opacity: 0.8;
    color: #ccf075;
  }
`;

const RegisterButton = styled(Button)`
  border-radius: 28px;
  background: #ccf075;
  color: #000;
  border: none;
  padding: 0px 32px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
  height: 40px;

  &:hover {
    background: #ccf075;
    opacity: 0.9;
    color: #000;
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const MenuBarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 80%;
  height: 100vh;
  background: #029199;
  transform: translateX(${(props) => (props.isOpen ? "0" : "-100%")});
  transition: transform 0.3s ease-in-out;
  z-index: 11;
`;
