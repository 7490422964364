import { showNotification } from "@mantine/notifications";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const usePost = () => {
    const queryClient = useQueryClient();
    const {t} = useTranslation()
    const queryKeys = {
        all: (communityId, postTab,label) => ['post',communityId, postTab,label],
        detail: (communityId,postId) => ['post', communityId, postId],
        savedPosts: (communityId,postTab,label) => ['savedPosts',communityId,postTab,label],
        comments: (communityId,postId,page) => ['comments',communityId,postId,page],
        replies: (communityId,postId,commentId,page) => ['replies',communityId,postId,commentId,page],
    }

    const invalidatePostQueries = (communityId,postId,type,commentId,page=1, postTab = "posts",label = null) => {
        if (communityId && postId) {
            if(type === "comments"){
                queryClient.invalidateQueries({ queryKey: queryKeys.comments(communityId,postId,page)});
            } else if(type === "replies" && commentId){
            queryClient.invalidateQueries({ queryKey: queryKeys.replies(communityId,postId,commentId,page)});
            } else if(type === "posts"){
                queryClient.invalidateQueries({ queryKey: queryKeys.all(communityId,postTab,label) });
            } 
            else if(type === "savedPosts"){
                queryClient.invalidateQueries({ queryKey: queryKeys.savedPosts(communityId,postTab,label) });
            }
            else{
                queryClient.invalidateQueries({ queryKey: queryKeys.detail(communityId,postId) });
            }
        }
        else if (communityId){ 
            if(type === "savedPosts"){
                queryClient.invalidateQueries({ queryKey: queryKeys.savedPosts(communityId,postTab,label) });
            }else{
                queryClient.invalidateQueries({ queryKey: queryKeys.all(communityId, postTab,label) });
            }
        }
    }

    const handleSuccess = (message) => {
        showNotification({
            title: t("success"),
            message,
            color: 'green',
        });
    }

    const handleError = (error) => {
        showNotification({
            title: t("error"),
            message: error?.message || t("error_happened"),
            color: 'red',
        });
    }

    return {
        queryKeys,
        invalidatePostQueries,
        handleSuccess,
        handleError,
    }
}
