import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePost } from "./usePost";
import { deleteAnswer } from "../../services/apiPost";
import { useTranslation } from "react-i18next";
export const useDeleteAnswer = ({community_id,post_id,post_tab}) => {
  const { handleError, handleSuccess } = usePost();
  const queryClient = useQueryClient();
  const {t} = useTranslation()
  return useMutation({
    mutationFn: ({ community_id, post_id, question_id, answer_id }) =>
      deleteAnswer({ community_id, post_id, question_id, answer_id }),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['detail',community_id,post_id]});
      queryClient.invalidateQueries({queryKey: ['all',community_id,post_tab]});
      handleSuccess(t("answer_deleted_successfully"));
    },
    onError: (error) => handleError(error),
  });
};
