import { useMutation, useQueryClient } from "@tanstack/react-query";
import { answeredToPost } from "../../services/apiPost";
import { usePost } from "./usePost";

export const useAnsweredToPost = (communityId,type,postTab,label,postId) => {
    const {handleError, invalidatePostQueries, queryKeys} = usePost();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({post_id,question_id,answer_id}) => answeredToPost({community_id:communityId,post_id,question_id,answer_id}),
        onSuccess: () => {
            if(type === "savedPosts"){
                queryClient.invalidateQueries({queryKey: ['savedPosts',communityId,postTab]});
            }else if(type === "sharedPost"){
                queryClient.invalidateQueries({queryKey: ['all',communityId,postTab]});
                queryClient.invalidateQueries({queryKey: ['detail',communityId,postId]});
            }else{
                queryClient.invalidateQueries({queryKey: ['all',communityId,postTab]});
            }
        },
        onError: (error) => {
            handleError(error);
        },
    })
}
