import { Text } from '@mantine/core';
import React from 'react'
import styled from 'styled-components';
import { HalfCircle, LeftVector, RightVector, Rocket } from '../../theme';
import { pathwayThemes } from '../../theme/pathwayTheme';


const getIconSrc = (icon, isSelected, replacedColor) => {
  if (isSelected) {
    return icon?.replace("%23CDCDCD", replacedColor); // stroke rengini değiştir
  }
  return icon?.src;
};

export const NonReversedStage = ({
  sortedData,
  index,
  type,
  handleNavigate,
  tUnit,
}) => {
  return (
    <StageContainer hasTwoData={sortedData?.length > index + 1}>
    {sortedData?.length > index + 1 && (
      <Stage
        type={type}
        onClick={() =>
          // diff
          handleNavigate(sortedData[index].is_completed, index + 1)
        }
        style={
          sortedData?.length > index + 1
            ? { visibility: "visible" }
            : { visibility: "hidden" }
        }
      >
        <Text
          sx={{
            position: "absolute",
            width: "63%",
            textAlign: "right",
            top: "-120px",
            left: "-23%",
          }}
          size={14}
          weight={500}
          color={type === "steam" ? "#029199" : "#686868"}
        >
        {/* diff */}
          {sortedData[index + 1].description.length < 130
            ? sortedData[index + 1].description
            : sortedData[index + 1].description.slice(0, 130) + "..."}
        </Text>
        <VectorWrapper top="-50px" left="55px">
          <RightVector theme={pathwayThemes[type]} />
        </VectorWrapper>
        <div className="white-circle">
          {/* diff */}
          {sortedData[index + 1].is_completed ? (
            <img
              style={{ width: "35px", height: "35px" }}
              src={getIconSrc(
                // diff
                sortedData[index + 1] && sortedData[index + 1][`${tUnit}_icon`],
                true,
                type === "steam" ? "%23B1DD47" : "%23FFCC42"
              )}
              // diff
              alt={`badge-${sortedData[index + 1].id}`}
            />
          ) : (
            index + 2
          )}
        </div>
      </Stage>
    )}

    {sortedData?.length > index + 1 && <Line type={type} />}

    <Stage
      type={type}
      onClick={() =>
        // diff
        handleNavigate(sortedData[index - 1].is_completed, index)
      }
    >
      <Text
        sx={{
          position: "absolute",
          width: "63%",
          textAlign: "left",
          top: "-120px",
          right: "-23%",
        }}
        size={14}
        weight={500}
        color={type === "steam" ? "#029199" : "#686868"}
      >
        {/* diff */}
        {sortedData[index].description.length < 130
          ? sortedData[index].description
          : sortedData[index].description.slice(0, 130) + "..."}
      </Text>
      <VectorWrapper top="-50px" right="55px">
        <LeftVector theme={pathwayThemes[type]} />
      </VectorWrapper>

      <div className="white-circle">
        {/*  */}
        {sortedData[index].is_completed ? (
          <img
            style={{ width: "35px", height: "35px" }}
            src={getIconSrc(
              // diff
              sortedData[index] && sortedData[index][`${tUnit}_icon`],
              true,
              type === "steam" ? "%23B1DD47" : "%23FFCC42"
            )}
            // diff
            alt={`badge-${sortedData[index].id}`}
          />
        ) : (
          index + 1
        )}
      </div>
    </Stage>
    {sortedData?.length > index + 1 && (
      <RocketIconWrapper rotate={"true"}>
        <Rocket theme={pathwayThemes[type]} />
      </RocketIconWrapper>
    )}

    {sortedData?.length > index + 2 && (
      <HalfCircleWrapper transform="rotate(-180deg)" left="-132px" top="41px">
        <HalfCircle theme={pathwayThemes[type]} />
      </HalfCircleWrapper>
    )}
  </StageContainer>
  )
}

export const ReversedStage = ({
  sortedData,
  index,
  type,
  handleNavigate,
  tUnit,
}) => {
  return (
    <StageContainer hasTwoData={!(sortedData?.length > index + 1)}>
    <Stage
      type={type}
      onClick={() =>
        handleNavigate(sortedData[index - 1].is_completed, index)
      }
    >
      <Text
        sx={{
          position: "absolute",
          width: "63%",
          textAlign: !(sortedData?.length > index + 1) ? "right" : "left",
          top: "-120px",
          ...(!(sortedData?.length > index + 1) 
            ? { left: "-23%" }
            : { right: "-23%" }
          ),
        }}
        size={14}
        weight={500}
        color={type === "steam" ? "#029199" : "#686868"}
      >
        {sortedData[index].description.length < 130
          ? sortedData[index].description
          : sortedData[index].description.slice(0, 130) + "..."}
      </Text>
        <VectorWrapper top="-50px" left="55px">
          <RightVector theme={pathwayThemes[type]} />
        </VectorWrapper>
      <div className="white-circle">
        {sortedData[index].is_completed ? (
          <img
            style={{ width: "35px", height: "35px" }}
            src={getIconSrc(
              sortedData[index] && sortedData[index][`${tUnit}_icon`],
              true,
              type === "steam" ? "%23B1DD47" : "%23FFCC42"
            )}
            alt={`badge-${sortedData[index].id}`}
          />
        ) : (
          index + 1
        )}
      </div>
    </Stage>

    {sortedData?.length > index + 1 && <Line type={type} />}
    {sortedData?.length > index + 1 && (
      <Stage
        type={type}
        onClick={() =>
          handleNavigate(sortedData[index].is_completed, index + 1)
        }
        style={
          sortedData?.length > index + 1
            ? { visibility: "visible" }
            : { visibility: "hidden" }
        }
      >
        <Text
          sx={{
            position: "absolute",
            width: "63%",
            textAlign: "right",
            top: "-120px",
            left: "-23%",
          }}
          size={14}
          weight={500}
          color={type === "steam" ? "#029199" : "#686868"}
        >
          {sortedData[index + 1].description.length < 130
            ? sortedData[index + 1].description
            : sortedData[index + 1].description.slice(0, 130) + "..."}
        </Text>
        <VectorWrapper top="-50px" right="55px">
          <LeftVector theme={pathwayThemes[type]} />
        </VectorWrapper>
        <div className="white-circle">
          {sortedData[5].is_completed ? (
            <img
              style={{ width: "35px", height: "35px" }}
              src={getIconSrc(
                sortedData[index + 1] && sortedData[index + 1][`${tUnit}_icon`],
                true,
                type === "steam" ? "%23B1DD47" : "%23FFCC42"
              )}
              alt={`badge-${sortedData[index + 1].id}`}
            />
          ) : (
            index + 2
          )}
        </div>
      </Stage>
    )}

    {sortedData?.length > index + 1 && (
      <RocketIconWrapper rotate={"false"}>
        <Rocket theme={pathwayThemes[type]} />
      </RocketIconWrapper>
    )}

    {sortedData?.length > index + 2 && (
      <HalfCircleWrapper right="-132px" top="41px">
        <HalfCircle theme={pathwayThemes[type]} />
      </HalfCircleWrapper>
    )}
  </StageContainer>
  )
}


export const StageContainer = styled.div`
  width: 60%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    !props.hasTwoData ? "flex-end" : "space-between"};
  position: relative;
  margin-bottom: 148px;
  
`;

export const Stage = styled.div`
  cursor: pointer;
  width: 83px;
  height: 83px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.type === "steam"
      ? "linear-gradient(0deg, #cdf075 0.03%, #9bca2a 100%)"
      : "linear-gradient(0deg, #FFDA75 0.03%, #FFCA3B 100%)"};
  .white-circle {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    font-weight: 500;
    color: ${(props) => (props.type === "steam" ? "#a0ce31" : "#FFCC42")};
    cursor: pointer;
  }
    @media (max-width: 1440px) {
      width: 80px;
      height: 80px;
      .white-circle{
        width: 55px;
        height: 55px;
      }
    }
    @media (max-width: 1280px) {
      width: 78px;
      height: 78px;
      .white-circle{
        width: 53px;
        height: 53px;
      }
    }
    @media (max-width: 1120px) {
      width: 76px;
      height: 76px;
      .white-circle{
        width: 51px;
        height: 51px;
      }
    }
    @media (max-width: 990px) {
      width: 74px;
      height: 74px;
      .white-circle{
        width: 49px;
        height: 49px;
      }
    }
    @media (max-width: 864px) {
      width: 72px;
      height: 72px;
      .white-circle{
        width: 47px;
        height: 47px;
      }
    }
  @media (max-width: 768px) {
    width: 68px;
    height: 68px;
    .white-circle {
      width: 45px;
      height: 45px;
      font-size: 20px;
    }
  }
`;

export const RocketIconWrapper = styled.div`
  position: absolute;
  width: 171px;
  height: 44px;
  top: 50%;
  left: 50%;
  transform: ${(props) =>
    props.rotate === "true"
      ? "translate(-40%, -50%) rotate(180deg)"
      : "translate(-50%, -50%) rotate(0deg)"};
`;

export const VectorWrapper = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  bottom: ${(props) => props.bottom};
  right: ${(props) => props.right};
`;

export const HalfCircleWrapper = styled.div`
  position: absolute;
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  bottom: ${(props) => props.bottom};
  transform: ${(props) => props.transform};
`;

export const Line = styled.div`
  width: 70%;
  height: 1.5px;
  background: ${(props) => (props.type === "steam" ? "#029199" : "#FFCC42")};
`;