import React, { useContext, useEffect, useState } from "react";
import Footer from "../Components/NewDashboard/Footer";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import NavbarContent from "../Components/NewDashboard/Navbar";
import AsideContent from "../Components/NewDashboard/Aside";
import { AppContext } from "../Helpers/Context";
import UserHeader from "../Components/NewDashboard/Header/UserHeader";
import NonUserHeader from "../Components/NewDashboard/Header/NonUserHeader";
import { useTranslation } from "react-i18next";
import SteamSidebarContent from "../Components/NewDashboard/Sidebar/SteamSidebarContent";
import { useQuery } from "@tanstack/react-query";
import LottieLoading from "../Components/Loaders/LottieLoading";
import axios from "../Helpers/Axios";

export const MainLayout = ({ props }) => {
  const { isSidebar, isAside } = props;
  let { user } = useContext(AppContext);
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <LayoutContainer pathname={pathname}>
      <div className="container">
        {user ? <UserHeader /> : !user ? <NonUserHeader /> : null}

        <Container
          isAside={isAside}
          isSidebar={isSidebar}
          className="container"
        >
          <Flex>
            {isSidebar && (
              <SideBarContainer
                pathname={pathname}
                user={user}
                isSidebar={isSidebar}
                isAside={isAside}
              >
                <NavbarContent t={t} />
              </SideBarContainer>
            )}
            <MainContainer
              pathname={pathname}
              isAside={isAside}
              isSidebar={isSidebar}
            >
              <Outlet />
            </MainContainer>
            {isAside && (
              <AsideContainer user={user} isAside={isAside}>
                <AsideContent t={t} />
              </AsideContainer>
            )}
          </Flex>
        </Container>

        <footer>
          <Footer />
        </footer>
      </div>
    </LayoutContainer>
  );
};

export const SteamLayout = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [steamStatus, setSteamStatus] = useState("");
  const newArr = pathname.split("/");
  const type = newArr[1];
  const { isLoading: appliedLoading } = useQuery({
    queryKey: ["isApplied", id],
    queryFn: async () => {
      const response = await axios.get(
        `/${type === "bootcamp" ? "bootcamp" : "steam/steam"}/${id}/apply`
      );
      return response.data;
    },
    onSuccess: (data) => {
      setSteamStatus(data?.status);
    },

    enabled: !!id, // Moved inside the options object
  });

  useEffect(() => {
    if (steamStatus === "Fail") {
      navigate(`/${type}/${id}`);
    }
  }, [steamStatus, navigate, id, type]);

  if (appliedLoading) {
    return <LottieLoading />;
  }

  return (
    <>
      <UserHeader />
      <SteamFlexBox>
        <SteamSideBar>
          <SteamSidebarContent type={type} id={id} />
        </SteamSideBar>
        <NavigationBar>
          <SteamSidebarContent type={type} id={id} />
        </NavigationBar>

        <SteamMainSide path={pathname}>
          <div className="outlet-wrapper">
            <Outlet />
          </div>
        </SteamMainSide>
      </SteamFlexBox>
    </>
  );
};
const SteamSideBar = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 15px 0px #0000001a;
  position: fixed;
  top: 73px;
  width: 19%;
  @media (max-width: 1440px) {
    width: 20%;
  }
  @media (max-width: 1280px) {
    width: 21%;
  }
  @media (max-width: 1120px) {
    width: 24%;
  }
  height: 100vh;
  z-index: 9;
  @media screen and (max-width: 990px) {
    display: none;
  }
`;

const NavigationBar = styled.div`
  box-shadow: 0px -1px 5px 0px #0000000d;
  background-color: #fff;
  margin: 0 10px;
  @media screen and (min-width: 990px) {
    display: none;
  }
`;

const SteamMainSide = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  background-color: ${(props) =>
    props.path.includes("day") || props.path.includes("task-comment")
      ? "#EFF3F4"
      : "#fffefd"};
  .outlet-wrapper {
  @media (min-width: 1441px) {
    width: 81%;
    }
    @media (max-width: 1440px) {
      width: 80%;
    }
    @media (max-width: 1280px) {
      width: 79%;
    }
    @media (max-width: 1120px) {
      width: 76%;
    }

  }
  @media screen and (max-width: 990px) {
    .outlet-wrapper {
      width: 100%;
    }
  }
`;

const SteamFlexBox = styled.div`
  display: flex;
  max-width: 1500px;
  margin: 0 auto;
  @media screen and (max-width: 990px) {
    flex-direction: column;
  }
`;

const LayoutContainer = styled.div`
  background-color: ${(props) =>
    props.pathname === "/home"
      ? "#f2f2f2"
      : props.pathname === "/profile"
      ? "#eff3f4"
      : props.pathname.includes("/courses")
      ? "#f6f6f6"
      : props.pathname.includes("/steam")
      ? "#F6F6F6"
      : props.pathname.includes("/communities")
      ? "#F6F6F6"
      : props.pathname.includes("/categories")
      ? "#f6f6f6"
      : props.pathname.includes("/cover-photo")
      ? "#f6f6f6"
      : props.pathname.includes("/community")
      ? "#F6F6F6"
      : "#fff"};

  @media screen and (max-width: 576px) {
    background-color: ${(props) =>
      props.pathname.includes("/subscribtion") ? "#f2f2f2" : ""};
  }
`;

const Container = styled.div`
  max-width: ${(props) =>
    props.isAside || props.isSidebar ? "1220px" : "100%"};
  @media screen and (max-width: 1440px) {
    max-width: ${(props) =>
      props.isAside || props.isSidebar ? "1140px" : "100%"};
  }
  @media screen and (max-width: 1280px) {
    max-width: ${(props) =>
      props.isAside || props.isSidebar ? "1080px" : "100%"};
  }
  @media screen and (max-width: 1120px) {
    max-width: ${(props) =>
      props.isAside || props.isSidebar ? "970px" : "100%"};
  }
  @media screen and (max-width: 990px) {
    max-width: ${(props) =>
      props.isAside || props.isSidebar ? "720px" : "100%"};
  }
  @media screen and (max-width: 720px) {
    max-width: ${(props) =>
      props.isAside || props.isSidebar ? "576px" : "100%"};
  }
  @media screen and (max-width: 576px) {
    max-width: ${(props) =>
      props.isAside || props.isSidebar ? "95%" : "100%"};
  }
  margin: 0 auto;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SideBarContainer = styled.div`
  width: ${(props) => (props.isSidebar ? "240px" : "0%")};
  
  section {
    position: sticky;
    top: ${(props) => (props.user && !props.pathname.includes("/owner") ? "-195px" :  props.user && props.pathname.includes("/owner") ? "-220px" : "100px")};
    margin-bottom : 20px;
  }

  @media screen and (max-width: 1440px) {
    width: ${(props) => (props.isAside ? "220px" : "240px")};
  }
  @media screen and (max-width: 1280px) {
    width: ${(props) => (props.isAside ? "200px" : "240px")};
  }
  @media screen and (max-width: 1120px) {
    width: ${(props) => (props.isAside ? "200px" : "240px")};
  }
  @media screen and (max-width: 720px) {
    display: none;
  }
`;

const AsideContainer = styled.div`
  width: ${(props) => (props.isAside ? "260px" : "0%")};
  position: sticky;
  top: ${(props) => (props.user ? "-195px" : "-190px")};
  @media screen and (max-width: 1440px) {
    width: 240px;
  }
  @media screen and (max-width: 1280px) {
    width: 220px;
  }
  @media screen and (max-width: 1120px) {
    width: 220px;
  }
  @media screen and (max-width: 990px) {
    display: none;
  }

  @media screen and (max-width: 720px) {
    display: none;
  }
`;

const MainContainer = styled.div`
  width: ${(props) =>
    props.isAside && props.isSidebar
      ? "650px"
      : props.isSidebar && !props.isAside
      ? "930px"
      : "100%"};

@media (min-width: 1600px) {
  width: ${(props) =>
    props.pathname.includes("/steamland") ||
    props.pathname === "/" ||
    props.pathname.includes("/universities") ||
    props.pathname.includes("/competition")
      ? "100%"
      : props.isAside && props.isSidebar
      ? "620px"
      : props.isSidebar && !props.isAside
      ? "930px"

      : "1500px"};
  }
      margin: 0 auto;
}
  @media screen and (max-width: 1440px) {
    width: ${(props) =>
      props.isAside && props.isSidebar
        ? "620px"
        : props.isSidebar && !props.isAside
        ? "870px"
        : "100%"};
  }
  @media screen and (max-width: 1280px) {
    width: ${(props) =>
      props.isAside && props.isSidebar
        ? "600px"
        : props.isSidebar && !props.isAside
        ? "810px"
        : "100%"};
  }
  @media screen and (max-width: 1120px) {
    width: ${(props) =>
      props.isAside && props.isSidebar
        ? "470px"
        : props.isSidebar && !props.isAside
        ? "700px"
        : "100%"};
  }
  @media screen and (max-width: 990px) {
    width: ${(props) =>
      props.isAside && props.isSidebar
        ? "470px"
        : props.isSidebar && !props.isAside
        ? "450px"
        : "100%"};
  }
    
  @media screen and (max-width: 720px) {
    width: ${(props) =>
      props.pathname.includes("/steamland") ||
      props.pathname.includes("/universities") ||
      props.pathname.includes("/steam") ||
      props.pathname.includes("/bootcamp") ||
      props.pathname.includes("/tip") ||
      props.pathname.includes("/competition") ||
      props.pathname === "/"
        ? "100%"

        : "90%"};
    margin: 0 auto;
  }
`;
