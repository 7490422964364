import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteHighlightPostImage } from "../../services/apiHighlight";
import { useHighlight } from "./useHighlight";
import { useTranslation } from "react-i18next";
export const useDeleteHighlightPostImage = ({community_id, highlight_id, post_id,post_tab}) => {
    const {handleError, handleSuccess} = useHighlight();
    const queryClient = useQueryClient();
    const {t} = useTranslation()
    return useMutation({
        mutationFn: ({community_id, highlight_id, post_id, image_id}) => deleteHighlightPostImage({community_id, highlight_id, post_id, image_id}),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['highlight-post',community_id,highlight_id,post_id]});
            queryClient.invalidateQueries({queryKey: ['highlight-posts',community_id,highlight_id,post_tab]});
            handleSuccess(t("highlight_post_image_deleted_successfully"));
        },
        onError: (error) => handleError(error),
    })
}
