import { Box, Skeleton, Group, Stack } from "@mantine/core";
import styled from "styled-components";

const CompetitionCardSkeleton = () => {
  return (
    <CardWrapper>
      <Box className="card-content">
        <Box className="card-body">
          <Box>
            <Group position="apart" mb={0}>
              <Skeleton height={24} width="60%" radius="md" />
              <Skeleton height={20} width={20} radius="md" />
            </Group>

            <Group mt={10} position="apart" mb={15}>
              <Group style={{ width: "100%" }} position="apart">
                <Stack spacing={8}>
                  <Skeleton height={18} width={80} radius="md" />
                  <Group spacing={8}>
                    <Skeleton height={14} width={10} radius="md" />
                    <Skeleton height={18} width={80} radius="md" />
                  </Group>
                </Stack>
                <Stack spacing={1}>
                  <Group spacing={5}>
                    <Skeleton height={13} width={13} radius="md" />
                    <Skeleton height={16} width={80} radius="md" />
                  </Group>
                  <Group spacing={5}>
                    <Skeleton height={13} width={13} radius="md" />
                    <Skeleton height={16} width={80} radius="md" />
                  </Group>
                </Stack>
              </Group>
            </Group>

            <Group spacing={4} mb={15}>
              {[1, 2, 3].map((_, index) => (
                <Skeleton key={index} height={28} width={80} radius="md" />
              ))}
            </Group>
          </Box>
          
          <Box className="card-footer">
            <Group position="apart" mb={15} mt={15}>
              <Group align="center" spacing={4}>
                <Skeleton height={35} width={35} radius="xl" />
                <Skeleton height={18} width={100} radius="md" />
              </Group>
              <Group align="center" spacing={4}>
                <Skeleton height={13} width={13} radius="md" />
                <Skeleton height={16} width={80} radius="md" />
              </Group>
            </Group>
            <Skeleton height={40} width="100%" radius={12} />
          </Box>
        </Box>
      </Box>
    </CardWrapper>
  );
};

export default CompetitionCardSkeleton;

const CardWrapper = styled.div`
  height: 100%;

  .card-content {
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 3.65px 13.68px 0px #0000001a;
    height: 90%;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
`;