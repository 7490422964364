import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import { Button, Group, Text } from "@mantine/core";
import { motion } from "framer-motion";
import greenVector from "../../Assets/SVGs/steams/green_vector.svg";
import hand from "../../Assets/SVGs/steams/hand.svg";
import videoPreview from "../../Assets/SVGs/steams/video_preview.png";
import player_icon from "../../Assets/SVGs/steams/player_icon.svg";
import icon1 from "../../Assets/SVGs/steams/sm_icon1.svg";
import icon2 from "../../Assets/SVGs/steams/sm_icon2.svg";
import icon3 from "../../Assets/SVGs/steams/sm_icon3.svg";
import icon4 from "../../Assets/SVGs/steams/sm_icon4.svg";
import AnimateCircle from "../../Components/Animation/CircleAnimate";
import robot from "../../Assets/Lottie/robot.json";
import Lottie from "lottie-react";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

const typingAnimation = {
  hidden: { width: 0 },
  visible: {
    width: "100%",
    transition: {
      duration: 2, // Animasyon süresi
      ease: "easeInOut",
    },
  },
};

const contentTransition = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      ease: "easeOut",
    },
  },
};

const handAnimation = {
  animate: {
    rotate: [0, 15, -15, 10, -10, 5, -5, 0], // Selamlama hareketi
    transition: {
      duration: 1.5,
      repeat: Infinity,
      repeatDelay: 1,
      ease: "easeInOut",
    },
  },
};

const SteamInfo = ({ type }) => {
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const xs392 = useMediaQuery("(max-width: 392px)");

  const xs576 = useMediaQuery("(max-width: 576px)");
  const xs = useMediaQuery("(max-width: 768px)");
  const lg = useMediaQuery("(max-width: 1280px)");
  const md = useMediaQuery("(max-width: 1024px)");
  const sm = useMediaQuery("(max-width: 990px)");
  const { t } = useTranslation();
  const { id } = useParams();

  
  useEffect(() => {
    if (window.innerWidth > 576 && window.innerWidth < 1280) {
      setIsAnimationComplete(true);
    }
  }, []);





  const getSeoTitle = () => {
    if (type === "steam") {
      return t("steam_info_title", "STEAM Information | Taskool");
    }
    return t("bootcamp_info_title", "Bootcamp Information | Taskool");
  };

  const getSeoDescription = () => {
    if (type === "steam") {
      return t(
        "steam_info_description",
        "Learn about STEAM education and its importance in Taskool. Discover how STEAM integrates Science, Technology, Engineering, Arts, and Mathematics."
      );
    }
    return t(
      "bootcamp_info_description",
      "Explore intensive bootcamp programs at Taskool. Learn practical skills through hands-on training and expert guidance."
    );
  };


  return (
    <SteamInfoStyle>

      <Helmet>
        <title>{getSeoTitle()}</title>
        <meta name="description" content={getSeoDescription()} />
      </Helmet>

      {/* Mevcut bileşen içeriği */}
      {!xs392 && !(window.innerWidth > 768 && window.innerWidth < 1280) && (
        <AnimationContainer
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
          onAnimationComplete={() => setIsAnimationComplete(true)}

        >
          <img
            style={{
              width: xs576 ? "70%" : "100%",
              height: xs576 ? "70%" : "100%",
            }}
            className="background_vector"
            src={greenVector}
            alt="Green BG"
          />
        </AnimationContainer>
      )}
      {!xs392 && !(window.innerWidth > 768 && window.innerWidth < 1280) && (
        <AnimationContainer
          style={{
            top: xs576 ? "-10px" : xs ? "-20px" : "20px",

            left: "-20px",
          }}
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
          onAnimationComplete={() => setIsAnimationComplete(true)}
        >
          <Lottie
            animationData={robot}
            style={{
              overflow: "hidden",
              rotate: "35deg",
              width: xs576 ? "80%" : "100%",
              height: xs576 ? "10vh" : "20vh",
            }}
          />
        </AnimationContainer>
      )}

      <ContentContainer type={type}>
        <div style={{ paddingTop: xs576 ? "0px" : xs ? "20px" : "80px" }}>
          {isAnimationComplete && (
            <div
              style={
                xs
                  ? {
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }
                  : {}
              }
            >
              <div
                style={{
                  maxWidth: xs392
                    ? "100%"
                    : xs576
                    ? "80%"
                    : xs
                    ? "80%"
                    : "100%",
                }}
              >
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={typingAnimation}
                  style={{ overflow: "hidden", whiteSpace: "nowrap" }}
                  onAnimationComplete={() => setIsTypingComplete(true)}
                >
                  <Text
                    size={xs576 ? 16 : xs ? 18 : 32}
                    weight={700}
                    color="#029199"
                  >
                    {type === "steam"
                      ? "STEAM Səyahətinizə Xoş Gəldiniz!"
                      : "Welcome!..."}
                  </Text>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.8,
                    delay: 0.3, // Ana başlıktan sonra görünmesi için
                    ease: "easeOut",
                  }}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Text
                    size={xs576 ? 14 : xs ? 16 : 20}
                    weight={500}
                    color="#029199"
                  >
                    {type === "steam"
                      ? "Əziz gənc kəşfiyyatçı, Taskool STEAM layihəsinə qoşulmağınız münasibətilə sizi səmimi qəlbdən təbrik edirik! Siz artıq gələcəyin açarını əlində tutan innovasiya keşikçilərindən birisiniz."
                      : "Lorem Ipsum is simply dummy text of the printing and typesetting industry."}
                  </Text>
                </motion.div>
              </div>
            </div>
          )}
          {isAnimationComplete && isTypingComplete && (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={contentTransition}
            >
              <div className="steam-about">
                <div className="circle_icon">
                  <AnimateCircle />
                </div>
                <Group>
                  <motion.img
                    src={hand}
                    alt="Hand Icon"
                    variants={handAnimation}
                    animate="animate"
                  />
                  <Text size={xs ? 18 : 24} weight={700} color="#3E3E3E">
                    {type === "steam" ? "STEAM nədir?" : "What is Bootcamp?"}
                  </Text>
                  <Text size={xs ? 18 : 24} weight={700} color="#3e3e3e">
                    {type === "steam" ? "STEAM" : "Bootcamp"}{" "}
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#3e3e3e",
                      }}
                    >
                      {type === "bootcamp" ? (
                        "Fact that a reader will be distracted..."
                      ) : (
                        <>
                          təhsili sadəcə bir təlim metodu deyil - bu, dünyanı
                          dəyişdirmək üçün güclü bir alətdir. Elm, texnologiya,
                          mühəndislik, incəsənət və riyaziyyatın möhtəşəm
                          sintezi sizə problemləri yaradıcı həll etmək, mürəkkəb
                          məsələlərə innovativ yanaşmaq və gələcəyin
                          çağırışlarına hazır olmaq imkanı verir. Bizim STEAM
                          layihələrimiz vasitəsilə siz nəzəri bilikləri real
                          həyatda tətbiq etməyi öyrənəcək, komanda işinin gücünü
                          kəşf edəcək və öz ideyalarınızı həyata keçirmək üçün
                          lazımi bacarıqlara yiyələnəcəksiniz.
                          <br />
                          <br />
                          Bu səyahətdə siz tənqidi düşünmə, problem həll etmə,
                          yaradıcılıq, ünsiyyət və əməkdaşlıq kimi əvəzolunmaz
                          yumşaq bacarıqlar qazanacaqsınız. Hər bir layihə sizə
                          liderlik, vaxtın idarə edilməsi və çevik düşünmə
                          qabiliyyətlərinizi inkişaf etdirmək üçün unikal
                          fürsətlər təqdim edəcək.
                          <br />
                          <br />
                          Unutmayın ki, bu gün öyrəndiyiniz hər bir yeni bacarıq
                          sabahın uğurları üçün möhkəm təməldir. STEAM ilə siz
                          təkcə öyrənməyəcək, həm də kəşf edəcək, yaradacaq və
                          innovasiya edəcəksiniz!
                          <br />
                          <br />
                          Gəlin birlikdə gələcəyi formalaşdıraq!
                        </>
                      )}
                    </span>
                  </Text>
                </Group>
              </div>
              <div className="steam-video">
                <img
                  className="video_preview"
                  src={videoPreview}
                  alt="Preview Img"
                />
                <img
                  className="player_icon"
                  src={player_icon}
                  alt="Player icon"
                />
              </div>
              <Button
                fullWidth
                component={Link}
                to={`/${type}/${id}/${
                  type === "steam" ? "week" : "section"
                }-pathway`}
                mt={30}
                radius={10}
                styles={() => ({
                  root: {
                    backgroundColor: `${
                      type === "steam" ? "#CCF075" : "#FE6027"
                    }`,
                    color: `${type === "steam" ? "#3e3e3e" : "#fff"}`,
                    width: "100%",
                    height: "56px",
                    fontSize: "18px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: `${
                        type === "steam" ? "#CCF075" : "#FE6027"
                      }`,
                    },
                  },
                })}
              >
                Start
              </Button>
              {!xs && (
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={contentTransition}
                >
                  <motion.img
                    className="sm_icon one"
                    src={icon1}
                    alt="Icon 1"
                    animate={{ y: [0, 20, 0] }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut",
                    }}
                  />
                  <motion.img
                    className="sm_icon two"
                    src={icon2}
                    alt="Icon 2"
                    animate={{ x: [0, -20, 0] }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut",
                    }}
                  />
                  <motion.img
                    className="sm_icon three"
                    src={icon3}
                    alt="Icon 3"
                    animate={{ y: [0, -20, 0] }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut",
                    }}
                  />
                  <motion.img
                    className="sm_icon four"
                    src={icon4}
                    alt="Icon 4"
                    animate={{ x: [0, 20, 0] }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut",
                    }}
                  />
                </motion.div>
              )}
            </motion.div>
          )}
        </div>
      </ContentContainer>
    </SteamInfoStyle>
  );
};

export default SteamInfo;

const SteamInfoStyle = styled.div`
  position: relative;
  .sm_icon {
    position: absolute;
  }
  .one {
    bottom: 30%;
    right: 8%;
    z-index: 1;
  }
  .two {
    top: 30%;
    right: 5%;
    z-index: 2;
  }
  .three {
    top: 35%;
    left: 5%;
    z-index: 3;
  }
  .four {
    bottom: 20%;
    left: 6%;
    z-index: 4;
  }

  @media (max-width: 768px) {
    margin-top: 70px;
  }

  @media (max-width: 392pxpx) {
    .background_vector {
      display: none;
    }
  }
  @media (min-width: 768px and max-width: 1024px) {
    .background_vector {
      display: none;
    }
  }
`;

const ContentContainer = styled.div`
  max-width: 848px;
  margin: 0 auto 50px auto;
  @media (max-width: 1440px) {
    max-width: 730px;
  }
    @media (max-width: 1280px) {
      max-width: 700px;
    }
  @media (max-width: 1120px) {
    max-width: 600px;
  }
  .steam-video {
    height: 405px;
    width: 100%;
    background-color: ${(props) =>
      props.type === "steam" ? "#029199" : "#FFCA3B"};
    border-radius: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .video_preview {
      width: 95%;
      height: 90%;
      border-radius: 19px;
      object-fit: cover;
      @media (max-width:768px){
        width: 90%;
        height: 85%;
        
      }
    }

    .player_icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media (max-width: 768px) {
      width : 100%;
      height: 264px;
    }
  }

  .steam-about {
    background-color: ${(props) =>
      props.type === "steam" ? "#ffca3b" : "#CDF075"};
    border-radius: 19px;
    padding: 35px 40px;
    margin: 40px 0;
    position: relative;
    .circle_icon {
      position: absolute;
      right: -30px;
      top: -40px;

      @media (max-width: 768px) {
        right: 5px;
        top : -50px

        width: 47px;
        height: 61px;
      }
    }
    @media (max-width: 768px) {
      padding: 25px 30px;
    }
  }
  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const AnimationContainer = styled(motion.div)`
  position: absolute;
  top: -10px;
  left: -10px;
  width: 158px;
  height: 239px;
  @media (max-width: 768px) {
    width: 134px;
    height: 202px;
    top: -20px;
  }
`;
