import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deletePostImage } from "../../services/apiPost";
import { usePost } from "./usePost";
import { useTranslation } from "react-i18next";

export const useDeletePostImg = (community_id,post_tab) => {
    const {handleError, handleSuccess} = usePost();
    const queryClient = useQueryClient();
    const {t} = useTranslation()
    return useMutation({
        mutationFn: ({community_id,post_id,image_id}) => deletePostImage({community_id,post_id,image_id}),
        onSuccess: (_,variables) => {
            queryClient.invalidateQueries({queryKey: ['detail',community_id,variables.post_id]});
            queryClient.invalidateQueries({queryKey: ['all',community_id,post_tab]});
            handleSuccess(t("post_image_deleted_successfully"));
        },
        onError: (error) => handleError(error),
    })
}
