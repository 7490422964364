import { useMutation, useQueryClient } from "@tanstack/react-query";
import { pinHighlightPost } from "../../services/apiHighlight";
import { useHighlight } from "./useHighlight";
import { useTranslation } from "react-i18next";
export const usePinHighlightPost = ({community_id,highlightId,post_id,post_tab,label}) => {
    const {handleSuccess,handleError} = useHighlight();
    const queryClient = useQueryClient();
    const {t} = useTranslation()
    return useMutation({
        mutationFn: ({community_id,highlightId,post_id}) => pinHighlightPost({community_id,highlightId,post_id}),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['highlight-posts',community_id,highlightId,post_tab]});
            handleSuccess(t("post_pinned_successfully"));
        },
        onError: (error) => handleError(error),
    });

}