import { React, useState } from "react";
import styled from "styled-components";
import { Button, Grid, Stack, Text, Title } from "@mantine/core";
import bg from "../../Assets/SVGs/landing/vectorBackground.svg";
import LandingCard from "../Cards/LandingCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { useMediaQuery } from "@mantine/hooks";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link, useNavigate } from "react-router-dom";

export const RightBox = ({ props }) => {
  const { t, rightBoxDatas, generalSectionData } = props;
  const matches = useMediaQuery("(max-width: 576px)");
  const navigate = useNavigate();

  const leftVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2,
        duration: 0.5,
      },
    }),
  };

  const cardVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });

  const [clickedIndex, setClickedIndex] = useState(null);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const handleClick = (index, card) => {
    if (isMobile) {
      if (clickedIndex === index) {
        navigate(card.to);
      } else {
        setClickedIndex(index);
      }
    } else {
      navigate(card.to);
    }
  };

  const handleSlideChange = () => {
    setClickedIndex(null);
  };

  const handleBackgroundClick = (e) => {
    // Check if the click is outside of the Swiper container
    const swiperContainer = document.querySelector(".swiper");
    if (!swiperContainer?.contains(e.target)) {
      setClickedIndex(null);
    }
  };

  return (
    <RightBoxStyle ref={ref} onClick={handleBackgroundClick}>
      <Grid align="center">
        <Grid.Col xl={6} md={6} sm={12}>
          <Stack align={"flex-start"}>
            <motion.div
              custom={2}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={leftVariants}
            >
              <div
                className="txt steamland"
                dangerouslySetInnerHTML={{
                  __html: generalSectionData?.content,
                }}
              />
            </motion.div>
          </Stack>
        </Grid.Col>
        <Grid.Col xl={6} md={6} sm={12} className="right">
          <img src={bg} alt="bg" className="bg-image" />
          {matches ? (
            <Swiper
              slidesPerView={1}
              spaceBetween={60}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper programs"
              onSlideChange={handleSlideChange}
            >
              {rightBoxDatas
                ?.sort((a, b) => a.id - b.id)
                .map((item, index) => (
                  <SwiperSlide
                    onClick={() => handleClick(0, { to: "/steamland" })}
                  >
                    {" "}
                    <motion.div
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={cardVariants}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <LandingCard
                        props={{
                          t,
                          icon: item?.svg_icon,
                          title: "",
                          text: item?.content,
                          to: "",
                          isClicked: clickedIndex === index,
                          isMobile,
                        }}
                      />
                    </motion.div>
                  </SwiperSlide>
                ))}
            </Swiper>
          ) : (
            <div className="card-container">
              {rightBoxDatas
                ?.sort((a, b) => a.id - b.id)
                ?.map((item, index) => (
                  <div className="card" key={item.id}>
                    <motion.div
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={cardVariants}
                    >
                      <LandingCard
                        props={{
                          t,
                          icon: item?.svg_icon,
                          title: "",
                          text: item?.content,
                          to: "",
                          isClicked: clickedIndex === 0,
                          isMobile,
                        }}
                      />
                    </motion.div>
                  </div>
                ))}
            </div>
          )}
        </Grid.Col>
      </Grid>
    </RightBoxStyle>
  );
};

const RightBoxStyle = styled.div`
  max-width: 90%;
  margin: 0 auto 80px auto;
  position: relative;
  .card-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 25px;
    .card {
      border: 1px solid #black;
      z-index: 5;
    }
  }

  .right {
    position: relative;
    .bg-image {
      position: absolute;
      left: -70px;
      top: 0;
      z-index: 1;
    }
  }

  .txt {
    width: 65%;
    margin-top: 20px;

    p {
      font-size: 16px;
      font-weight: 400;
      color: #575757;
      margin-top: 10px;
    }
    h3 {
      margin-bottom: 20px;
      max-width: 90%;
      font-size: 28px;
      font-weight: 700;
      color: #1a5059;
      @media (max-width: 1280px) {
        font-size: 24px;
      }
      @media (max-width: 1024px) {
        font-size: 20px;
      }
    }

    ul {
      list-style: disc;
      padding: 0;
      margin-left: 20px;
      margin-top: 10px;
      li {
        font-size: 16px;
        font-weight: 400;
        color: #575757;
        margin-bottom: 6px;
      }
    }

    @media (max-width: 990px) {
      width: 100%;
      h3 {
        max-width: 100%;
        text-align: left;
      }
    }
  }
  @media screen and (max-width: 990px) {
    .right {
      position: relative;
      .bg-image {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 40px;
        z-index: 1;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .right {
      position: relative;
      .bg-image {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 40px;
        z-index: 1;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .programs {
      padding: 10px 10px 40px 10px;
    }
    .cardWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .swiper-wrapper {
      padding-top: 10px;
    }
    .swiper-pagination-bullets {
      bottom: 0px;
    }
    .right {
      position: relative;
      margin-top: 40px;
      .bg-image {
        width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        z-index: 1;
      }
    }
    .txt {
      width: 100%;
      margin-top: 20px;
    }
  }
  .programs {
    position: relative;
    z-index: 2;
  }
`;
