import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Text, Group, Select, Stack, Button } from "@mantine/core";
import { IconCheck, IconX } from '@tabler/icons-react';
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import { getSlugAndIdFromPath } from "../../Helpers";
import { useTranslation } from "react-i18next";

const StudentExam = () => {
  const {t} = useTranslation();
  const [scores, setScores] = useState({});
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const {pathname} = useLocation();
  const {slug, id} = getSlugAndIdFromPath(pathname);
  const [tasks, setTasks] = useState([]);
  const [currentTaskData, setCurrentTaskData] = useState(null);

  const { data: olympic } = useQuery({
    queryKey: ["olympic", id],
    queryFn: async () =>
      await axios.get(`/olympiad/${slug}-${id}/`).then((res) => res.data),
  });

  const { data: taskData, refetch: refetchTaskData } = useQuery({
    queryKey: ["task", id, tasks[currentTaskIndex]?.id],
    queryFn: async () => {
      if (!tasks[currentTaskIndex]?.id) return null;
      return await axios
        .get(`/olympiad/${slug}-${id}/task/${tasks[currentTaskIndex].id}/solve`)
        .then((res) => res.data);
    },
    enabled: !!tasks[currentTaskIndex]?.id,
  });

  const currentTask = tasks[currentTaskIndex];


  useEffect(() => {
    if (olympic) {
      setTasks(olympic?.olympiad_tasks || []);
    }
  }, [olympic]);

  useEffect(() => {
    if (taskData) {
      setCurrentTaskData(taskData);
    }
  }, [taskData]);

  useEffect(() => {
    if (currentTaskData) {
      const newScores = {};
      currentTaskData.question_answer.forEach(qa => {
        newScores[qa.question] = qa.score;
      });
      setScores(newScores);
    }
  }, [currentTaskData]);

  const handleNextTask = () => {
    if (currentTaskIndex < tasks.length - 1) {
      setCurrentTaskIndex(prev => prev + 1);
      setCurrentTaskData(null);
    }
  };

  const handlePreviousTask = () => {
    if (currentTaskIndex > 0) {
      setCurrentTaskIndex(prev => prev - 1);
      setCurrentTaskData(null);
    }
  };

  const renderQuizAnswers = (question) => {
    const isAnswered = currentTaskData?.question_answer?.some(
      (qa) =>
        qa.question === question.id &&
        (qa.answer !== null || qa.answer_inner !== null)
    );
    return (
      <Stack spacing={8} style={{ width: '100%' }}>
        {question.question_answers.map((answer, index) => {
          const isStudentAnswer = currentTaskData?.question_answer?.find(
            qa => qa.answer === answer.id
          );
          const isWrongAnswer = isStudentAnswer && !answer.score;
          const isCorrectAnswer = answer.score;

          return (
            <Box
              key={index}
              className={`answer-option ${
                isCorrectAnswer ? 'correct' : ''
              } ${
                isWrongAnswer ? 'wrong' : ''
              }`}
            >
              <Group position="apart" style={{ width: '100%' }}>
                <Text size="md" color="#404040" weight={500}>{answer.answer}</Text>
                {isCorrectAnswer && (
                  <IconCheck size={20} />
                )}
                {isWrongAnswer && (
                  <IconX size={20} />
                )}
              </Group>
            </Box>
          )
        })}
         {!isAnswered && (
          <Text size="md" color="#FF4B00" weight={500} mt={10}>
            {t('studentExam.unanswered')}
          </Text>
        )}
      </Stack>
    );
  };

  const renderQuestionAnswer = (question) => {
    const studentAnswer = currentTaskData?.question_answer?.find(
      qa => qa.question === question.id
    );
    return (
      <Stack spacing={25}>
        <Box>
          <Text size="md" color="#364152" mb={8}>
            {t('studentExam.studentAnswer')}
          </Text>
          <div style={{ color: '#697586', minHeight: 100,backgroundColor: '#F9F9F9',borderRadius: 8,padding: 12}} dangerouslySetInnerHTML={{ __html: studentAnswer?.answer_text }} />
        </Box>


        <Box>
          <Text size="md" color="#364152" mb={8}>
            {t('studentExam.correctAnswer')}
          </Text>
          <div style={{ color: '#697586', minHeight: 100,backgroundColor: '#E5F9F6',borderRadius: 8,padding: 12}} dangerouslySetInnerHTML={{ __html: question?.question_answers[0]?.answer }} />
         

        </Box>

        <Stack align="flex-start" spacing={15} style={{ width: '100%' }}>
          <Box style={{width: '100%', marginTop: 10}}>
            <Text size="md" color="#364152" weight={500} mb={7}>
              {t('studentExam.score')}
            </Text>
            <Select
              value={scores[question.id]?.toString()}
              disabled={true}
              onChange={(value) => {
                setScores(prev => ({
                  ...prev,
                  [question.id]: value
                }));
              }}
              data={Array.from({ length: 11 }, (_, i) => ({
                value: i.toString(),
                label: i.toString(),
              }))}
              placeholder="Select"
              styles={{
                input: {
                  border: "1px solid #E7E7E7",
                  borderRadius: "8px",
                  height: '42px'
                },
              }}
            />
          </Box>
        </Stack>
      </Stack>
    );
  };

  return (
    <ExamContainer>
      {currentTask && (
        <>
          <Box className="task-header">
            <Text size={20} color="#364152" weight={600} mb={28}>
              {t("task")} {currentTaskIndex + 1} of {tasks.length}
            </Text>
            <div dangerouslySetInnerHTML={{ __html: currentTask.description }} />
            <Text size="md" color="#697586" style={{ lineHeight: 1.6 }}>

              {currentTask.file_url}
            </Text>
          </Box>

          {currentTask.task_question.map((question, index) => (
            <Box key={question.id} className="question-section">
              <Text size="md" color="#364152" weight={500} mb={15}>
                {t('studentExam.question')}
              </Text>
              <Box className="question-card">
                <Stack spacing={25}>
                  <Box>
                    <Group position="apart" align="flex-start">
                      <Group align="center" spacing={6}>
                      {index + 1}.
                      <div style={{marginBottom: 1.5}} dangerouslySetInnerHTML={{ __html: question.description }} />
                      </Group>

                      {currentTask.is_quiz && (
                        <Text size="md" weight={500} color="#FF4B00">
                          {question.question_answers.find(answer => answer.score)?.score} points
                        </Text>
                      )}
                    </Group>
                  </Box>

                  {currentTask.is_quiz ? 
                    renderQuizAnswers(question) : 
                    renderQuestionAnswer(question)
                  }
                </Stack>
              </Box>
            </Box>
          ))}

          <Group position="apart" mt={30}>
            <Group>
              <Button 
                onClick={handlePreviousTask}
                disabled={currentTaskIndex === 0}
                variant="outline"
              >
                {t('studentExam.previousTask')}
              </Button>
              <Button 
                onClick={handleNextTask}


                disabled={currentTaskIndex === tasks.length - 1}
                variant="outline"
              >
                {t('studentExam.nextTask')}
              </Button>
            </Group>

          </Group>
        </>
      )}
    </ExamContainer>
  );
};

const ExamContainer = styled.div`
  padding: 30px;
  background: white;
  border-radius: 10px;

  .task-header {
    margin-bottom: 40px;
  }

  .question-section {
    margin-bottom: 30px;
  }

  .question-card {
    padding: 25px 0;
    border-radius: 12px;
  }

  .answer-option,
  .answer-button {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #E7E7E7;
    background-color: white;
    max-width: 97%;
    transition: all 0.2s ease;
  }

  .answer-button {
    cursor: pointer;

    &:hover {
      background-color: #F9F9F9;
    }

    &.selected {
      &.true {
        background-color: #02919929;
        border-color: #8CA3A559;
        color: #029199;

        svg {
          color: #029199;
        }
      }

      &.false {
        background-color: #FE602729;
        border-color: #FE602759;
        color: #FF4B00;

        svg {
          color: #FF4B00;
        }
      }
    }
  }

  .answer-option {
    &.correct {
      background-color: #02919929;
      border-color: #8CA3A559;
      color: #029199;

      svg {
        color: #029199;
      }
    }

    &.wrong {
      background-color: #FE602729;
      border-color: #FE602759;
      color: #FF4B00;

      svg {
        color: #FF4B00;
      }
    }
  }
`;

export default StudentExam;