export const formatDate = (dateString) => {
    if (!dateString) return '';
    
    const date = new Date(dateString);
    
    // Gün için 01-31 formatı
    const day = date.getDate().toString().padStart(2, '0');
    // Ay için 01-12 formatı
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    // Yıl için son 2 rakam
    const year = date.getFullYear().toString().slice(-2);
    
    return `${day}.${month}.${year}`;
  }; 