import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { MainLayout, SteamLayout } from "./RouteLayout";
import {
  AuthRoutes,
  CommunityRoutes,
  PrivateRoutes,
  SignRoutes,
} from "./SpecialRoutes";
import Landing from "../Pages/Informative/Landing/_Landing";
import About from "../Pages/Informative/About";
import PrivacyPolicy from "../Pages/Informative/PrivacyPolicy";
import TermsOfService from "../Pages/Informative/TermsAndConds";
import ErrorPage from "../Pages/Informative/ErrorPage";
import CourseContent from "../Pages/Course/CourseContent/_CourseContent";
import { TIP } from "../Pages/TIP/_TIP";
import { Videos } from "../Pages/TIP/Videos/_Videos";
import { Vision } from "../Pages/TIP/Vision/_Vision";
import { Profession } from "../Pages/TIP/Profession/_Profession";
import Mentoring from "../Pages/Mentoring/Mentoring";
import { CourseUsers } from "../Pages/Mentoring/CourseUsers";
import CourseMentorContent from "../Pages/Course/CourseMentorContent/_CourseMentorContent";
import { AllContents } from "../Components/AllContents";
import Universities from "../Pages/StudyAbroad/Universities";
import SingleUniversity from "../Pages/StudyAbroad/SingleUniversity";
import ApplyForm from "../Pages/StudyAbroad/ApplyForm";
import Agents from "../Pages/StudyAbroad/Agents";
import SingleAgent from "../Pages/StudyAbroad/SingleAgent";
import { NewRegister } from "../Pages/Authentication/Register/_NewRegister";
import Login from "../Pages/Authentication/Login/_Login";
import EmailVerify from "../Pages/Authentication/EmailVerify";
import UniversityPortal from "../Pages/StudyAbroad/UniversityPortal";
import Subscription from "../Pages/StudyAbroad/Subscription";
import Profile from "../Pages/MyProfile/Profile";
import SingleProgram from "../Pages/Programs";
import NewPayment from "../Pages/NewPayment/NewPayment";
import SteamInfo from "../Pages/Steam/SteamInfo";
import MyTasks from "../Pages/Steam/MyTasks";
import TaskBlog from "../Pages/Steam/TaskBlog";
import WeekPathway from "../Pages/Steam/WeekPathway";
import SteamTask from "../Pages/Steam/SteamTask";
import ScoringDashboard from "../Pages/Steam/TaskComment";
import LottieLoading from "../Components/Loaders/LottieLoading";
import Olympics from "../Pages/Olympics";
import ResetPasswordSuccess from "../Pages/Authentication/ResetPassword/_ResetPassword";
import VersionChecker from "../Components/VersionChecker";
import Communities from "../features/Community";
import CategoryList from "../Pages/Community/Categories";
import CoverPhotoList from "../Pages/Community/CoverPhoto";
import CommunityPanel from "../Pages/Community/CommunityPanel";
import AdminAnalytics from "../Pages/Community/AdminAnalytics";
import CommunityHighlights from "../Pages/Community/CommunityHighlights";
import SavedPost from "../Pages/Community/SavedPost";
import CommunityHighlight from "../Pages/Community/CommunityHighlight";
import JoinPanel from "../Pages/Community/JoinPanel";
import BadgesAndCollaborators from "../Pages/Community/BadgesAndCollaborators";
import SteamLand from "../Pages/SteamLand";
import CompetitionList from "../Pages/Competition/CompetitionList";
import CompetitionDetail from "../Pages/Competition/CompetitionDetail";
import StudentExam from "../Pages/Competition/StudentExam";
import SharedPost from "../Pages/Community/SharedPost";

export let isTaskool = true;

const NewDashboard = lazy(() => import("../Components/NewDashboard"));

const RouteLogic = () => {
  return (
    <>
      <VersionChecker />
      <Routes>
        <Route
          element={<MainLayout props={{ isAside: false, isSidebar: false }} />}
        >
          {/* Public Routes */}

          <Route
              path="/community/:communityId/join"
              element={<JoinPanel type="community" />}
            />
          <Route
            path="/"
            element={
              <AuthRoutes>
                {" "}
                <Landing />{" "}
              </AuthRoutes>
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-usage" element={<TermsOfService />} />
          <Route path="*" element={<ErrorPage />} />

          {/* <Route path="/courses/:id" element={<Program props={{ isTaskool, type: "course", status: true }} />} /> */}
          <Route
            path="/courses/:id/payment"
            element={<NewPayment props={{ type: "course" }} />}
          />

          <Route
            path="/steam/:id/payment"
            element={<NewPayment props={{ type: "steam" }} />}
          />

          <Route
            path="/community/:id/payment"
            element={<NewPayment props={{ type: "community" }} />}
          />
          <Route
            path="/competition/:id/payment"
            element={<NewPayment props={{ type: "competition" }} />}
          />
          <Route
            path="/steam/:id"
            element={<SingleProgram props={{ isTaskool, type: "steam" }} />}
          />
          <Route
            path="/course/:id"
            element={<SingleProgram props={{ isTaskool, type: "course" }} />}
          />
          <Route
            path="/bootcamp/:id"
            element={<SingleProgram props={{ isTaskool, type: "bootcamp" }} />}
          />
          <Route
            path="/bootcamp/:id/payment"
            element={<NewPayment props={{ type: "bootcamp" }} />}
          />

          <Route path="/tip" element={<TIP />} />

          <Route
            path="/universities"
            element={<Universities props={{ applyTo: "university" }} />}
          />
          <Route
            path="/universities/:uId/programs/:pId"
            element={<SingleUniversity />}
          />

          <Route path="/profile/:id" element={<Profile type="public" />} />
          <Route path="/olympics" element={<Olympics />} />
          <Route path="/steamland" element={<SteamLand />} />

          <Route path="/competitions" element={<CompetitionList />} />
          <Route path="/competitions/:slug" element={<CompetitionDetail />} />

          {/* Private Rotes */}
          <Route element={<PrivateRoutes />}>
            <Route path="/subscribtion" element={<Subscription />} />
            <Route path="/subscribtion/:status" element={<Subscription />} />
            <Route
              path="/steam/:id"
              element={<SingleProgram props={{ isTaskool, type: "steam" }} />}
            />
            <Route
              path="/course/:id"
              element={<SingleProgram props={{ isTaskool, type: "course" }} />}
            />
            <Route path="/course-users/:courseId" element={<CourseUsers />} />
            <Route path="/mentor" element={<Mentoring />} />

            {/* <Route
    path="/courses/:id"
    element={<Program props={{ isTaskool, type: "course", status: true }} />}
  /> */}

            <Route
              path="/universities/apply-form/programs/:pId"
              element={<ApplyForm props={{ applyTo: "university" }} />}
            />
            <Route
              path="/agents/:aId/apply-form"
              element={<ApplyForm props={{ applyTo: "agents" }} />}
            />

            <Route
              path="/agents/:aId/universities"
              element={<Universities props={{ applyTo: "agents" }} />}
            />
            <Route path="/agents" element={<Agents />} />
            <Route path="/agents/:aId" element={<SingleAgent />} />
            <Route path="/universities/portal" element={<UniversityPortal />} />
            <Route
              path="/course/:courseId/course-content"
              element={<CourseContent props={{ type: "course" }} />}
            />
            {/* <Route path="/steams/:courseI d" element={<CourseContent props={{ type: "steam" }} />} /> */}
            <Route
              path="/profile"
              element={<Profile type="private" props={{ isTaskool }} />}
            />
            <Route path="/tip/videos" element={<Videos />} />
            <Route path="/tip/vision" element={<Vision />} />
            <Route path="/tip/profession" element={<Profession />} />

            <Route
              path="/mentor/course/:courseId"
              element={<CourseMentorContent />}
            />
            <Route path="/mentor" element={<Mentoring />} />
            <Route path="/course-users/:courseId" element={<CourseUsers />} />
            <Route
              path="/categories"
              element={<CategoryList type="create" />}
            />
            <Route
              path="/categories/:id/cover-photo"
              element={<CoverPhotoList type="create" />}
            />
            <Route
              path="/categories/edit"
              element={<CategoryList type="edit" />}
            />
            <Route
              path="/categories/:id/cover-photo/edit"
              element={<CoverPhotoList type="edit" />}
            />

            <Route
              path="/community/:communityId/highlight/:highlightId/join"
              element={<JoinPanel type="highlight" />}
            />

          </Route>
          {/* Private Rotes */}
        </Route>

        <Route
          element={<MainLayout props={{ isAside: false, isSidebar: true }} />}
        >
          <Route
            path="/competition/:competitionId/student/:studentId"
            element={<StudentExam />}
          />
          <Route
            path="/steam"
            element={
              <AllContents props={{ defaultValue: "all", type: "steam" }} />
            }
          />
          <Route
            path="/courses"
            element={
              <AllContents props={{ defaultValue: "all", type: "course" }} />
            }
          />
          <Route
            path="/bootcamps"
            element={
              <AllContents props={{ defaultValue: "all", type: "bootcamp" }} />
            }
          />
          {/* <Route path="/groups/:id" element={<Community />} /> */}
          <Route
            path="/community/:id"
            element={<CommunityPanel type="public" />}
          />
          <Route element={<CommunityRoutes />}>
            <Route
              path="/community/:id/owner"
              element={<CommunityPanel type="owner" />}
            />
            <Route
              path="/community/:id/badges"
              element={<BadgesAndCollaborators type="badges" />}
            />
            <Route
              path="/community/:id/collaborators"
              element={<BadgesAndCollaborators type="collaborators" />}
            />
            <Route
              path="/community/:id/analytics"
              element={<AdminAnalytics />}
            />
          </Route>
          <Route
            path="/community/:id/highlights"
            element={<CommunityHighlights />}
          />
          <Route path="/community/:id/saved" element={<SavedPost />} />
          <Route path="/community/:id/post/:postId" element={<SharedPost />} />
          <Route path="/community/:id/highlight/:highlightId/post/:postId" element={<SharedPost />} />


          <Route
            path="/community/:id/highlight/:highlightId/"
            element={<CommunityHighlight type="public" />}
          />
          <Route
            path="/community/:id/highlight/:highlightId/owner"
            element={<CommunityHighlight type="owner" />}
          />
        </Route>

        <Route
          element={<MainLayout props={{ isAside: true, isSidebar: true }} />}
        >
          <Route path="/communities" element={<Communities />} />

          <Route element={<PrivateRoutes />}>
            <Route
              path="/home"
              element={
                <Suspense fallback={<LottieLoading />}>
                  <NewDashboard />
                </Suspense>
              }
            />
          </Route>
        </Route>

        <Route element={<SignRoutes />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<NewRegister />} />
          <Route path="/email-verify/:token" element={<EmailVerify />} />
          <Route
            path="/password-reset/:uuid/:token"
            element={<ResetPasswordSuccess />}
          />
        </Route>

        <Route element={<SteamLayout />}>
          <Route
            path="/steam/:id/overview"
            element={<SteamInfo type="steam" />}
          />
          <Route
            path="/bootcamp/:id/overview"
            element={<SteamInfo type="bootcamp" />}
          />

          <Route
            path="/steam/:id/week-pathway/:unitId"
            element={<MyTasks type="steam" />}
          />
          <Route
            path="/bootcamp/:id/section-pathway/:unitId"
            element={<MyTasks type="bootcamp" />}
          />

          <Route
            path="/steam/:id/week-pathway/:unitId/day/:subUnitId"
            element={<TaskBlog type="steam" />}
          />
          <Route
            path="/bootcamp/:id/section-pathway/:unitId/lesson/:subUnitId"
            element={<TaskBlog type="bootcamp" />}
          />

          <Route
            path="/steam/:id/week-pathway/:unitId/day/:subUnitId/task"
            element={<SteamTask type="steam" />}
          />
          <Route
            path="/bootcamp/:id/section-pathway/:unitId/lesson/:subUnitId/task"
            element={<SteamTask type="bootcamp" />}
          />

          <Route
            path="/steam/:id/week-pathway"
            element={<WeekPathway type="steam" />}
          />
          <Route
            path="/bootcamp/:id/section-pathway"
            element={<WeekPathway type="bootcamp" />}
          />

          <Route
            path="/steam/:id/task-score"
            element={<ScoringDashboard type="steam" />}
          />
          <Route
            path="/bootcamp/:id/task-score"
            element={<ScoringDashboard type="bootcamp" />}
          />
        </Route>
      </Routes>
    </>
  );
};

export default RouteLogic;
