import { Group, Skeleton, Textarea } from "@mantine/core";
import { IconEdit, IconCheck } from "@tabler/icons-react";
import { useParams } from "react-router-dom";
import { useUpdateCommunity } from "../../../../hooks/community/useUpdateCommunity";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../../Helpers/Context";
import { useTranslation } from "react-i18next";

const AboutSection = ({ isLoading, data }) => {
  const {id,highlightId} = useParams();
  const {mutate: updateCommunity , isLoading: isUpdating} = useUpdateCommunity(id);
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState();
  const {user} = useContext(AppContext)
  const is_owner = data?.author?.id === user?.id

  const handleUpdate = () => {
    const updatedData = {
      cover_photo: data.cover_photo,
      category: data.category,
      name: data.name,
      description: description,
      lang_id: data.lang_id,
      price: data.price,
      is_free: data.is_free,
    }
    updateCommunity({ id, data: updatedData });
    setIsEditing(false);
  };

  useEffect(() => {
    setDescription(data?.description);
  }, [data]);
  const {t} = useTranslation()

  return (
  
  <div className="panel-section about">
    <Group position="apart" align="center" style={{borderBottom: '1px solid #e4e4e4'}} >
    <h4>{t('about')}</h4>
    {is_owner  && ( isEditing ? 
      <IconCheck
        style={{cursor: 'pointer',marginBottom: '6px'}}
        size={20}
        color="#202020"
        onClick={handleUpdate}
      />
     : !highlightId ? 
      <IconEdit
        style={{cursor: 'pointer',marginBottom: '6px'}}
        size={20}
        color="#ECA200"
        onClick={() => setIsEditing(true)}
      /> : null
    )}
    </Group>
    {isLoading || isUpdating || description === undefined ? (
      <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "10px" }}>
        <Skeleton height={15} width="100%" radius="md" />
        <Skeleton height={15} width="95%" radius="md" />
        <Skeleton height={15} width="90%" radius="md" />
        <Skeleton height={15} width="97%" radius="md" />
      </div>
    ) : isEditing ? (
      <Textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        minRows={3}
        style={{marginTop: '10px'}}
      />
    ) : (
      <div style={{ 
        maxHeight: '200px', 
        overflowY: 'auto',
        marginTop: '10px'
      }}>
        <p>{description}</p>
      </div>
    )}
  </div>
  );
};

export default AboutSection; 