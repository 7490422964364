import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePost } from "./usePost";
import { updatePost } from "../../services/apiPost";
import { useTranslation } from "react-i18next";
export const useUpdatePost = ({closeModal, resetStates,community_id,post_tab,label,post_id,type}) => {
    const {invalidatePostQueries, handleError, handleSuccess} = usePost();
    const queryClient = useQueryClient();
    const {t} = useTranslation()
    return useMutation({
        mutationFn: ({communityId,postId,data}) => updatePost({communityId,postId,data}),
        onSuccess: (_,variables) => {
            if(type==="savedPosts"){
                queryClient.invalidateQueries({queryKey: ['savedPosts',community_id,post_tab]});
            }
            else if(type==="sharedPost"){
                queryClient.invalidateQueries({queryKey: ['detail',community_id,post_id]});
            }
            else{
                queryClient.invalidateQueries({queryKey: ['all',community_id,post_tab]});
            }
            handleSuccess(t("post_updated_successfully"));
            resetStates();
            closeModal();
        },
        onError: (error) => handleError(error),
    })
}   
