import { useMutation } from "@tanstack/react-query";
import { useCommunity } from "./useCommunity";
import { createCommunity } from "../../services/apiCommunity";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export const useCreateCommunity = () => {
  const {t} = useTranslation()
  const {invalidateCommunityQueries, handleError, handleSuccess } = useCommunity();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (data) => createCommunity(data),
    onSuccess: (data) => {
      invalidateCommunityQueries();
      handleSuccess(t("community_created_successfully"));
      localStorage.setItem('isFirstHighlight',true);
      navigate(`/communities?id=${data.message.slug}-${data.message.id}&createHighlight=active`);
      localStorage.setItem('communityId',`${data.message.slug}-${data.message.id}`);
      localStorage.removeItem('communityData');
    },
    onError: (error) => handleError(error),
  });
};
