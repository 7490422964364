import React, { useState } from "react";
import { CommunityLayout } from "./CommunityPanel";
import {
  Text,
  Group,
  Stack,
  Avatar,
  ActionIcon,
  Skeleton,
} from "@mantine/core";
import styled from "styled-components";
import {
  IconPlus,
  IconAward,
  IconArrowLeft,
  IconEdit,
  IconUsers,
} from "@tabler/icons-react";
import Button from "../../Components/FormComponent/Button";
import BadgeTable from "../../features/Community/components/badge/BadgeTable";
import { useGetAwardBadges } from "../../features/Community/hooks/badges/useGetAwardBadges";
import { useParams } from "react-router-dom";
import { useCreateAwardBadge } from "../../features/Community/hooks/badges/useCreateAwardBadge";
import { getCollaboratorsByBadgeId } from "../../features/Community/services/apiBadges";
import { useInfiniteQuery } from "@tanstack/react-query";
import InfiniteScrollComponent from "../../Components/Common/InfiniteScroll";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { useUpdateAwardBadge } from "../../features/Community/hooks/badges/useUpdateAwardBadge";
const LoadingSkeleton = () => (
  <Stack spacing={25}>
    {[1, 2, 3].map((_, index) => (
      <div key={index}>
        <Group mb={10}>
          <Skeleton height={24} width={24} radius="xl" />
          <Skeleton height={24} width={200} radius="sm" />
        </Group>
        <Skeleton height={20} width="90%" radius="sm" mb={15} />

        <Stack spacing={10}>
          {[1, 2].map((_, idx) => (
            <Group
              key={idx}
              position="apart"
              style={{
                padding: "12px 16px",
                background: "#f8fafd",
                borderRadius: "6px",
              }}
            >
              <Group>
                <Skeleton height={32} width={32} radius="xl" />
                <Skeleton height={20} width={120} radius="sm" />
              </Group>
              <Skeleton height={20} width={80} radius="sm" />
            </Group>
          ))}
        </Stack>
      </div>
    ))}
  </Stack>
);

const BadgesAndCollaborators = ({ type }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [activeBadgeTable, setActiveBadgeTable] = useState(false);
  const [editingBadge, setEditingBadge] = useState(null);
  const { data: awardBadges, isLoading: isAwardBadgesLoading } =
  useGetAwardBadges(id);
  const { mutate: createBadge } = useCreateAwardBadge(id, setActiveBadgeTable);
  const { mutate: updateBadge } = useUpdateAwardBadge(id, setActiveBadgeTable, setEditingBadge);


  const badgeIds = awardBadges?.map((badge) => badge.id) || [];

  const {
    data: allCollaborators,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading: isCollaboratorsLoading,
  } = useInfiniteQuery({
    queryKey: ["all-collaborators", id, badgeIds],
    queryFn: async ({ pageParam = 1 }) => {
      if (!badgeIds.length) return {};

      const collaboratorsMap = {};
      for (const badgeId of badgeIds) {
        const collaborators = await getCollaboratorsByBadgeId(
          id,
          badgeId,
          pageParam
        );
        collaboratorsMap[badgeId] = collaborators;
      }
      return collaboratorsMap;
    },
    getNextPageParam: (lastPage) => {
      const hasMore = Object.values(lastPage).some(
        (collaborators) => collaborators?.next !== null
      );
      if (hasMore) {
        return Object.values(lastPage)[0]?.next;
      }
      return undefined;
    },
    enabled: badgeIds.length > 0,
  });


  const mergedCollaborators = React.useMemo(() => {
    if (!allCollaborators?.pages) return {};

    return allCollaborators.pages.reduce((acc, page) => {
      Object.entries(page).forEach(([badgeId, collaborators]) => {
        if (!acc[badgeId]) {
          acc[badgeId] = [];
        }
        const newCollaborators = collaborators.results.filter(
          (newCollab) =>
            !acc[badgeId].some(
              (existingCollab) => existingCollab.user.id === newCollab.user.id
            )
        );
        acc[badgeId] = [...acc[badgeId], ...newCollaborators];
      });
      return acc;
    }, {});
  }, [allCollaborators]);

  const handleAddBadge = (badge) => {
    const data = {
      community: id,
      ...badge,
    };
    if(editingBadge){
      updateBadge(data);
    }else{
      createBadge(data);
    }
  };

  const handleEditBadge = (badge) => {
    setEditingBadge(badge);
    setActiveBadgeTable(true);
  };

  const xs = useMediaQuery("(max-width:768px)");

  const EmptyCollaborators = () => (
    <EmptyState>
      <div className="empty-content">
        <div className="icon-wrapper">
          <IconUsers size={xs ? 40 : 48} />
        </div>
        <Text size={xs ? "xl" : "lg"} weight={500} color="#364152" mt={20}>
          {t("empty-collaborators-title")}
        </Text>
        <Text
          size={xs ? "md" : "sm"}
          color="dimmed"
          mt={10}
          align="center"
          maw={400}
        >
          {t("empty-collaborators-description")}
        </Text>
      </div>
    </EmptyState>
  );
  const isLoading =
    type === "badges"
      ? isAwardBadgesLoading
      : type === "collaborators" && badgeIds.length > 0
      ? isCollaboratorsLoading
      : false;

  return (
    <CommunityLayout>
      <BadgesContainer>
        <Group
          style={{ paddingBottom: "16px", borderBottom: "1px solid #e7e7e7" }}
          position="apart"
          mb={30}
        >
          <Text size={xs ? 18 : 20} weight={500}>
            {type === "badges" ? t("badge-list") : t("collaborators-list")}
          </Text>
          {type === "badges" &&
            (awardBadges && awardBadges.length > 0 && !activeBadgeTable ? (
              <Button
                leftIcon={
                  <IconPlus
                    style={{ marginBottom: xs ? 2 : 0 }}
                    size={xs ? 14 : 16}
                  />
                }
                backgroundColor="transparent"
                color="#FE6513"
                border="none"
                variant="outline"
                onClick={() => setActiveBadgeTable(true)}
              >
                {t("create")}
              </Button>
            ) : activeBadgeTable ? (
              <Button
                leftIcon={<IconArrowLeft size={xs ? 14 : 16} />}
                backgroundColor="transparent"
                color="#FE6513"
                border="none"
                variant="outline"
                onClick={() => {setActiveBadgeTable(false); setEditingBadge(null)}}
              >
                {t("back")}
              </Button>
            ) : null)}
        </Group>
        {isLoading ? (
          <LoadingSkeleton />
        ) : activeBadgeTable && type === "badges" ? (
          <BadgeTable onAddBadge={handleAddBadge} editingBadge={editingBadge} />
        ) : null}

        {type === "badges" ? (
          awardBadges && awardBadges.length === 0 && !activeBadgeTable ? (
            <EmptyState>
              <div className="empty-content">
                <div className="icon-wrapper">
                  <IconAward size={xs ? 40 : 48} />
                </div>
                <Text
                  size={xs ? "lg" : "xl"}
                  weight={500}
                  color="#364152"
                  mt={20}
                >
                  {t("empty-badges-title")}
                </Text>
                <Text
                  size={xs ? "md" : "sm"}
                  color="dimmed"
                  mt={10}
                  align="center"
                  maw={400}
                >
                  {t("empty-badges-description")}
                </Text>
                <Button
                  leftIcon={<IconPlus size={16} />}
                  color="#FE6513"
                  border="1px solid #FE6513"
                  variant="outline"
                  mt={20}
                  onClick={() => setActiveBadgeTable(true)}
                >
                  {t("create-new-badge")}
                </Button>
              </div>
            </EmptyState>
          ) : (
            <Stack spacing={25}>
              {awardBadges?.map((badge, index) => {
                const badgeCollaborators =
                  mergedCollaborators?.[badge.id] || [];

                return (
                  <div key={badge.id}>
                    <BadgeTitle>
                      <div>
                        <span>{index + 1}. </span>
                        <div
                          style={{
                            width: xs ? 18 : 28,
                            height: xs ? 18 : 28,
                            marginBottom: 1,
                          }}
                          dangerouslySetInnerHTML={{
                            __html: badge?.badge_output?.svg_icon?.replace(
                              "<svg",
                              `<svg width="${xs ? 18 : 28}" height="${
                                xs ? 18 : 28
                              }"`
                            ),
                          }}
                        />
                    
                        <span>{badge?.badge_output?.name}</span>
                      </div>
                      <ActionIcon
                        ml="auto"
                        onClick={() => handleEditBadge(badge)}
                        sx={{ color: "#029199" }}
                      >
                        <IconEdit size={18} />
                      </ActionIcon>
                    </BadgeTitle>
                    <Text>{badge?.description}</Text>

                    <CollaboratorsContainer>
                      <InfiniteScrollComponent
                        isFetching={isFetching}
                        hasNextPage={hasNextPage}
                        fetchNextPage={fetchNextPage}
                      >
                        {badgeCollaborators.map((collaborator) => (
                          <UserCard key={`${collaborator.id}-${collaborator.user.id}`}>
                            <Group>
                              <Avatar
                                src={collaborator.user?.profile_img}
                                radius="xl"
                                size={32}
                              />
                              <Text size="sm">
                                {collaborator.user?.last_name}
                              </Text>
                            </Group>
                            <Text size="sm" color="dimmed">
                              {collaborator.user?.posts} {t("post-count")}
                            </Text>
                          </UserCard>
                        ))}
                      </InfiniteScrollComponent>
                    </CollaboratorsContainer>
                  </div>
                );
              })}
            </Stack>
          )
        ) : isLoading ? (
          <LoadingSkeleton />
        ) : !mergedCollaborators ||
          Object.keys(mergedCollaborators).length === 0 ||
          Object.values(mergedCollaborators).every(
            (collaborators) => collaborators.length === 0
          ) ? (
          <EmptyCollaborators />
        ) : (
          <Stack spacing={25}>
            {awardBadges?.map((badge, index) => {
              const badgeCollaborators = mergedCollaborators?.[badge.id] || [];

              return (
                <div key={badge.id}>
                  <BadgeTitle>
                    <div>
                      <span>{index + 1}. </span>
                      <div
                      style={{
                        width: xs ? 18 : 28,
                        height: xs ? 18 : 28,
                        marginBottom: 1.5,
                      }}
                        dangerouslySetInnerHTML={{
                          __html: badge?.badge_output?.svg_icon?.replace(
                            "<svg",
                            `<svg width="${xs ? 18 : 28}" height="${
                              xs ? 18 : 28
                            }"`
                          ),
                        }}
                      />
                      <span>{badge?.badge_output?.name}</span>
                    </div>
                   
                  </BadgeTitle>
                  <Text>{badge?.description}</Text>

                  <CollaboratorsContainer>
                    <InfiniteScrollComponent
                      isFetching={isFetching}
                      hasNextPage={hasNextPage}
                      fetchNextPage={fetchNextPage}
                    >
                      {badgeCollaborators.map((collaborator) => (
                        <UserCard key={`${collaborator.id}-${collaborator.user.id}`}>
                          <Group>
                            <Avatar
                              src={collaborator.user?.profile_img}
                              radius="xl"
                              size={32}
                            />
                            <Text size="sm">
                              {collaborator.user?.last_name}
                            </Text>
                          </Group>
                          <Text size="sm" color="dimmed">
                            {collaborator.user?.posts} {t("post-count")}
                          </Text>
                        </UserCard>
                      ))}
                    </InfiniteScrollComponent>
                  </CollaboratorsContainer>
                </div>
              );
            })}
          </Stack>
        )}
      </BadgesContainer>
    </CommunityLayout>
  );
};

const BadgesContainer = styled.div`
  padding: 20px;
  background: white;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const BadgeTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  color: #029199;
  div {
    display: flex;
    align-items: center;
  }
  svg {
    margin-top: 2px;
    margin-right: 4px;
    margin-left: 6px;
  }
  span {
    font-size: 18px;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    div {
      font-size: 12px;
    }
    span {
      font-size: 16px;
    }
  }
`;

const UserCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: #f8fafd;
  border-radius: 6px;
`;

const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  background: #f8fafd;
  border-radius: 12px;
  border: 2px dashed #dbe4e7;
  margin-top: 20px;

  .empty-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    text-align: center;
  }

  .icon-wrapper {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: rgba(2, 145, 153, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #029199;
  }

  button {
    &:hover {
      background: rgba(2, 145, 153, 0.1);
      border-color: #029199;
    }
  }
`;

const CollaboratorsContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
  margin-top: 10px;
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
`;

export default BadgesAndCollaborators;
