import React from 'react';
import styled from 'styled-components';
import { Skeleton } from '@mantine/core';

const CompetitionDetailSkeleton = () => {
  return (
    <StyledCompetitionDetail>
      {/* Banner Skeleton */}
      <BannerSkeleton>
        <Skeleton height={362} radius={0} />
      </BannerSkeleton>

      {/* Navigation Skeleton */}
      <NavigationSkeleton>
        <nav>
          {[...Array(6)].map((_, index) => (
            <Skeleton key={index} height={20} width={100} radius="md" />
          ))}
        </nav>
      </NavigationSkeleton>

      {/* Content Grid */}
      <GridContainer>
        <ContentWrapper>
          {/* Description Section */}
          <Section>
            <Skeleton height={32} width={200} mb={20} />
            <Skeleton height={16} width={120} mb={20} />
            {[...Array(4)].map((_, index) => (
              <Skeleton key={index} height={16} mb={10} />
            ))}
          </Section>

          {/* Results Section */}
          <Section>
            <Skeleton height={32} width={200} mb={20} />
            <ResultsWrapper>
              <ColumnsContainer>
                <Column>
                  <HeaderSkeleton />
                  {[...Array(10)].map((_, index) => (
                    <ResultCardSkeleton key={index} />
                  ))}
                </Column>
                <Column>
                  <HeaderSkeleton />
                  {[...Array(10)].map((_, index) => (
                    <ResultCardSkeleton key={index} />
                  ))}
                </Column>
              </ColumnsContainer>
            </ResultsWrapper>
          </Section>

          {/* My Results Section */}
          <Section>
            <Skeleton height={32} width={200} mb={20} />
            <Skeleton height={100} radius="md" />
          </Section>
        </ContentWrapper>

        {/* Right Sidebar */}
        <RightSideWrapper>
          <PriceSection>
            <Skeleton height={80} radius="md" mb={15} />
            <Skeleton height={45} radius="md" mb={10} />
            <Skeleton height={45} radius="md" />
          </PriceSection>

          <RelatedCoursesSection>
            <Skeleton height={50} radius="md" mb={15} />
            {[...Array(4)].map((_, index) => (
              <Skeleton key={index} height={72} radius="md" mb={10} />
            ))}
          </RelatedCoursesSection>
        </RightSideWrapper>
      </GridContainer>
    </StyledCompetitionDetail>
  );
};

// Styled Components
const StyledCompetitionDetail = styled.div``;

const BannerSkeleton = styled.div`
  margin-bottom: 20px;
`;

const NavigationSkeleton = styled.div`
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  position: sticky;
  top: 70px;
  z-index: 9;

  nav {
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    gap: 40px;
    padding: 10px 0;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
  max-width: 90%;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ContentWrapper = styled.div`
  padding: 20px 0;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const ResultsWrapper = styled.div`
  border-radius: 8px;
  padding: 0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  margin-top: 24px;
`;

const ColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
`;

const Column = styled.div`
  padding: 0 16px 0 0;
`;

const HeaderSkeleton = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid #E2E8F0;
  margin-bottom: 8px;
  border-radius: 4px;
`;

const ResultCardSkeleton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 4px;
  border: 1px solid #E2E8F0;
`;

const RightSideWrapper = styled.div`
  position: sticky;
  top: 160px;
  height: fit-content;
`;

const PriceSection = styled.div`
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 24px;
  box-shadow: 0px 4px 15px 0px #0000001a;
`;

const RelatedCoursesSection = styled.div`
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0px 1px 8px 0px #0000001f;
`;

export default CompetitionDetailSkeleton; 