import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import MenuBarDropdown from "./MenuBarDropdown";
import { IconSearch, IconX } from "@tabler/icons-react";
import logo from "../../../Assets/SVGs/landing/logo.svg";
import { Link } from "react-router-dom";
import { Button, Group } from "@mantine/core";
import { AppContext } from "../../../Helpers/Context";
import subsIcon from "../../../Assets/SVGs/subscription-icon.svg";

const MenuBar = ({ onClose }) => {
  const { t } = useTranslation();
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleToggle = (title) => {
    setOpenDropdown(openDropdown === title ? null : title);
  };

  const handleBackdropClick = (e) => {
    if (!e.target.closest(".menu-bar-container")) {
      onClose();
    }
  };

  const { user } = useContext(AppContext);

  return (
    <MenuBarContainer className="menu-bar-container">
      <Header>
        <LogoLink to="/" onClick={onClose}>
          <img src={logo} alt="taskool" />
        </LogoLink>
        <CloseButton onClick={onClose}>
          <IconX size={24} color="#fff" />
        </CloseButton>
      </Header>

      {/* <SearchContainer>
          <IconSearch size={18} color="#fff" />
          <SearchInput placeholder={t("search")} />
        </SearchContainer> */}

      <MenuBarDropdown
        title={t("about")}
        items={[
          { label: t("about"), link: "/about" },
          { label: "STEAMLAND", link: "/steamland" },
        ]}
        onClose={onClose}
        isOpen={openDropdown === t("about")}
        onToggle={handleToggle}
      />

      <MenuBarDropdown
        title={t("steam")}
        items={[
          { label: t("steam-bank"), link: "/steam" },
          { label: t("olympic"), link: "/competitions" },
          { label: t("course"), link: "/bootcamps" },
        ]}
        onClose={onClose}
        isOpen={openDropdown === t("steam")}
        onToggle={handleToggle}
      />

      <MenuBarDropdown
        title={t("education")}
        items={[
          { label: t("study-abroad"), link: "/universities" },
          { label: t("tip"), link: "/tip" },
        ]}
        onClose={onClose}
        isOpen={openDropdown === t("education")}
        onToggle={handleToggle}
      />

      <MenuBarDropdown
        title={t("community")}
        isNoneDropdown={true}
        link="/communities"
        onClose={onClose}
        isOpen={openDropdown === t("community")}
        onToggle={handleToggle}
      />

      {!user ? (
        <AuthSection>
          <LoginButton component={Link} to="/login" onClick={onClose}>
            {t("login")}
          </LoginButton>
          <RegisterButton component={Link} to="/register" onClick={onClose}>
            {t("register")}
          </RegisterButton>
        </AuthSection>
      ) : (
        <Group position="left">
          <Button
            onClick={onClose}
            component={Link}
            to="/subscribtion"
            className="subscription-button"
            leftIcon={<img src={subsIcon} alt="Subs-Icon" />}
          >
            {t("subscription")}
          </Button>
        </Group>
      )}
    </MenuBarContainer>
  );
};

export default MenuBar;

const MenuBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #029199;
  padding: 20px 0 20px 20px;
  width: 80%;
  position: relative;
  .subscription-button {
    margin-top: 20px;
    background: #ccf075;
    color: #364152;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 32px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 45px;

    &:hover {
      background: #bde164;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }
`;

const LogoLink = styled(Link)`
  text-decoration: none;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dadada;
  padding-bottom: 10px;
  margin-bottom: 15px;

  img {
    height: 24px;
  }
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 20px 15px 0;
  border-bottom: 1px solid #dadada;
  border-top: 1px solid #dadada;
  margin-top: 20px;
`;

const SearchInput = styled.input`
  background: transparent;
  border: none;
  color: white;
  font-si
  e: 16px;
  width: 100%;
  outline: none;

  &::placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
`;

const MenuItem = styled.div`
  color: white;
  font-size: 16px;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const AuthSection = styled.div`
  padding: 20px 0px;
  display: flex;
  justify-content: left;
  gap: 10px;
`;

const LoginButton = styled(Button)`
  background: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 14px;
  font-size: 14px;
  font-weight: 500;
  height: 42px;
  flex: 1;
  &:hover {
    background: transparent;
    color: #fff;
    opacity: 0.9;
  }
`;

const RegisterButton = styled(Button)`
  background: #ccf075;
  color: black;
  border: none;
  border-radius: 14px;
  font-size: 14px;
  font-weight: 500;
  height: 42px;
  flex: 1;
  &:hover {
    background: #ccf075;
    color: black;
    opacity: 0.9;
  }
`;
