import { Box, Text, Button } from "@mantine/core";
import styled from "styled-components";
import { IconSearchOff } from "@tabler/icons-react";
import { useTranslation } from 'react-i18next';

const EmptyState = ({ onReset }) => {
  const { t } = useTranslation();

  return (
    <EmptyWrapper>
      <Box className="empty-content">
        <IconSearchOff size={120} className="empty-icon" stroke={1.2} />
        <Text className="title">{t('emptyState.title')}</Text>
        <Text className="description">
          {t('emptyState.description')}
        </Text>
        <Button onClick={onReset} className="reset-button">
          {t('emptyState.resetButton')}
        </Button>
      </Box>
    </EmptyWrapper>
  );
};

export default EmptyState;

const EmptyWrapper = styled.div`
  width: 100%;
  padding: 40px 0;

  .empty-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 40px;
    background: white;
    border-radius: 16px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  }

  .empty-icon {
    color: #029199;
    margin-bottom: 24px;
    opacity: 0.8;
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    color: #202939;
    margin-bottom: 12px;
  }

  .description {
    font-size: 16px;
    color: #697586;
    max-width: 400px;
    margin-bottom: 24px;
  }

  .reset-button {
    background-color: #029199;
    height: 40px;
    padding: 0 24px;
    border-radius: 8px;
    transition: all 0.3s ease;

    &:hover {
      background-color: #029199;
      opacity: 0.9;
    }
  }
`;