import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useContext,
} from "react";
import { Group, Modal, Tabs, Text, Select, Loader } from "@mantine/core";
import { ModalPostLayoutStyled } from "../../style";
import photo_video from "../../../../Assets/SVGs/community/post/photo-video.svg";
import quiz from "../../../../Assets/SVGs/community/post/quiz.svg";
import poll from "../../../../Assets/SVGs/community/post/poll.svg";
import text_icon from "../../../../Assets/SVGs/community/post/text.svg";
import Button from "../../../../Components/FormComponent/Button";
import { ChevronDown, Plus, Trash, CirclePlus } from "tabler-icons-react";
import { useGetAllPostLabels } from "../../hooks/post/useGetAllPostLabels";
import { useCreatePost } from "../../hooks/post/useCreatePost";
import { useParams } from "react-router-dom";
import axios from "../../../../Helpers/Axios";
import * as tus from "tus-js-client";
import { useGetPost } from "../../hooks/post/useGetPost";
import { useUpdatePost } from "../../hooks/post/useUpdatePost";
import { showNotification } from "@mantine/notifications";
import { useDeleteAnswer } from "../../hooks/post/useDeleteAnswer";
import { useDeletePostImg } from "../../hooks/post/useDeletePostImg";
import { useDeletePostVideo } from "../../hooks/post/useDeletePostVideo";
import { useCreateHighlightPost } from "../../hooks/highlight/useCreateHighlightPost";
import { useUpdateHighlightPost } from "../../hooks/highlight/useUpdateHighlightPost";
import { useGetHighlightPost } from "../../hooks/highlight/useGetHighlightPost";
import { useDeleteHighlightPostAnswer } from "../../hooks/highlight/useDeleteHighlighPostAnswer";
import { useDeleteHighlightPostImage } from "../../hooks/highlight/useDeleteHighlighPostImage";
import { useDeleteHighlightPostVideo } from "../../hooks/highlight/useDeleteHighlightPostVideo";
import { AppContext } from "../../../../Helpers/Context";
import HashtagHighlighter from "../../../../Components/Common/HashtagHighligter";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { UserAvatar } from "../../../../Components/Common/UserAvatar";

const SelectItem = forwardRef(({ value, label, icon, ...others }, ref) => (
  <div ref={ref} {...others}>
    <Group noWrap>
      <div dangerouslySetInnerHTML={{ __html: icon }} />
      <span>{label}</span>
    </Group>
  </div>
));

SelectItem.displayName = "SelectItem";

export const CustomSelect = ({ value, onChange, data }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const selectedOption = data?.find((item) => item.id.toString() === value);

  // Click outside to close
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div ref={selectRef} className="custom-select">
      {/* Selected Value Display */}
      <div
        style={{ border: "1px solid #029199" }}
        className="select-display"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedOption ? (
          <div className="selected-item">
            <div
              dangerouslySetInnerHTML={{ __html: selectedOption.svg_icon }}
            />
            <span>{selectedOption.name}</span>
          </div>
        ) : (
          <span style={{ color: "#029199" }} className="placeholder">
            {t("label")}
          </span>
        )}
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          className={`arrow ${isOpen ? "open" : ""}`}
        >
          <path d="M2 4L6 8L10 4" stroke="#029199" fill="none" />
        </svg>
      </div>

      {/* Dropdown Options */}
      {isOpen && (
        <div className="options-container">
          {data?.map((option) => (
            <div
              key={option.id}
              className={`option ${
                value === option.id.toString() ? "selected" : ""
              }`}
              onClick={() => {
                onChange(option.id.toString());
                setIsOpen(false);
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: option.svg_icon }} />
              <span>{option.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

// Köməkçi funksiya - faylı base64-ə çevirmək üçün
const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

// Helper function to strip HTML and check for actual content
const hasValidContent = (htmlString) => {
  // Remove HTML tags
  const textContent = htmlString.replace(/<[^>]*>/g, '');
  // Remove whitespace
  const trimmedContent = textContent.trim();
  return trimmedContent.length > 0;
};

const ModalPostLayout = ({
  opened,
  onClose,
  children,
  active,
  postId,
  highlightId,
  type,
  postTab,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("");
  const [label, setLabel] = useState(null);
  const [text, setText] = useState("");
  const [mediaFiles, setMediaFiles] = useState([]);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(null);
  const [selectedMediaId, setSelectedMediaId] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const fileInputRef = useRef(null);
  const [questionOptions, setQuestionOptions] = useState({
    is_quiz: false,
    options: [
      { answer_text: "", is_true: false },
      { answer_text: "", is_true: false },
    ],
  });
  const { id: communityId } = useParams();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useContext(AppContext);

  // Reset all states when modal closes
  const resetStates = () => {
    setActiveTab(active);
    setLabel(null);
    setText("");
    setMediaFiles([]);
    setSelectedMediaIndex(null);
    setQuestionOptions({
      is_quiz: false,
      options: [
        { answer_text: "", is_true: false },
        { answer_text: "", is_true: false },
      ],
    });
    setIsEditMode(false);
  };

  useEffect(() => {
    if (!opened && !postId) {
      resetStates();
    }
  }, [opened, onClose, postId]);

  // Watch for modal close
  useEffect(() => {
    setActiveTab(active);
  }, [active, type]);

  const { data: labelData } = useGetAllPostLabels();
  const { data: communityPost } = useGetPost(communityId, postId, type);

  const { data: highlightPost } = useGetHighlightPost(
    communityId,
    highlightId,
    postId,
    type
  );
  const post = (type === "community" || type === "savedPosts" || type === "sharedPost") ? communityPost : highlightPost;

  const { mutate: createPost, isLoading: isCreatingPost } = useCreatePost({
    closeModal: onClose,
    resetStates: resetStates,
    communityId,
    postTab,
    label,
  });

  const { mutate: createHighlightPost, isLoading: isCreatingHighlightPost } =
    useCreateHighlightPost({
      closeModal: onClose,
      resetStates: resetStates,
      community_id: communityId,
      highlight_id: highlightId,
      post_tab: postTab,
      label: label,
    });

  const { mutate: updatePost, isLoading: isUpdatingPost } = useUpdatePost({
    closeModal: onClose,
    resetStates: resetStates,
    community_id: communityId,
    post_id: postId,
    post_tab: postTab,
    label: label,
    type: type,
  });

  const { mutate: updateHighlightPost, isLoading: isUpdatingHighlightPost } =
    useUpdateHighlightPost({
      closeModal: onClose,
      resetStates: resetStates,
      community_id: communityId,
      highlight_id: highlightId,
      post_tab: postTab,
      label: label,
    });

  const { mutate: deleteAnswer } = useDeleteAnswer({
    community_id: communityId,
    post_id: postId,
    post_tab: postTab,
  });

  const { mutate: deleteHighlightPostAnswer } = useDeleteHighlightPostAnswer({
    community_id: communityId,
    highlight_id: highlightId,
    post_id: postId,
    post_tab: postTab,
    label: label,
  });

  const { mutate: deletePostImage } = useDeletePostImg(communityId, postTab);

  const { mutate: deleteHighlightPostImage } = useDeleteHighlightPostImage({
    community_id: communityId,
    highlight_id: highlightId,
    post_id: postId,
    post_tab: postTab,
  });

  const { mutate: deletePostVideo } = useDeletePostVideo(communityId, postTab);

  const { mutate: deleteHighlightPostVideo } = useDeleteHighlightPostVideo({
    community_id: communityId,
    highlight_id: highlightId,
    post_id: postId,
    post_tab: postTab,
  });
  // Load post data when in edit mode
  useEffect(() => {
    if (postId && post) {
      setIsEditMode(true);
      setText(post.text || "");
      setLabel(post.label?.toString());

      // Handle existing media files
      if (
        post.community_images?.length > 0 ||
        post.community_videos?.length > 0
      ) {
        setActiveTab("media");
        const existingMedia = [
          ...(post.community_images || []).map((img) => ({
            type: "image",
            url: img.image,
            isExisting: true,
            id: img.id,
          })),
          ...(post.community_videos || []).map((vid) => ({
            type: "video",
            url: vid.video_url,
            isExisting: true,
            id: vid.id,
          })),
        ];
        setMediaFiles(existingMedia);
      }

      // Handle quiz/poll data
      if (post.questions && post.questions.length > 0) {
        setActiveTab(post.questions[0].is_quiz ? "quiz" : "poll");
        setQuestionOptions({
          is_quiz: post.questions[0].is_quiz,
          options: post.questions[0].community_question_answers_output.map(
            (answer) => ({
              id: answer.id,
              answer_text: answer.answer_text,
              is_true: answer?.is_true_output,
            })
          ),
        });
      }
    }
  }, [post, postId, type]);
  const accessTokenX = "38c6f5ca7dab2349d12ad6d5e716c759";

  const handleVideo = async (e) => {
    if (e !== undefined) {
    setIsUploading(true);
      const accessTokenX = "38c6f5ca7dab2349d12ad6d5e716c759";
  
    const headerPost = {
      Accept: "application/vnd.vimeo.*+json;version=3.4",
      Authorization: `bearer ${accessTokenX}`,
      "Content-Type": "application/json",
    };
  
      const file = e;
    const fileSize = file.size.toString();
  
    try {
        // Initial upload request
      const uploadResponse = await axios({
        method: "post",
        url: `https://api.vimeo.com/me/videos`,
        headers: headerPost,
        data: {
          upload: {
            approach: "tus",
            size: fileSize,
          },
            privacy: {
              view: 'anybody',
              embed: 'public'
            }
        },
      });
  
        // Video upload
        return new Promise((resolve, reject) => {
      const upload = new tus.Upload(file, {
        endPoint: "https://api.vimeo.com/me/videos",
        uploadUrl: uploadResponse.data.upload.upload_link,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: file.name,
          filetype: file.type,
        },
        headers: {},
            onProgress: function (bytesUploaded, bytesTotal) {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          setUploadProgress(Number(percentage));
        },
            onSuccess: async function () {
              try {
      const videoUri = uploadResponse.data.uri;
                let videoReady = false;
      let attempts = 0;
                const maxAttempts = 60; // Increased max attempts
                let finalVideoData = null;

                // Function to check video availability
                const checkVideoAvailability = async () => {
                  const statusResponse = await axios({
                    method: 'get',
                    url: `https://api.vimeo.com${videoUri}`,
          headers: headerPost,
        });

                  // Check both transcode status and playback status
      return {
                    isReady: statusResponse.data.transcode.status === 'complete' && 
                            statusResponse.data.status === 'available',
                    data: statusResponse.data
                  };
                };

                while (!videoReady && attempts < maxAttempts) {
                  const { isReady, data } = await checkVideoAvailability();
                  
                  if (isReady) {
                    videoReady = true;
                    finalVideoData = data;
                    break;
                  }

                  attempts++;
                  // Exponential backoff: wait longer between checks as time goes on
                  const waitTime = Math.min(2000 * Math.pow(1.5, attempts), 10000);
                  await new Promise(r => setTimeout(r, waitTime));
                }

                if (videoReady && finalVideoData) {
                  // Get the embed code
                  const embedHtml = finalVideoData.embed.html;
                  const srcMatch = embedHtml.match(/src="([^"]+)"/);
                  
                  if (srcMatch && srcMatch[1]) {
                    const embedUrl = srcMatch[1];
                    
                    // Do one final check to ensure the video is playable
                    try {
                      const checkResponse = await axios.head(embedUrl);
                      if (checkResponse.status === 200) {
                        uploadResponse.data.player_embed_url = embedUrl;
                        setIsUploading(false);
                        setUploadProgress(0);
                        resolve(uploadResponse);
                      } else {
                        throw new Error('Video not yet available');
                      }
                    } catch (error) {
                      // If the final check fails, wait a bit longer and try one more time
                      await new Promise(r => setTimeout(r, 5000));
                      uploadResponse.data.player_embed_url = embedUrl;
                      setIsUploading(false);
                      setUploadProgress(0);
                      resolve(uploadResponse);
                    }
                  }
                } else {
                  // If video is still not ready, show notification but allow the post to be created
                  showNotification({
                    title: t("video_processing_title"),
                    message: t("video_processing_message"),
                    color: "yellow"
                  });
                  // Return the URL anyway - it will work after processing
                  uploadResponse.data.player_embed_url = `https://player.vimeo.com${videoUri}`;
                  setIsUploading(false);
                  setUploadProgress(0);
                  resolve(uploadResponse);
                }
              } catch (error) {
                console.error("Error checking video status:", error);
                setIsUploading(false);
                setUploadProgress(0);
                reject(error);
              }
            },
            onError: function (error) {
              setIsUploading(false);
              setUploadProgress(0);
              console.error("Upload failed", error);
              reject(error);
            },
          });

          upload.start();
        });
      } catch (error) {
        setIsUploading(false);
        setUploadProgress(0);
        console.error("Error initiating upload", error);
        throw error;
      }
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter(
      (file) => file.type.startsWith("image/") || file.type.startsWith("video/")
    );

    setMediaFiles((prev) => [
      ...prev,
      ...validFiles.map((file) => ({
        url: URL.createObjectURL(file),
        type: file.type.startsWith("image/") ? "image" : "video",
        file,
      })),
    ]);
  };

  const handleAddMore = () => {
    fileInputRef.current?.click();
  };

  const handleDelete = (index, media_id, file_type) => {
    if (media_id) {
      if (file_type === "image") {
        if (type === "community" || type === "sharedPost" || type === "savedPosts") {
          deletePostImage({
            community_id: communityId,
            post_id: postId,
            image_id: media_id,
          });
        } else {
          deleteHighlightPostImage({
            community_id: communityId,
            highlight_id: highlightId,
            post_id: postId,
            image_id: media_id,
          });
        }
      } else if (file_type === "video") {
        if (type === "community" || type === "sharedPost" || type === "savedPosts") {
          deletePostVideo({
            community_id: communityId,
            post_id: postId,
            video_id: media_id,
          });
        } else {
          deleteHighlightPostVideo({
            community_id: communityId,
            highlight_id: highlightId,
            post_id: postId,
            video_id: media_id,
          });
        }
      }
      setSelectedMediaIndex(null);
    } else {
      setMediaFiles((prev) => prev.filter((_, i) => i !== index));
      setSelectedMediaIndex(null);
    }
  };

  const handleOptionChange = (index, value) => {
    setQuestionOptions((prev) => ({
      ...prev,
      options: prev.options.map((opt, i) =>
        i === index ? { ...opt, answer_text: value } : opt
      ),
    }));
  };

  const handleCorrectAnswerSelect = (index) => {
    if (activeTab === "quiz" || activeTab === "poll") {
      setQuestionOptions((prev) => ({
        ...prev,
        options: prev.options.map((opt, i) => ({
          ...opt,
          is_true: i === index,
        })),
      }));
    }
  };

  const handleAddOption = () => {
    setQuestionOptions((prev) => ({
      ...prev,
      options: [...prev.options, { answer_text: "", is_true: false }],
    }));
  };

  const handleDeleteOption = (index, answer_id) => {
    if (answer_id) {
      if (type === "community" || type === "savedPosts" || type === "sharedPost") {
        deleteAnswer({
          community_id: communityId,
          post_id: postId,
          question_id: post.questions[0].id,
          answer_id: answer_id,
        });
      } else if (type === "highlight") {
        deleteHighlightPostAnswer({
          community_id: communityId,
          highlight_id: highlightId,
          post_id: postId,
          question_id: post.questions[0].id,
          answer_id: answer_id,
        });
      }
    } else {
      setQuestionOptions((prev) => ({
        ...prev,
        options: prev.options.filter((_, i) => i !== index),
      }));
    }
  };

  const handleCreatePost = async () => {
    try {
      setIsSubmitting(true);
      // Add validation check for text and label
      if (!hasValidContent(text) || !label) {
        showNotification({
          title: t("attention"),
          message: t("please_fill_text_and_label"),
          color: "yellow",
        });
        return;
      }

      // Add validation for quiz correct answer
      if (activeTab === "quiz") {
        const hasCorrectAnswer = questionOptions.options.some(opt => opt.is_true);
        if (!hasCorrectAnswer) {
          showNotification({
            title: t("attention"),
            message: t("please_select_correct_answer"),
            color: "yellow",
          });
          return;
        }
      }

      const postData = {
        label: Number(label),
        text: text,
        community_images: [],
        community_videos: [],
      };

      // Handle media files
      if (mediaFiles.length > 0) {
        for (const file of mediaFiles) {
          // Handle new files
          if (file.type === "image" && !file.isExisting) {
            const base64Image = await fileToBase64(file.file);
            postData.community_images.push({ image: base64Image });
          } else if (file.type === "video" && !file.isExisting) {
            const uploadedVideo = await handleVideo(file.file);
            let returnedLink = uploadedVideo?.data?.player_embed_url;
            postData.community_videos.push({ video_url: returnedLink });
          }
        }
      }

      // Add quiz/poll data if present
      if (activeTab === "quiz" || activeTab === "poll") {
        postData.community_post_questions = {
          is_quiz: activeTab === "quiz",
          community_question_answers: questionOptions.options
            .filter((opt) => opt.answer_text.trim() !== "")
            .map((opt) => ({
              ...(opt.id && { id: opt.id }),
              answer_text: opt.answer_text,
              is_true: opt.is_true,
            })),
        };
      }

      if (isEditMode) {
        // Update existing post
        if (type === "community" || type === "savedPosts" || type === "sharedPost") {
          updatePost({
            communityId,
            postId,
            data: postData,
          });
        } else if (type === "highlight") {
          updateHighlightPost({
            community_id: communityId,
            highlight_id: highlightId,
            post_id: postId,
            data: postData,
          });
        }
      } else {
        // Create new post
        if (type === "community" || type === "savedPosts" || type === "sharedPost") {
          createPost({
            id: communityId,
            data: postData,
          });
        } else if (type === "highlight") {
          createHighlightPost({
            community_id: communityId,
            highlight_id: highlightId,
            data: postData,
          });
        }
      }
    } catch (error) {
      console.error("Error processing post:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Add this function to handle textarea auto-height
  const handleTextareaHeight = (e) => {
    const textarea = e.target;
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";

    const inputValue = e.target.value;

    // Hashtag'ləri qalın hərflərlə işarələyən funksiya
    const formattedText = inputValue.replace(/#(\w+)/g, "**#$1**");

    setText(formattedText); // State-ə formatlı mətn əlavə edilir
  };

  const xs = useMediaQuery('(max-width: 768px)');

  const renderContent = () => {
    switch (activeTab) {
      case "text":
        return <HashtagHighlighter text={text} setText={setText} />;
      case "media":
        return (
          <div className="media-content">
            <HashtagHighlighter text={text} setText={setText} />

            {/* Media Preview */}
            {mediaFiles.length > 0 && (
              <div className="media-preview">
                {mediaFiles.map((file, index) => (
                  <div
                    key={index}
                    className={`media-item ${
                      selectedMediaIndex === index ? "selected" : ""
                    }`}
                    onClick={() => {
                      setSelectedMediaIndex(index);
                      setSelectedMediaId(file.id);
                      setSelectedFileType(file.type);
                    }}
                  >
                    {file.type === "image" ? (
                      <img src={file.url} alt={`Preview ${index}`} />
                    ) : (
                      <div className="video-container">
                        {file.isExisting ? (
                          <>
                            <iframe
                              src={`${file.url}?autoplay=0&title=0&byline=0&portrait=0`}
                              width="100%"
                              height="100%"
                              frameBorder="0"
                              allow="autoplay; fullscreen; picture-in-picture"
                              allowFullScreen
                              title={`Video preview ${index + 1}`}
                            />
                            {isUploading && (
                              <div className="video-loading-overlay">
                                <Loader size="md" color="#fff" />
                                <Text color="#fff" size="sm" align="center">
                                  {uploadProgress < 100 
                                    ? t("video_uploading", { progress: Math.round(uploadProgress) })
                                    : t("video_processing")}
                                </Text>
                              </div>
                            )}
                          </>
                        ) : (
                          <video src={file.url} controls />
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      case "quiz":
      case "poll":
        return (
          <div className="quiz-container">
            <Text weight={500} size={16} color="#202020" mb={8}>
              {t('post-question')}
            </Text>
            <HashtagHighlighter text={text} setText={setText} />

            <Text weight={500} size={16} color="#202020" mt={16} mb={8}>
              {t("option")}
            </Text>
            {questionOptions.options.map((option, index) => (
              <div key={index} className="quiz-option">
                {activeTab === "quiz" && (
                  <input
                    type="radio"
                    name="is_true"
                    checked={option.is_true}
                    onChange={() => handleCorrectAnswerSelect(index)}
                  />
                )}
                <input
                  type="text"
                  value={option.answer_text}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  placeholder={`${t("option")} #${index + 1}`}
                />
                <button onClick={() => handleDeleteOption(index, option.id)}>
                  <Trash size={16} color="#FF5858" />
                </button>
              </div>
            ))}

            <Button
              leftIcon={<Plus size={16} color="#fff" />}
              onClick={handleAddOption}
              variant="primary"
              color="#fff"
              backgroundColor="#00BD90"
              borderRadius="10px"
              padding="8px 16px"
            >
              {t("add-option")}
            </Button>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <ModalPostLayoutStyled>
      <Modal
        className="modal-post-layout"
        opened={opened}
        onClose={onClose}
        centered
        withCloseButton={false}
        styles={{
          header: {
            padding: xs ? "16px 8px" : "16px 24px",
            marginBottom: 0,
            borderBottom: xs ? "none" : "1px solid #E3E3E3",
          },
          title: {
            width: "100%",
          },
        }}
        title={
          <Group noWrap={xs ? true : false} position="apart" align={xs ? "center" : "flex-end"} style={{ width: "100%" }}>
            <Text
              style={{ textTransform: "capitalize" }}
              weight={600}
              size={xs ? 16 : 20}
              color="#202020"
            >
              {
                isEditMode && activeTab === "text" ? t("edit-text-post") :
                isEditMode && activeTab === "media" ? t("edit-media-post") :
                isEditMode && activeTab === "poll" ? t("edit-poll-post") :
                isEditMode && activeTab === "quiz" ? t("edit-quiz-post") :
                activeTab === "media" ? t("create-media-post") :
                activeTab === "poll" ? t("create-poll-post") :
                activeTab === "quiz" ? t("create-quiz-post") :
                t("create-text-post")
              }

            </Text>
            <CustomSelect value={label} onChange={setLabel} data={labelData} />
          </Group>
        }
      >
        <div className="modal-inner-layout">
          <Group align="center" spacing={8}>
            <div className="avatar">
              {/* <img
                style={{ borderRadius: "50%", width: "100%", height: "100%" }}
                src={user?.profile_img}
                alt="avatar"
              /> */}
              <UserAvatar img={user?.profile_img} size="100%"  radius="50%" />
            </div>
            <Text>
              {user?.first_name} {user?.last_name}
            </Text>
          </Group>

          {renderContent()}

          <Group width="100%" spacing={8} className="post-actions">
            <div className="action-btn">
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                accept="image/png,image/jpeg,image/jpg,video/*"
                multiple
                style={{ display: "none" }}
              />
              <div
                className={`tab-item ${
                  activeTab === "text" ? "active text" : ""
                }`}
                onClick={() => {
                  setActiveTab("text");
                }}
              >
                <img className="text-icon" src={text_icon} alt="text" />
              </div>
              <div
                className={`tab-item ${
                  activeTab === "media" ? "active media" : ""
                }`}
                onClick={() => {
                  setActiveTab("media");
                }}
              >
                <img className="media-icon" src={photo_video} alt="Media" />
              </div>
              <div
                className={`tab-item ${
                  activeTab === "poll" ? "active poll" : ""
                }`}
                onClick={() => setActiveTab("poll")}
              >
                <img className="poll-icon" src={poll} alt="Poll" />
              </div>
              <div
                className={`tab-item ${
                  activeTab === "quiz" ? "active quiz" : ""
                }`}
                onClick={() => setActiveTab("quiz")}
              >
                <img className="quiz-icon" src={quiz} alt="Quiz" />
              </div>
            </div>

            {(mediaFiles.length > 0 || activeTab === "media") && (
              <div className="media-actions">
                {activeTab === "media" && (
                  <button onClick={handleAddMore}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <CirclePlus color="#707070" strokeWidth={1.5} size={xs ? 24 : 30} />
                      {mediaFiles.length === 0 && (
                        <Text size={xs ? 12 : 14} weight={500} color="#707070">
                          {t("add-media")}
                        </Text>
                      )}
                    </div>
                  </button>
                )}
                {mediaFiles.length > 0 && (
                  <button
                    onClick={() =>
                      handleDelete(
                        selectedMediaIndex,
                        selectedMediaId,
                        selectedFileType
                      )
                    }
                  >
                    <Trash color="#707070" strokeWidth={1.5} size={xs ? 24 : 28} />
                  </button>
                )}
              </div>
            )}
          </Group>
        </div>
        <Group mt="16px" className="post-controls" position={xs ? "center" : "right"}>
          <Button
            variant="cancel"
            onClick={onClose}
            padding={xs ? "8px 48px" : "12px 48px"}
            disabled={isSubmitting || isUploading}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={handleCreatePost}
            color="#323922"
            backgroundColor="#CCF075"
            padding={xs ? "8px 56px" : "12px 56px"}
            disabled={
              isSubmitting ||
              isUploading ||
              isCreatingPost ||
              isCreatingHighlightPost ||
              isUpdatingPost ||
              isUpdatingHighlightPost
            }
          >
            {isSubmitting ||
            isUploading ||
            isCreatingPost ||
            isCreatingHighlightPost ||
            isUpdatingPost ||
            isUpdatingHighlightPost ? (
              <>
                <Loader size="sm" color="#323922" />
                <span style={{ marginLeft: "8px" }}>
                  {isEditMode ? t("updating") : t("posting")}
                </span>
              </>
            ) : isEditMode ? (
              t("update")
            ) : (
              t("post-share")
            )}
          </Button>
        </Group>
      </Modal>
    </ModalPostLayoutStyled>
  );
};

export default ModalPostLayout;
