import { useQuery } from "@tanstack/react-query";
import { usePost } from "./usePost";
import { getPost } from "../../services/apiPost";


export const useGetPost = (communityId,postId,type,highlightId) => {
    const { handleError} = usePost();

    return useQuery({
        queryKey: ['detail',communityId,postId],
        queryFn: () => getPost(communityId,postId),
        onError: (error) => handleError(error),
        enabled: !!communityId && !!postId && !highlightId && (type === "community" || type === "savedPosts" || type === "sharedPost"),
    })
}
