import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePost } from "./usePost";
import { deletePost } from "../../services/apiPost";
import { useTranslation } from "react-i18next";
export const useDeletePost = (community_id,post_tab,type) => {
    const {handleError, handleSuccess} = usePost();
    const queryClient = useQueryClient();
    const {t} = useTranslation()
    return useMutation({
        mutationFn: ({community_id, id}) => deletePost({community_id, id}),
        onSuccess: (data) => {
            if(type==="savedPosts"){
                queryClient.invalidateQueries({queryKey: ['savedPosts',community_id,post_tab]});
                queryClient.invalidateQueries({queryKey: ['all',community_id,post_tab]});
            }else{
                queryClient.invalidateQueries({queryKey: ['all',community_id,post_tab]});
            }
            handleSuccess(t("community_post_deleted_successfully"));
        },
        onError: (error) => handleError(error),
    })
}
