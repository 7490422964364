export const getAcronym = (sentence) => {
    // Boşluklara göre cümleyi ayır
    const words = sentence?.split(' ');
    
    // Baş harfleri toplamak için bir dizi oluştur
    const acronym = words?.reduce((acc, word) => {
      // Kelimenin ilk harfini al ve küçük harfe çevir
      const firstLetter = word[0]?.toLowerCase();
      // Eğer ilk harf küçük ise akronime ekle
      if (firstLetter === firstLetter?.toLowerCase()) {
        acc += firstLetter;
      }
      return acc;
    }, '');
    
    return acronym;
  }

  export function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
}
export const formatDateToMonthYear = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'long' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};
export const formatDateToMonthDayYear = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const convertToBase64 = (input) => {
  const files = Array.isArray(input) ? input : [input];
  
  if (files.length === 1) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.onload = () => {
        const base64String = fileReader.result;
        resolve(base64String);
      };
      fileReader.onerror = (error) => reject(error);
    });
  } else {
    return Promise.all(files.map(file => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          const base64String = fileReader.result;
          resolve(base64String);
        };
        fileReader.onerror = (error) => reject(error);
      });
    }));
  }
};

export const formatDate2 = (isoString) => {
  if (!isoString) return '';
  
  const date = new Date(isoString);
  const currentLang = localStorage.getItem('tsklng') || 'en';

  const formatConfigs = {
    az: {
      weekday: {
        long: ['Bazar', 'Bazar ertəsi', 'Çərşənbə axşamı', 'Çərşənbə', 'Cümə axşamı', 'Cümə', 'Şənbə'],
      },
      months: {
        short: ['Yan', 'Fev', 'Mar', 'Apr', 'May', 'İyun', 'İyul', 'Avq', 'Sen', 'Okt', 'Noy', 'Dek'],
      }
    },
    en: {
      weekday: {
        long: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      },
      months: {
        short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      }
    },
    ru: {
      weekday: {
        long: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
      },
      months: {
        short: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
      }
    },
    tr: {
      weekday: {
        long: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
      },
      months: {
        short: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'],
      }
    }
  };

  const config = formatConfigs[currentLang] || formatConfigs.en;
  const weekDay = config.weekday.long[date.getDay()];
  const month = config.months.short[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${weekDay}, ${day} ${month}, ${year}`;
};

export const getSlugAndIdFromPath = (pathname) => {
  try {
      const pattern = /([^/]+)-(\d+)(?=\/|$)/;
      const match = pathname.match(pattern);
      
      if (!match) {
          throw new Error('Invalid path format');
      }
      
      return {
          slug: match[1],
          id: match[2]
      };
  } catch (error) {
      console.error('Error parsing path:', error);
      return {
          slug: '',
          id: ''
      };
  }
};