import { useContext, useEffect, useState } from "react";
import axios from "../../../Helpers/Axios";
import styled from "styled-components";
import parse from "html-react-parser";
import AnswerSection from "./AnswerSection";
import { Quiz } from "./Quiz/Quiz";
import { AppContext } from "../../../Helpers/Context";
import { Button } from "@mantine/core";
import { Lock } from "tabler-icons-react";

function UnpurchasedSection({ props }) {
  const { user } = useContext(AppContext);
  const { courseData, currentSection ,type} = props;

  const [connecting, setConnecting] = useState(false);

  const [payInSection, setPayInSection] = useState(false);
  const [sections, setSections] = useState([]);

  const [order, setOrder] = useState({});
  const [buyCourse, setBuyCourse] = useState(false);


  useEffect(() => {
    if (courseData?.id) {
      (async () => {
        const apiUrl = type === 'course' ? `/api/section-detail/${courseData?.id}/` : `steam/section_parts_list/${courseData?.id}/`;
        const response = await axios.get(apiUrl);
        if (response.data.length > 0) {
          let filtered = response.data.filter(section => !section.is_final_project).map((section) => ({
            id: section?.id,
            title: section.title,
            chosen: false,
            section_active: section.section_active,
            is_final_project: section.is_final_project,
          }));
          setSections(filtered);
        }
      })();
    }
    return () => {
      setSections([]);
    };
  }, []);

  useEffect(() => {
    if (order.url) {
      localStorage.setItem("CPOrderIdTaskool", order.order_id);
      window.location = order.url;
    }
  }, [order]);

  useEffect(() => {
    if (buyCourse === true) {
      (async () => {
        if (payInSection) {
          setConnecting(true);
          try {
            const response = await axios.post(`api/create-order/`, {
              course: courseData?.id,
              user: user?.id,
              promocode: "",
              installment_paid: true,
              sections: [currentSection?.id],
            });

            if (response.status === 201) {
              setOrder(response.data);
            }
          } catch (error) {}
        } else {
          try {
            setConnecting(true);
            const response = await axios.post(`api/create-order/`, {
              course: courseData?.id,
              user: user?.id,
              promocode: "",
              installment_paid: false,
              sections: [],
            });

            if (response.status === 201) {
              setOrder(response.data);
            }
          } catch (error) {}
        }
      })();
    }
  }, [buyCourse]);

  function buySectionAlone() {
    setPayInSection(true);
    setBuyCourse(true);
  }

  

  return (
    sections.length > 0 && (
      <div className="unpurchasedSection" style={{ marginLeft: "1rem" }}>
        <h2 style={{ display: "flex", alignItems: "center", gridGap: "1rem" }}>
          <Lock /> {currentSection?.title} -{" "}
          {(courseData.price / sections.length).toFixed(2)} AZN
        </h2>
        <h3>You have not purchased this section yet</h3>
        <p></p>
        <div style={{ display: "flex", gridGap: "1rem" }}>
          <Button
            disabled={connecting}
            variant="gradient"
            gradient={{ from: "teal", to: "lime", deg: 105 }}
            onClick={() => buySectionAlone()}
          >
            Buy Section
          </Button>
        </div>
      </div>
    )
  );
}

export const Material = ({ props }) => {
  const { courseData, currentSection, showPart, currentSectionEntries } = props;

  return (
    <MaterialStyle>
      {currentSection?.section_active ? (
        currentSectionEntries?.map((content) => {
          return showPart?.entry?.id === content?.id &&
            showPart?.entry?.type === content?.type &&
            showPart?.entry?.type === "quiz" ? (
            <Quiz props={{ content }} key={content?.id} />
          ) : (
            showPart?.entry?.id === content?.id &&
              showPart?.entry?.type === content?.type && (
                <div key={content?.id} className="materialInside">
                  {content?.file?.includes("player") && (
                    <iframe
                      title="videox"
                      className="videoFrame"
                      width="100%"
                      height="540"
                      src={content?.file}
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                    />
                  )}

                  <div className="headerMaterial">
                    <h2>{content?.title}</h2>
                    {parse(content?.description)}
                  </div>

                  {(content?.file?.endsWith(".jpg") ||
                    content?.file?.endsWith(".png") ||
                    content?.file?.endsWith(".webp") ||
                    content?.file?.endsWith(".jpeg")) && (
                    <img src={content?.file} alt="" />
                  )}

                  {content?.file && !content?.file?.includes("player") && (
                    <a href={content?.file}>file - {content?.title}</a>
                  )}

                  {content?.is_question === true && (
                    <AnswerSection
                      props={{ showPart, section: currentSection }}
                    />
                  )}
                </div>
              )
          );
        })
      ) : (
        <UnpurchasedSection props={{ courseData, currentSection }} />
      )}
    </MaterialStyle>
  );
};

const MaterialStyle = styled.div`
  .materialInside {
  }
  img {
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
  .headerMaterial {
    p {
      width: 80%;
      font-size: 17px;
    }
  }
  .question {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    hr {
      width: 100%;
      border: none;
      border-bottom: 1px solid #aaa;
      margin-top: 0;
    }
    form {
      textarea {
        width: 96.5%;
        padding: 1rem;
        border-radius: 0.25rem;
        font-size: 15px;
        resize: none;
      }
      button {
        margin-top: 1rem;
        font-size: 15px;
        background-color: var(--yellow);
        padding: 0.5rem 2rem;
        border: none;
        &:hover {
          background-color: #e9cd16;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .videoFrame {
      height: 210px;
      width: 100%;
      margin-top: 1.25rem;
    }
  }
`;
