import React from "react";
import styled from "styled-components";
import { Grid, Title } from "@mantine/core";
import { Helmet } from "react-helmet-async";
import AgentCard from "../../Components/StudyAbroad/Agents/AgentCard";
import {useQuery} from '@tanstack/react-query'
import axios from '../../Helpers/Axios'
import { useTranslation } from "react-i18next";

const Agents = () => {
  const {data : agents} = useQuery({
    queryKey : ["agents"],
    queryFn : async () => {
      const {data} = await axios.get("/university/agent/rank/list/")
      return data
    }
  })
  const {t} = useTranslation()

  return (
    <AgentContainer>
      <Helmet>
        <title>{t('agents_page_title')}</title>
        <meta 
          name="description" 
          content={t('agents_page_description')} 
        />
      </Helmet>

      <Title mb={40} order={1} size={24} weight={500} color="#202939">
        {t('agent')}
      </Title>
      <Grid>
        {
          agents?.map((agent) => (
            <Grid.Col key={agent.id} sm={3}>
              <AgentCard t={t} agent={agent} />
            </Grid.Col>
          ))
        }
      </Grid>
    </AgentContainer>
  );
};

export default Agents;

const AgentContainer = styled.div`
  max-width: 90%;
  margin: 20px auto;
  min-height: screen;
  min-height: 100vh;
`;
