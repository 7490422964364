import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createHighlight } from "../../services/apiHighlight";
import { useHighlight } from "./useHighlight";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
export const useCreateHighlight = (communityId, firstTime, onClose,onShowNoticiationModal) => {
    const {invalidateHighlightQueries, handleError, handleSuccess} = useHighlight();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const {t} = useTranslation()
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    return useMutation({
        mutationFn: (data) => createHighlight(communityId, data),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['highlights',communityId]});
            onClose();
            localStorage.removeItem('communityId');
            if(onShowNoticiationModal) {
              onShowNoticiationModal(true);
            } else {
                handleSuccess(t("highlight_created_successfully"));
            }

            // Check if this is the first highlight

            navigate(firstTime ? `/communities` : `/community/${communityId}`);
        },
        onError: (error) => handleError(error),
        showSuccessModal,
        setShowSuccessModal
    });
};