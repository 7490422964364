import axios from "../../../Helpers/Axios";

export const getTask = async (slug, olympiadId, taskId) => {
  const response = await axios.get(`/olympiad/${slug}-${olympiadId}/task/${taskId}/`);
  return response.data;
};


export const startTask = async (slug, olympiadId, taskId) => {
  const response = await axios.post(`/olympiad/${slug}-${olympiadId}/task/${taskId}/`);
  return response.data;
};

export const solveTask = async (slug, olympiadId, taskId, data) => {
  const response = await axios.post(`/olympiad/${slug}-${olympiadId}/task/${taskId}/solve/`, data);
  return response.data;
};

export const getSolveTask = async (slug, olympiadId, taskId) => {
  const response = await axios.get(`/olympiad/${slug}-${olympiadId}/task/${taskId}/solve/`);
  return response.data;
};