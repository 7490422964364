import axios from "../../../Helpers/Axios";

export const getOlympiad = async ({ pageParam = 1, filters = {} }) => {
  const queryParams = new URLSearchParams({
    page: pageParam,
    ...filters
  }).toString();
  
  const response = await axios.get(`/olympiad?${queryParams}`);
  return response.data;
};

export const getOlympiadType = async () => {
  const response = await axios.get("/olympiad/types");
  return response.data;
};

export const getCompetitionDetail = async (slug,id) => {
  const response = await axios.get(`/olympiad/${slug}-${id}/`);
  return response.data;
};

export const getStudentResults = async (competitionId, studentId) => {
  try {
    const response = await axios.get(`/olympiad/${competitionId}/student/${studentId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching student results:', error);
    throw error;
  }
};