import { useMutation } from "@tanstack/react-query";
import { useCommunity } from "./useCommunity";
import { updateCommunity } from "../../services/apiCommunity";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export const useUpdateCommunity = (communityId) => {
    const {t} = useTranslation()
    const {invalidateCommunityQueries, handleError, handleSuccess } = useCommunity();
    const navigate = useNavigate();
    return useMutation({
        mutationFn: ({id, data}) => updateCommunity({id, data}),
        onSuccess: (data) => {
            invalidateCommunityQueries(communityId);
            handleSuccess(t("community_updated_successfully"));
            navigate(`/community/${communityId}`);
        },
        onError: (error) => handleError(error),
    })
}
