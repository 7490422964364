import React, { useEffect, useState } from "react";
import { PostListStyled } from "../../../style";
import TextPost from "../../posts/TextPost";
import PollPost from "../../posts/PollPost";
import QuizPost from "../../posts/QuizPost";
import { useParams } from "react-router-dom";
import { useGetAllPost } from "../../../hooks/post/useGetAllPost";
import PostLayout from "../../posts/PostLayout";
import MediaPost from "../../posts/MediaPost";
import TextPostSkeleton from "../../skeletons/TextPostSkeleton";
import MediaPostSkeleton from "../../skeletons/MediaPostSkeleton";
import PollPostSkeleton from "../../skeletons/PollPostSkeleton";
import QuizPostSkeleton from "../../skeletons/QuizPostSkeleton";
import { useGetAllHighlightPosts } from "../../../hooks/highlight/useGetAllHighlightPosts";
import InfiniteScrollComponent from "../../../../../Components/Common/InfiniteScroll";
import { useGetAllSavePosts } from "../../../hooks/post/useGetAllSavePosts";
import { Stack, Text, Center, Box, ActionIcon, Group } from "@mantine/core";
import { useGetAllPostLabels } from "../../../hooks/post/useGetAllPostLabels";
import { CustomSelect } from "../../modal/ModalPostLayout";
import {
  IconMessageCircleOff,
  IconBookmarkOff,
  IconStarOff,
  IconMoodEmpty,
} from "@tabler/icons-react";
import Button from "../../../../../Components/FormComponent/Button";
import { X } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import { useGetPost } from "../../../hooks/post/useGetPost";
import { useGetHighlightPost } from "../../../hooks/highlight/useGetHighlightPost";
const PostList = ({
  type,
  setActiveTab,
  setOpenModal,
  openModal,
  setPostId,
  postTab,
  isOwner,
  label,
  setLabel,
  postId,
  setHisghlightIdInSavedPost
}) => {
  const { t } = useTranslation();
  const { id: communityId, highlightId } = useParams();
  const { data: labelData } = useGetAllPostLabels();
  const [sharedPostData, setSharedPostData] = useState(null);
  const {
    data: postsData,
    isLoading: isLoadingPosts,
    fetchNextPage: fetchNextPagePosts,
    hasNextPage: hasNextPagePosts,
    isFetchingNextPage: isFetchingNextPagePosts,
  } = useGetAllPost(communityId, type, postTab, label);

  const {
    data: highlightPostsData,
    isLoading: isLoadingHighlightPosts,
    fetchNextPage: fetchNextPageHighlight,
    hasNextPage: hasNextPageHighlight,
    isFetchingNextPage: isFetchingNextPageHighlight,
  } = useGetAllHighlightPosts(communityId, highlightId, type, postTab, label);

  const {
    data: savedPostsData,
    isLoading: isLoadingSavedPosts,
    fetchNextPage: fetchNextPageSavedPosts,
    hasNextPage: hasNextPageSavedPosts,
    isFetchingNextPage: isFetchingNextPageSavedPosts,
  } = useGetAllSavePosts(communityId, type, postTab, label);

  const { data: sharedCommunityPostData, isLoading: isLoadingSharedCommunityPost } = useGetPost(
    communityId,
    postId,
    type,
    highlightId
  );

  const {data : sharedHighlightPostData, isLoading: isLoadingSharedHighlightPost} = useGetHighlightPost(
    communityId,
    highlightId,
    postId,
    type
  )

  useEffect(() => {
    if (type === 'sharedPost' && highlightId) {
      setSharedPostData(sharedHighlightPostData);
    } else if (type === 'sharedPost' && !highlightId) {
      setSharedPostData(sharedCommunityPostData);
    }
  }, [type, highlightId, sharedHighlightPostData, sharedCommunityPostData]);

  const isLoadingSharedPost = (type === 'sharedPost' && highlightId) ? isLoadingSharedHighlightPost : isLoadingSharedCommunityPost;

  const isLoading =
    type === "highlight"
      ? isLoadingHighlightPosts
      : type === "savedPosts"
      ? isLoadingSavedPosts
      : type === "sharedPost"
      ? isLoadingSharedPost
      : isLoadingPosts;

  if (isLoading) {
    return (
      <PostListStyled>
        <MediaPostSkeleton />
        <PollPostSkeleton />
        <QuizPostSkeleton />
        <TextPostSkeleton />
      </PostListStyled>
    );
  }
  

  const renderPost = (post,highlight) => {
    // Əgər questions varsa Quiz və ya Poll postdur
    if (post?.questions?.length > 0) {
      return post.questions[0].is_quiz ? (
        <PostLayout
          isOwner={isOwner}
          label={label}
          postTab={postTab}
          type={type}
          openModal={openModal}
          setPostId={setPostId}
          postType="quiz"
          setActiveTab={setActiveTab}
          setOpenModal={setOpenModal}
          key={post.id}
          post={post}
          
        >
          <QuizPost postTab={postTab} label={label} type={type} post={post} highlight={highlight}  />
        </PostLayout>
      ) : (
        <PostLayout
          isOwner={isOwner}
          label={label}
          postTab={postTab}
          type={type}
          openModal={openModal}
          setPostId={setPostId}
          postType="poll"
          setActiveTab={setActiveTab}
          setOpenModal={setOpenModal}
          key={post.id}
          post={post}
        >
          <PollPost postTab={postTab} label={label} type={type} post={post} highlight={highlight} />
        </PostLayout>
      );
    }

    if (
      post?.community_images?.length > 0 ||
      post?.community_videos?.length > 0
    ) {
      return (
        <PostLayout
          isOwner={isOwner}
          label={label}
          postTab={postTab}
          type={type}
          openModal={openModal}
          setPostId={setPostId}
          postType="media"
          setActiveTab={setActiveTab}
          setOpenModal={setOpenModal}
          key={post.id}
          post={post}
        >
          <MediaPost type={type} post={post} />
        </PostLayout>
      );
    }

    // Əgər şəkillər varsa ImagePost

    // Sadəcə text varsa TextPost
    if (post?.text) {
      return (
        <PostLayout
          isOwner={isOwner}
          label={label}
          postTab={postTab}
          type={type}
          openModal={openModal}
          setPostId={setPostId}
          postType="text"
          setActiveTab={setActiveTab}
          setOpenModal={setOpenModal}
          key={post.id}
          post={post}
        >
          <TextPost type={type} post={post} />
        </PostLayout>
      );
    }

    return null; // Əgər heç biri deyilsə
  };

  const allPosts =
    type === "highlight"
      ? highlightPostsData?.pages.flatMap((page) => page.results) || []
      : type === "savedPosts"
      ? savedPostsData?.pages.flatMap((page) => page) || []
      : postsData?.pages.flatMap((page) => page.results) || [];

  const fetchNextPage =
    type === "highlight"
      ? fetchNextPageHighlight
      : type === "savedPosts"
      ? fetchNextPageSavedPosts
      : fetchNextPagePosts;

  const hasNextPage =
    type === "highlight"
      ? hasNextPageHighlight
      : type === "savedPosts"
      ? hasNextPageSavedPosts
      : hasNextPagePosts;

  const isFetchingNextPage =
    type === "highlight"
      ? isFetchingNextPageHighlight
      : type === "savedPosts"
      ? isFetchingNextPageSavedPosts
      : isFetchingNextPagePosts;

  if (allPosts.length === 0 && type !== "sharedPost") {
    const emptyStateConfig = {
      highlight: {
        icon: IconStarOff,
        title: t("empty-highlight-title-post"),
        description: t("empty-highlight-description-post"),
      },
      savedPosts: {
        icon: IconBookmarkOff,
        title: t("empty-saved-posts-title-post"),
        description: t("empty-saved-posts-description-post"),
      },
      default: {
        icon: IconMessageCircleOff,
        title: t("empty-posts-title-post"),
        description: t("empty-posts-description-post"),
      },
    };

    const config = emptyStateConfig[type] || emptyStateConfig.default;
    const Icon = config.icon;

    return (
      <PostListStyled>
        {type !== "savedPosts" && type !== "sharedPost" && (
          <Group noWrap align="center" spacing={15}>
            <CustomSelect value={label} onChange={setLabel} data={labelData} />
            {label && (
              <Button
                onClick={() => setLabel(null)}
                padding="0"
                variant="outline"
                color="#838383"
                border="none"
                rightIcon={
                  <X color="#838383" style={{ marginTop: 3 }} size={12} />
                }
              >
                {t("reset")}
              </Button>
            )}
          </Group>
        )}
        <Center style={{ minHeight: "calc(100vh - 200px)" }}>
          <Box sx={{ textAlign: "center", padding: "20px" }}>
            <Group position="center" mb={30}>
              <ActionIcon
                size={80}
                radius="xl"
                variant="light"
                color="gray"
                sx={(theme) => ({
                  backgroundColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[5]
                      : theme.colors.gray[0],
                  "&:hover": {
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[5]
                        : theme.colors.gray[0],
                  },
                })}
              >
                <Icon size={40} />
              </ActionIcon>
            </Group>

            <Text
              size="xl"
              weight={500}
              sx={(theme) => ({
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[0]
                    : theme.colors.gray[9],
                marginBottom: theme.spacing.md,
              })}
            >
              {config.title}
            </Text>

            <Text
              size="md"
              color="dimmed"
              sx={{
                maxWidth: 400,
                margin: "0 auto",
                lineHeight: 1.6,
              }}
            >
              {config.description}
            </Text>

            {type === "default" && (
              <Group position="center" mt={30}>
                <ActionIcon
                  size="xl"
                  radius="xl"
                  variant="light"
                  color="blue"
                  onClick={() => setOpenModal(true)}
                  sx={(theme) => ({
                    backgroundColor: theme.colors.blue[0],
                    color: theme.colors.blue[6],
                    "&:hover": {
                      backgroundColor: theme.colors.blue[1],
                    },
                  })}
                >
                  <IconMoodEmpty size={24} />
                </ActionIcon>
              </Group>
            )}
          </Box>
        </Center>
      </PostListStyled>
    );
  }

  return (
    <PostListStyled>
      {type !== "savedPosts" && type !== "sharedPost" && (
        <Group noWrap align="center" spacing={15}>
          <CustomSelect value={label} onChange={setLabel} data={labelData} />
          {label && (
            <Button
              onClick={() => setLabel(null)}
              padding="0"
              variant="outline"
              color="#838383"
              border="none"
              rightIcon={
                <X color="#838383" style={{ marginTop: 3 }} size={12} />
              }
            >
              {t("reset")}
            </Button>
          )}
        </Group>
      )}

      {type !== "sharedPost" ? (
        <InfiniteScrollComponent
          isFetching={isFetchingNextPage}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
        >
          {allPosts.map((post) => (
            <div style={{ marginBottom: 10 }} key={post?.id}>
              {renderPost(type === "savedPosts" ? post?.post_output : post,type === "savedPosts" ? `${post?.highlight_slug}-${post?.highlight}` : null)}
            </div>
          ))}
        </InfiniteScrollComponent>
      ) : (
        <div style={{ marginBottom: 10 }} key={sharedPostData?.id}>
          {renderPost(sharedPostData)}
        </div>
      )}
    </PostListStyled>
  );
};

export default PostList;
