import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Dummy data

const ResultsSection = ({ dummyResults, competitionId }) => {
  const midPoint = Math.ceil(dummyResults?.length / 2);
  const leftResults = dummyResults?.slice(0, midPoint);
  const rightResults = dummyResults?.slice(midPoint);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleStudentClick = (studentId) => {
    navigate(`/competition/${competitionId}/student/${studentId}`);
  };

  return (
    <ResultsWrapper>
      <ScrollableContainer results={dummyResults}>
        <ColumnsContainer>
          <Column>
            <HeaderRow>
              <HeaderText>{t("results.students")}</HeaderText>
              <HeaderScore>{t("results.score")}</HeaderScore>
            </HeaderRow>

            {leftResults.map((result, index) => (
              <ResultCard key={result.id}>
                <RankAndUser>
                  <Rank>{index + 1}.</Rank>
                  <Avatar src={result.profile_image} alt={result.first_name} />
                  <Name>
                    {result.first_name} {result.last_name}
                  </Name>
                </RankAndUser>
                <Score>{result.scores}</Score>
              </ResultCard>
            ))}
          </Column>
          <Column>
            <HeaderRow>
              <HeaderText>{t("results.students")}</HeaderText>
              <HeaderScore>{t("results.score")}</HeaderScore>
            </HeaderRow>

            {rightResults.map((result, index) => (
              <ResultCard key={result.id}>
                <RankAndUser>
                  <Rank>{midPoint + index + 1}.</Rank>
                  <Avatar src={result.profile_image} alt={result.first_name} />
                  <Name>
                    {result.first_name} {result.last_name}
                  </Name>
                </RankAndUser>
                <Score>{result.scores}</Score>
              </ResultCard>
            ))}
          </Column>
        </ColumnsContainer>
      </ScrollableContainer>
    </ResultsWrapper>
  );
};

const ResultsWrapper = styled.div`
  border-radius: 8px;
  padding: 0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  margin-top: 24px;
  max-width: 95%;
  @media (max-width: 1280px) {
    max-width: 85%;
  }
  @media (max-width: 1120px) {
    max-width: 90%;
  }
  @media (max-width: 990px) {
    max-width: 100%;
  }
`;

const ScrollableContainer = styled.div`
  height: ${(props) => (props.results?.length > 12 ? "400px" : "auto")};
  overflow-y: ${(props) => (props.results?.length > 12 ? "auto" : "visible")};

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #029199;
    border-radius: 2px;
  }
`;

const ColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  
  @media (max-width: 880px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`;
const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0 16px 8px;
  border: 1px solid #e2e8f0;
  margin-bottom: 8px;
  border-radius: 4px;
`;

const Column = styled.div`
  padding: 0 16px 0 0;
  
  @media (max-width: 880px) {
    padding: 0 8px;
     &:last-child ${HeaderRow} {
      display: none;
    }
  }
`;



const HeaderText = styled.div`
  color: #64748b;
  font-size: 14px;
  font-weight: 500;
`;

const HeaderScore = styled(HeaderText)`
  padding-right: 16px;
`;

const ResultCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px 12px 8px;
  border-radius: 4px;
  margin-bottom: 4px;
  border: 1px solid #e2e8f0;
  &:hover {
    background: #f8fafc;
  }
`;

const RankAndUser = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Rank = styled.div`
  color: #64748b;
  font-weight: 500;
  min-width: 25px;
`;

const Avatar = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #e2e8f0;
`;

const Name = styled.div`
  color: #334155;
  font-weight: 400;
`;

const Score = styled.div`
  color: #334155;
  font-weight: 400;
  padding-right: 16px;
`;

export default ResultsSection;
