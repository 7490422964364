import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useHighlight } from "./useHighlight";
import { deleteHighlightPostAnswer } from "../../services/apiHighlight";
import { useTranslation } from "react-i18next";
export const useDeleteHighlightPostAnswer = ({community_id, highlight_id, post_id,post_tab,label}) => {
    const {handleError, handleSuccess} = useHighlight();
    const queryClient = useQueryClient();
    const {t} = useTranslation()
    return useMutation({
        mutationFn: ({community_id, highlight_id, post_id,question_id, answer_id}) => deleteHighlightPostAnswer({community_id, highlight_id, post_id,question_id, answer_id}),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['highlight-posts',community_id,highlight_id,post_tab]});
            queryClient.invalidateQueries({queryKey: ['highlight-post',community_id,highlight_id,post_id]});
            handleSuccess(t("highlight_post_answer_deleted_successfully"));
        },
        onError: (error) => handleError(error),
    })
}
