import React from "react";
import { Group } from "@mantine/core";
import { Download } from "tabler-icons-react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CustomButton from "../Common/CustomButton";

const ProgramButtons = ({
  user,
  programStatus,
  programId,
  syllabusFile,
  xs,
  buttonWidth,
  buttonHeight,
  type,
  entryData,
  t,
}) => {

  if(!user?.id) {
    return (
      <CustomButton
      component={Link}
      to={`/login`}
      xs={xs}
      width="100%"
      height={buttonHeight}
      type={type}
      mt={12}
      
    >
      {t("apply")}
    </CustomButton>
    )
  }
  // Original STEAM buttons
  return (
    <ButtonGroup>
      {programStatus === "Fail" ? (
        <CustomButton
          component={Link}
          to={`/${type}/${entryData?.slug}-${entryData?.id}/payment`}
          xs={xs}
          width="100%"
          height={buttonHeight}
          type={type}
        >
          {t("apply")}
        </CustomButton>
        ) : programStatus === "Success" &&  (
        <>
          <CustomButton
            type={type}
            component={Link}
            to={`/${type}/${entryData?.slug}-${entryData?.id}/overview`}
            xs={xs}
            width={buttonWidth}
            height={buttonHeight}
          >
            {t("enter")}
          </CustomButton>
          <CustomButton
            type={type}
            variant="outline"
            leftIcon={<Download />}
            component="a"
            href={syllabusFile}
            target="_blank"
            download
            xs={xs}
            width={buttonWidth}
            height={buttonHeight}
          >
            {t("downloadSyllabus")}
          </CustomButton>
        </>
      )}
    </ButtonGroup>
  );
};

export default ProgramButtons;

// ... ButtonGroup styled component ...
const ButtonGroup = styled(Group)`
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    button {
      width: 100%;
    }
  }
`;