import { useMutation, useQueryClient } from "@tanstack/react-query";
import { answeredToHighlightPost } from "../../services/apiHighlight";
import { useHighlight } from "./useHighlight";

export const useAnswerToHighlightPost = (communityId,highlight_id,type,postTab,label,postId) => {
    const {handleError} = useHighlight();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({post_id,question_id,answer_id}) => answeredToHighlightPost({community_id:communityId,highlight_id,post_id,question_id,answer_id}),
        onSuccess: () => {
            if (type === "sharedPost") {
                queryClient.invalidateQueries({queryKey: ['highlight-post',communityId,highlight_id,postId]});
            } else if (type === "savedPosts") {
                queryClient.invalidateQueries({queryKey: ['savedPosts',communityId,postTab]});
            } else {
                queryClient.invalidateQueries({queryKey: ['highlight-posts',communityId,highlight_id,postTab]});
            }
        },
        onError: (error) => {
            handleError(error);
        },
    })
}
