import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useHighlight } from "./useHighlight";
import { updateHighlight } from "../../services/apiHighlight";
import { useTranslation } from "react-i18next";

export const useUpdateHighlight = (communityId, highlightId,onClose) => {
    const {handleError , handleSuccess} = useHighlight();
    const queryClient = useQueryClient();
    const {t} = useTranslation()
    return useMutation({
        mutationFn: ({community_id,highlight_id,data}) => updateHighlight({community_id,highlight_id,data}  ),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['highlight',communityId,highlightId]});
            handleSuccess(t("highlight_updated_successfully"));
            onClose();
        },
        onError: (error) => {
            handleError(error);
        }
    })
}
