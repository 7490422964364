import React, { useContext } from "react";
import { Card, Text, Button, Group } from "@mantine/core";
import member from "../../../Assets/SVGs/community/member.svg";
import { useMediaQuery } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../Helpers/Context";
import isPaid from "../../../Assets/SVGs/community/paid.svg";
import { useTranslation } from "react-i18next";

const CommunityCard = ({ data }) => {
  const {t} = useTranslation()
  const { user } = useContext(AppContext);
  const is_owner = user?.id === data?.author?.id;
  const navigate = useNavigate();
  const handleJoinCommunity = () => {
    if (is_owner) {
      navigate(`/community/${data?.slug}-${data?.id}/owner`);
    } else if (data?.is_joined) {
      navigate(`/community/${data?.slug}-${data?.id}`);
    } else  {
      navigate(`/community/${data?.slug}-${data?.id}/join`);
    }
  };
  const xs = useMediaQuery("(max-width: 576px)");
  return (
    <Card
      radius="21px"
      withBorder
      sx={{
        backgroundColor: "white",
      }}
    >
      <Card.Section>
        <img
          src={data.cover_photo_url}
          alt={`img-${data.cover_photo}`}
          style={{
            width: "100%",
            height: xs ? "80px" : "86px",
            objectFit: "cover",
            borderTopLeftRadius: "21px",
            borderTopRightRadius: "21px",
          }}
        />
      </Card.Section>

      <div style={{ position: "relative", padding: xs ? "8px 0px 0px 0px" : "9px 0px 0px 0px" }}>
          <Text 
            weight={700} 
            size={xs ? 12 : 14} 
            color="#1A5059"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%'
            }}
          >
            {data.name}
          </Text>
          {!data?.is_free && <img width={xs ? 15 : 23} height={xs ? 15 : 23}  style={{ position: "absolute", top: xs ? 5 :10, right: xs ? -5 :  0 }} src={isPaid} alt="isPaid" />}

        {/* <Text size={xs ? 11 : 13} color="#575757" mt={xs ? 0 : 4}>
          {data.description.length > (xs ? 19 : 25)
            ? `${data.description.slice(0, xs ? 19 : 25)}...`
            : data.description}
        </Text> */}

        <Group spacing={6} mt={xs ? 4 : 6}>
          <Text
            size={xs ? 11 : 13}
            color="#A3A3A3"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <img style={{ marginRight: "4px" }} src={member} alt="member" />{data?.members}{(" ")}
            {t("member-count")}
          </Text>
        </Group>

        <Button
          fullWidth
          mt={xs ? 7 : 10}
          radius={xs ? 7 : 12}
          onClick={handleJoinCommunity}
          disabled={!data?.is_active}
          sx={{
            backgroundColor: "#029199",
            color: "white",
            fontSize: xs ? 10 : 12,
            height: xs ? 25 : 35,
            "&:hover": {
              backgroundColor: "#027a7a",
            },
          }}
        >
          {is_owner || data?.is_joined ? t("enter") : t("join")}
        </Button>
      </div>
    </Card>
  );
};

export default CommunityCard;
