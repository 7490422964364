import React, { useContext, useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { Button, Group, Modal, Radio, Stack, Paper, Box, Title, Text, Divider, SimpleGrid, ThemeIcon } from "@mantine/core";
import styled from "styled-components";
import { InfoCircle, Check, School, Certificate, Discount } from "tabler-icons-react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import { useDisclosure } from "@mantine/hooks";
import InfoDropdown from "../../Components/StudyAbroad/SingleUniversity.jsx/Dropdowon";
import Banner from "../../Components/StudyAbroad/Banner";
import TabMenu from "../../Components/StudyAbroad/TabMenu";
import { useTranslation } from "react-i18next";
import subsImage from "../../Assets/SVGs/university/subs.png";
import LottieLoading from "../../Components/Loaders/LottieLoading";
import { AppContext } from "../../Helpers/Context";
const SingleUniversity = () => {
  const { t } = useTranslation();
  const { uId, pId } = useParams();
  const {user} = useContext(AppContext)
  const [selectedSpecialityId, setSelectedSpecialityId] = useState();
  const [opened, { open, close }] = useDisclosure(false);
  const [applyType, setApplyType] = useState("me");
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [activeSubsPackage, setActiveSubsPackage] = useState(false);

  const { data: university, isLoading: universityLoading } = useQuery({
    queryKey: ["university", uId],
    queryFn: async () => {
      const { data } = await axios.get(`/university/byid/${uId}/`);
      return data.University[0];
    },
  });

  const { data: specialities } = useQuery({
    queryKey: ["specialities"],
    queryFn: async () => {
      const { data } = await axios.get(`/api/v1/speciality/`);
      return data;
    },
  });

  const { data: profession, isLoading: professionLoading } = useQuery({
    queryKey: ["professions", pId],
    queryFn: async () => {
      const { data } = await axios.get(`/university/professionbyid/${pId}/`);
      return data;
    },
    enabled: !!pId,
  });

  const { data: professions } = useQuery({
    queryKey: ["professions", selectedSpecialityId],
    queryFn: async () => {
      const { data } = await axios.get(
        `/university/professionforuniversity/?speciality_id=${selectedSpecialityId}&university_id=${uId}`
      );
      return data;
    },
    enabled: !!selectedSpecialityId,
  });

  const { data: plans } = useQuery({
    queryKey: ["plans"],
    queryFn: async () =>
    await axios.get(`/subscribe/foruser/`).then((res) => res.data),
    enabled: !!user
  });

  useEffect(() => {
    if (plans && plans?.length > 0) {
      const filteredActiveSubsPck = plans.some(
        (plan) => (plan.is_expired === false && plan.package.title === "3")
      );
      setActiveSubsPackage(filteredActiveSubsPck);
    }
  }, [plans]);
  if (universityLoading || professionLoading) {
    return <LottieLoading />;
  }

  const getPageTitle = () => {
    if (university && profession) {
      return t('single_university_program_title', { 
        universityName: university.title, 
        programName: profession.profession 
      });
    } else if (university) {
      return t('single_university_title', { universityName: university.title });
    }
    return t('university_page_title');
  };

  const getPageDescription = () => {
    if (university && profession) {
      return t('single_university_program_description', { 
        universityName: university.title, 
        programName: profession.profession,
        country: university.country
      });
    } else if (university) {
      return t('single_university_description', { 
        universityName: university.title,
        country: university.country
      });
    }
    return t('university_page_description');
  };

  return (
    <>
      <Helmet>
        <title>{getPageTitle()}</title>
        <meta name="description" content={getPageDescription()} />
      </Helmet>

      <Banner props={{ type: "single", university, open, t }} />
      <Container>
        <TabMenu
          props={{
            t,
            type: "university",
            university,
            specialities,
            professions,
            profession,
            selectedSpecialityId,
            setSelectedSpecialityId,
          }}
        />
        <Modal
          opened={opened}
          onClose={close}
          title={
            activeSubsPackage 
              ? t("application-form")
              : t("subscribe-to-apply")
          }
          centered
          className="apply-modal"
        >
          {activeSubsPackage ? (
            <>
              <Radio.Group
                value={applyType}
                onChange={setApplyType}
                name="chooseApplyType"
                orientation="vertical"
                spacing="md"
              >
                <Group noWrap align="center" style={{ position: "relative" }} spacing={3}>
                  <Radio color="green" value="me" label={t("apply-by-yourself")} />
                  <InfoCircle
                    style={{ cursor: "pointer" }}
                    color="#fff"
                    fill="#FFA523"
                    onMouseEnter={() => setHoveredIcon("me")}
                    onMouseLeave={() => setHoveredIcon(null)}
                  />
                  {hoveredIcon === "me" && (
                    <InfoDropdown
                      top={-125}
                      left={0}
                      text={t('uni-info')}
                    />
                  )}
                </Group>
                <Group noWrap align="center" style={{ position: "relative" }} spacing={3}>
                  <Radio
                    color="green"
                    value="agent"
                    label={t("apply-by-agent")}
                  />
                  <InfoCircle
                    style={{ cursor: "pointer" }}
                    color="#fff"
                    fill="#FFA523"
                    onMouseEnter={() => setHoveredIcon("agent")}
                    onMouseLeave={() => setHoveredIcon(null)}
                  />
                  {hoveredIcon === "agent" && (
                    <InfoDropdown
                      top={-125}
                      left={0}
                      text={t('agent-info')}
                    />
                  )}
                </Group>
              </Radio.Group>

              <Group mt={40} mb={20} position="center" align="center">
                <ButtonStyle
                  size="lg"
                  radius={29}
                  border = "1px solid #CDD5DF"
                  back = "#fff"
                  onClick={close}
                >
                  {t("cancel")}
                </ButtonStyle>
                <ButtonStyle
                  size="lg"
                  radius={29}
                  back="#ccf075"
                  component={Link}
                  to={
                    applyType === "me"
                      ? `/universities/apply-form/programs/${pId}`
                      : "/agents"
                  }
                >
                  {t("apply")}
                </ButtonStyle>
              </Group>
            </>
          ) : (
            <Paper shadow="sm" radius="md" p="xl" withBorder>
              <Stack spacing="xl">
                <Box>
                  <Title order={3} align="center" mb="xs">
                    {t("subscription_required_title")}
                  </Title>
                  <Text color="dimmed" size="sm" align="center">
                    {t("subscription_required_message")}
                  </Text>
                </Box>

                <Divider />

                <SimpleGrid cols={1} spacing="md">
                  <Paper p="md" radius="md" withBorder>
                    <Group noWrap>
                      <ThemeIcon size={40} radius="md" color="blue" variant="light">
                        <School size={24} />
                      </ThemeIcon>
                      <div>
                        <Text weight={500}>{t("sub_benefit_1")}</Text>
                        <Text size="sm" color="dimmed">{t("sub_benefit_1_desc")}</Text>
                      </div>
                    </Group>
                  </Paper>

                  <Paper p="md" radius="md" withBorder>
                    <Group noWrap>
                      <ThemeIcon size={40} radius="md" color="green" variant="light">
                        <Certificate size={24} />
                      </ThemeIcon>
                      <div>
                        <Text weight={500}>{t("sub_benefit_2")}</Text>
                        <Text size="sm" color="dimmed">{t("sub_benefit_2_desc")}</Text>
                      </div>
                    </Group>
                  </Paper>

                  <Paper p="md" radius="md" withBorder>
                    <Group noWrap>
                      <ThemeIcon size={40} radius="md" color="orange" variant="light">
                        <Discount size={24} />
                      </ThemeIcon>
                      <div>
                        <Text weight={500}>{t("sub_benefit_3")}</Text>
                        <Text size="sm" color="dimmed">{t("sub_benefit_3_desc")}</Text>
                      </div>
                    </Group>
                  </Paper>
                </SimpleGrid>

                <Button
                  size="lg"
                  component={Link}
                  to="/subscribtion"
                  leftIcon={<Check size={20} />}
                  styles={(theme) => ({
                    root: {
                      backgroundColor: "#fe6027",
                      borderRadius: "md",
                      '&:hover': {
                        backgroundColor: "#fe6027",
                        opacity: 0.8,
                      },
                    },
                  })}
                >
                  {t("view_subscription_plans")}
                </Button>
              </Stack>
            </Paper>
          )}
        </Modal>
      </Container>
    </>
  );
};

export default SingleUniversity;

const Container = styled.div`
  max-width: 92%;
  margin: 40px auto 80px auto;
  min-height: 50vh;
  .mantine-Popover-dropdown.mantine-oo5anx {
    left: 210px !important;
  }

  .mantine-Paper-root.mantine-Modal-modal.mantine-rqmd87 {
    padding : 0 !important;
  }
  
`;

const ButtonStyle = styled(Button)`
  width: 35%;
  border : ${props => props.border};
  background-color : ${props => props.back};
  font-size : 16px;
  font-weight :500;
  color : #323922;
  &:hover {
    background-color : ${props => props.back};
  }
  @media (max-width: 576px) {
    width: 100%;
   
  }
`;
