import React from "react";
import { Button, FileInput } from "@mantine/core";
import { Eye } from "tabler-icons-react";

const PreviewButton = ({t, to }) => {
  return (
    <a
    style={{ textDecoration: "none", color: "#1F2A37" }}
    href={to}
    target="_blank"
    rel="noreferrer"
  >
    <FileInput
      size="md"
      className="preview-btn"
      
      placeholder={t('pdf-file')}
      style={{
        background : "#F5F5F5",
        color: "#697586",
        fontWeight : 400,
        border : "1px solid #CDD5DF",
        borderRadius : "10px"
      }}
      rightSection={
        <Button
          rightIcon={<Eye strokeWidth={1.5} color="#1F2A37" />}
          style={{
            border: "1px solid #CDD5DF",
            background: "#F8FAFC",
            color: "#1F2A37",
            fontSize: "16px",
            fontWeight: 500,
            position: "absolute",
            right: "5px",
            zIndex: "8",
          }}
          className="download"
          radius={10}
        >

            {t('preview')}
        </Button>
      }
   />
   </a>
  );
};

export default PreviewButton;


