import { useInfiniteQuery } from "@tanstack/react-query";
import { useHighlight } from "./useHighlight";
import { getAllHighlightPost } from "../../services/apiHighlight";

export const useGetAllHighlightPosts = (communityId, highlightId, type,postTab,label) => {
    
    return useInfiniteQuery({
        queryKey: ['highlight-posts',communityId,highlightId,postTab,label],
        queryFn: ({ pageParam = 1 }) => getAllHighlightPost({
            community_id: communityId, 
            highlight_id: highlightId,
            page: pageParam,
            post_tab: postTab,
            label: label
        }),
        getNextPageParam: (lastPage) => {
            if (lastPage.next) {
                const nextPage = new URLSearchParams(new URL(lastPage.next).search).get('page');
                return nextPage;
            }
            return undefined;
        },
        enabled: !!communityId && !!highlightId && type === "highlight",
    });
};
