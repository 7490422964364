import React from 'react'
import { HighlightCardStyled } from '../../style'
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { az } from 'date-fns/locale';

const formatDate = (dateString) => {
  if (!dateString) return 'Tarix mövcud deyil';
  
  try {
    const date = parseISO(dateString);
    if (isNaN(date.getTime())) {
      return 'Etibarsız tarix';
    }
    return format(date, 'd MMMM yyyy', { locale: az });
  } catch (error) {
    console.error('Tarix formatında xəta:', error);
    return 'Etibarsız tarix';
  }
};

const HighlightCard = ({ highlight }) => {
  const { t } = useTranslation();
    const {image, name, posts, date, communityId, id, slug} = highlight
  return (
    <HighlightCardStyled to={`/community/${communityId}/highlight/${slug}-${id}/join`}>
      <div className="card-content">
        <div className="image-container">
          <img 
            src={`https://taskilled.blob.core.windows.net/taskool-store/${image}`} 
            alt={name}
          />
        </div>

        <div className="text-content">
          <h3>{name}</h3>
          
          <div className="meta-info">
            <p>{posts} {t("post-count")}</p>
          </div>
        </div>
      </div>
    </HighlightCardStyled>
  )
}

export default HighlightCard