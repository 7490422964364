import { useMutation, useQueryClient } from "@tanstack/react-query";
import { solveTask } from "../../services/apiTask";

export const useSolveTask = (slug, olympiadId, taskId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => solveTask(slug, olympiadId, taskId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(["solveTask", slug, olympiadId, taskId]);
    },
  });
};