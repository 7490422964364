import React, { useState } from "react";
import { CommunityLayout } from "./CommunityPanel";
import styled from "styled-components";
import PostList from "../../features/Community/components/community-panel/post-panel/PostList";
import { useParams } from "react-router-dom";
import ModalPostLayout from "../../features/Community/components/modal/ModalPostLayout";
const SharedPost = () => {
  const { id, highlightId, postId } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [label, setLabel] = useState(null);

  return (
    <CommunityLayout>
      <SavedPostContainer>
        <PostList
          setActiveTab={setActiveTab}
          setOpenModal={setOpenModal}
          openModal={openModal}
          postId={postId}
          type="sharedPost"
          postTab={"posts"}
          setLabel={setLabel}
          label={label}
        />
        <ModalPostLayout
          type={"shared"}
          highlightId={highlightId}
          communityId={id}
          opened={openModal}
          onClose={() => setOpenModal(false)}
          active={activeTab}
          postId={postId}
          postTab={"posts"}
          label={label}
        />
      </SavedPostContainer>
    </CommunityLayout>
  );
};

export default SharedPost;

const SavedPostContainer = styled.div`
  padding: 20px;
  background: white;
  border-radius: 8px;
  @media (max-width: 768px) {
    padding: 5px;
  }
`;
