export const getSlugAndIdFromPath = (pathname) => {
    try {
        const pattern = /([^/]+)-(\d+)(?=\/|$)/;
        const match = pathname.match(pattern);
        
        if (!match) {
            throw new Error('Invalid path format');
        }
        
        return {
            slug: match[1],
            id: match[2]
        };
    } catch (error) {
        // console.error('Error parsing path:', error);
        return {
            slug: '',
            id: ''
        };
    }
};