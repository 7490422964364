import { React } from "react";
import styled from "styled-components";
import { SecondPart } from "./Landing/SecondPart";
import { ThirdPart } from "./Landing/ThirdPart";
import { ForthPart } from "./Landing/ForthPart";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import About from "./Landing/About";

import Partner from "./Landing/Partner";
import { useMediaQuery } from "@mantine/hooks";



const Landing = () => {
  const { t } = useTranslation();

  const xs = useMediaQuery('(max-width: 768px)')

  return (
    <>
      <Helmet>
        <title>{t('landing_title', 'About | Taskool')}</title>
        <meta name="description" content={t('landing_description')} />
      </Helmet>
      <LandingStyle props={{ t }}>

        <div style={{ paddingTop: xs ? "50px" : "100px" }} id="about-section">
          <SecondPart props={{ t }} />
        </div>
        <div style={{ paddingTop: xs ? "50px" : "100px" }} id="mission-section">
          <ThirdPart props={{ t }} />
        </div>
        <div style={{ paddingTop: xs ? "50px" : "100px" }} id="our-history" >
          <About props={{ t }} />
        </div>
        <div style={{ paddingTop: xs ? "50px" : "100px" }} id="partner-section">
          <Partner props={{ t }} />
        </div>
        <div style={{ paddingTop: xs ? "50px" : "100px" }} id="faq-section">
          <ForthPart props={{ t }} />
        </div>
      </LandingStyle>
    </>
  );
};

export default Landing;

const LandingStyle = styled.div`
  overflow: hidden;
`;
