import React from "react";
import PostLayout from "./PostLayout";
import { Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import DOMPurify from 'dompurify';
import styled from 'styled-components';

export const renderText = (text) => {
  // First check if the text is already HTML (contains tags)
  if (text.includes('<a') || text.includes('</a>')) {
    // If it's already HTML, just sanitize and return
    const cleanHtml = DOMPurify.sanitize(text, {
      ALLOWED_TAGS: ['strong', 'p', 'br', 'div', 'span', 'a'],
      ALLOWED_ATTR: ['href', 'target', 'rel', 'style', 'class']
    });
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: cleanHtml
        }}
      />
    );
  }

  // If it's plain text, proceed with the normal link and hashtag processing
  const textWithLinks = text.replace(
    /(https?:\/\/[^\s]+)/g,
    '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
  );
  
  const textWithHashtagsAndLinks = textWithLinks.replace(
    /#(\w+)/g, 
    '<strong>#$1</strong>'
  );
  
  const cleanHtml = DOMPurify.sanitize(textWithHashtagsAndLinks, {
    ALLOWED_TAGS: ['strong', 'p', 'br', 'div', 'span', 'a'],
    ALLOWED_ATTR: ['href', 'target', 'rel', 'style', 'class']
  });
  
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: cleanHtml
      }}
    />
  );
};

export const StyledText = styled(Text)`
  word-wrap: break-word;
  overflow-wrap: break-word;
  width: 100%;

  a {
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-all;
    max-width: 100%;
    display: inline-block;
  }

  div {
    max-width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
`;

const TextPost = ({ post }) => {
  const xs = useMediaQuery('(max-width: 768px)');
  


  return (
    <StyledText size={xs ? 13 : 16} weight={400} color="202020" lh={xs ? "20px" : "28px"}>
      {renderText(post.text)}
    </StyledText>
  );
};

export default TextPost;
