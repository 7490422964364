import React, { useState } from "react";
import { Modal, Select, Grid, Box } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";
import { Button } from "@mantine/core";
import { useGetAllCompetitionType } from "../hooks/useGetAllCompetitionType";
import { useTranslation } from 'react-i18next';

const FilterModal = ({ opened, onClose, onSearch }) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    is_hackathon: "",
    level_id: "",
    type_id: "",
    is_free: "",
  });

  const handleFilterChange = (field, value) => {
    setFilters({ ...filters, [field]: value });
  };

  const { data: competitionType } = useGetAllCompetitionType();

  const handleSearch = () => {
    const searchParams = {
      ...filters,
    };

    // Remove undefined, null, empty string values, and "null" string
    Object.keys(searchParams).forEach((key) => {
      if (
        searchParams[key] === undefined ||
        searchParams[key] === null ||
        searchParams[key] === "" ||
        searchParams[key] === "null"
      ) {
        delete searchParams[key];
      }
    });

    onSearch(searchParams);
  };

  const selectStyles = {
    label: {
      marginBottom: "4px",
      color: "#364152",
      fontSize: "15px",
      fontWeight: "400",
    },
    input: {
      "&::placeholder": {
        fontSize: "14px",
        color: "#697586",
      },
    },
  };

  //   const { data: programs, isLoading } = useQuery({
  //     queryKey: ["programs",type],
  //     queryFn: async () => {
  //       const res = await axios.get(`/${type === "steam" ? "steam/steam" : "bootcamp"}/own_${type}s`);
  //       return res.data;
  //     },
  //   });

  return (
    <Modal opened={opened} onClose={onClose} title={t('filterModal.title')} size="xl">
      <Grid style={{ borderTop: "1px solid #e1e1e1" }} gutter="md">
        <Grid.Col span={12} md={6}>
          <Select
            label={t('filterModal.olympiadHackathon')}
            placeholder={t('filterModal.select')}
            data={[
              { value: false, label: t('filterModal.olympiad') },
              { value: true, label: t('filterModal.hackathon') },
            ]}
            value={filters.is_hackathon}
            onChange={(value) => handleFilterChange("is_hackathon", value)}
            size="md"
            radius={10}
            styles={() => selectStyles}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <Select
            label={t('filterModal.hybridOnlineOffline')}
            placeholder={t('filterModal.select')}
            data={[
              { value: "1", label: t('filterModal.offline') },
              { value: "2", label: t('filterModal.hybridOtherPlatform') },
              { value: "3", label: t('filterModal.hybridOurPlatform') },
              { value: "4", label: t('filterModal.onlineOtherPlatform') },
              { value: "5", label: t('filterModal.onlineOurPlatform') }
            ]}
            value={filters.type_id}
            onChange={(value) => handleFilterChange("type_id", value)}
            size="md"
            radius={10}
            styles={() => selectStyles}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <Select
            label={t('filterModal.level')}
            placeholder={t('filterModal.selectLevel')}
            data={[
              { value: "0", label: t('filterModal.easy') },
              { value: "1", label: t('filterModal.medium') },
              { value: "2", label: t('filterModal.hard') },
            ]}
            value={filters.level_id}
            onChange={(value) => handleFilterChange("level_id", value)}
            size="md"
            radius={10}
            styles={() => selectStyles}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <Select
            label={t('filterModal.freePurchased')}
            placeholder={t('filterModal.select')}
            data={[
              { value: false, label: t('filterModal.free') },
              { value: true, label: t('filterModal.purchased') },
            ]}
            value={filters.is_free}
            onChange={(value) => handleFilterChange("is_free", value)}
            size="md"
            radius={10}
            styles={() => selectStyles}
          />
        </Grid.Col>
      </Grid>
      <Box
        mt="md"
        style={{
          paddingTop: "14px",
          borderTop: "0.85px solid #E1E1E1",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          styles={{
            root: {
              border: "1px solid #CDD5DF",
              color: "#323922",
              width: "49%",
              borderRadius: "10px",
              backgroundColor: "transparent",
              height: "47px",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#323922",
                opacity: "0.9",
              },
            },
          }}
          onClick={() => {
            setFilters({
              type: "",
              status: "",
              level: "",
              is_free: "",
            });
          }}
        >
          {t('filterModal.reset')}
        </Button>
        <Button
          styles={{
            root: {
              width: "49%",
              backgroundColor: "#364152",
              color: "#fff",
              borderRadius: "10px",
              height: "47px",
              "&:hover": {
                backgroundColor: "#364152",
                color: "#fff",
                opacity: "0.9",
              },
            },
          }}
          onClick={handleSearch}
        >
          {t('filterModal.search')}
        </Button>
      </Box>
    </Modal>
  );
};

export default FilterModal;