import { useMutation, useQueryClient } from "@tanstack/react-query";
import { pinPost } from "../../services/apiPost";
import { usePost } from "./usePost";
import { useHighlight } from "../highlight/useHighlight";
import { useTranslation } from "react-i18next";
export const usePinPost = ({community_id,highlightId,post_id,post_tab,label}) => {
    const {invalidatePostQueries,handleSuccess,handleError} = usePost();
    const queryClient = useQueryClient();
    const {invalidateHighlightQueries} = useHighlight();
    const {t} = useTranslation()
    return useMutation({
        mutationFn: ({community_id,post_id}) => pinPost({community_id,post_id}),
        onSuccess: () => {
            if(highlightId){
                invalidateHighlightQueries(community_id,highlightId,post_id,"allPost",post_tab,label);
            }else{
                queryClient.invalidateQueries({queryKey: ['all',community_id,post_tab]});
            }
            handleSuccess(t("post_pinned_successfully"));
        },
        onError: (error) => handleError(error),
    });

};
