
import axios from "../../../Helpers/Axios";

export const getBadgesList = async () => {
    const response = await axios.get("/community/badges/");
    return response.data;
  };

  export const createAwardBadge = async (id,data) => {
    const response = await axios.post(`/community/${id}/badges`,data);
    return response.data;
  };

  export const updateAwardBadge = async (id,data) => {
    const response = await axios.put(`/community/${id}/badges?badge_id=${data.id}`,data);
    return response.data;
  };

  export const getAwardBadges = async (id) => {
    const response = await axios.get(`/community/${id}/badges`);
    return response.data;
  };

  export const getCollaboratorsByBadgeId = async (id,badgeId) => {
    const response = await axios.get(`/community/${id}/colobrator-list/${badgeId}`);
    return response.data;
  };

  export const getCollaboratorsByCommuntyId = async (id) => {
    const response = await axios.get(`/community/${id}/colobrator-list`);
    return response.data;
  };

