import { useInfiniteQuery } from "@tanstack/react-query";
import { getOlympiad } from "../services/apiCompetition";

export const useGetAllCompetition = (filters = {}, searchTerm = "") => {
  return useInfiniteQuery({
    queryKey: ["competition", filters, searchTerm],
    queryFn: ({ pageParam }) => getOlympiad({ 
      pageParam, 
      filters: {
        ...filters,
        ...(searchTerm && { name: searchTerm })
      } 
    }),
    getNextPageParam: (lastPage) => {
      if (lastPage.next) {
        // URL'den sayfa numarasını çıkar
        const nextPage = new URL(lastPage.next).searchParams.get("page");
        return nextPage;
      }
      return undefined;
    },
    initialPageParam: 1,
  });
};