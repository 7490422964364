import { Box, Button, Group, Text, Avatar, Stack } from "@mantine/core";
import styled from "styled-components";
import vector from "../../../Assets/SVGs/competition/level-vector.svg";
import currencyIcon from "../../../Assets/SVGs/competition/currency-icon.svg";
import { IconCalendar } from "@tabler/icons-react";
import { formatDate } from '../utils/dateFormatter';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const CompetitionCard = ({
  id,
  title,
  level,
  date,
  skills,
  type,
  isFree,
  isDone,
  company,
  slug
}) => {
  const { t } = useTranslation();
  const isOlympiad = type === "olympic";
  const mainColor = isOlympiad ? "#029199" : "#FE6027";
  const levelColor =
    level === "Easy" ? "#00BD90" : level === "Medium" ? "#F3CF02" : "#FE6513";

  // Tarihleri formatla
  const formattedStartDate = date?.start_date ? formatDate(date.start_date) : '';
  const formattedEndDate = date?.end_date ? formatDate(date.end_date) : '';

  // Level tərcüməsi
  const getTranslatedLevel = (level) => {
    switch(level?.toLowerCase()) {
      case 'easy':
        return t('competitionCard.easy');
      case 'medium':
        return t('competitionCard.medium');
      case 'hard':
        return t('competitionCard.hard');
      default:
        return level;
    }
  };

  
  return (
    <CardWrapper $isDone={isDone} $mainColor={mainColor} $levelColor={levelColor}>
      <Box className="card-content">

        <Box className="card-body">
          <Box>
            <Group position="apart" mb={0} align="baseline" noWrap>
              <Text size={18} weight={600} color="#202224" style={{ wordBreak: 'break-word' }}>
                {title}
              </Text>
              <Box style={{ flexShrink: 0 }}>
                {!isFree && !isDone && (
                  <img src={currencyIcon} alt="paid" width={20} height={20} />
                )}
                {isFree && !isDone && (
                  <Text size={14} weight={500} color={mainColor}>
                    {t('competitionCard.free')}
                  </Text>
                )}
                {isDone && (
                  <Text size={14} weight={500} color="#4E4E4E">
                    {t('competitionCard.timeUp')}
                  </Text>
                )}
              </Box>
            </Group>

            <Group mt={10} position="apart" mb={15}>
              <Group style={{ width: "100%" }} position="apart">
                <Stack spacing={8}>
                  <Text size={15} weight={400} color="#575757">
                    {isOlympiad ? t('competitionCard.olympiad') : t('competitionCard.hackathon')}
                  </Text>
                  <Box className="level-badge">
                    <div className="diamond"></div>
                    <Text size={14} weight={500} color="#9B9B9B">
                      {t('competitionCard.level')} - {getTranslatedLevel(level)}
                    </Text>
                  </Box>
                </Stack>
                {!isOlympiad && (
                  <Stack spacing={1}>
                    <Group spacing={5}>
                      <IconCalendar size={13} color="#a3a3a3" stroke={1.5} />
                      <Text size={14} weight={400} color="#a3a3a3">
                        {formattedStartDate}
                      </Text>
                    </Group>
                    <Group spacing={5}>
                      <IconCalendar size={13} color="#a3a3a3" stroke={1.5} />
                      <Text size={14} weight={400} color="#a3a3a3">
                        {formattedEndDate}
                      </Text>
                    </Group>
                  </Stack>
                )}
              </Group>
            </Group>

            <Group spacing={4} mb={15}>
              {skills.map((skill, index) => (
                <Tag key={index} $mainColor={mainColor}>
                  {skill?.skill}
                </Tag>
              ))}
            </Group>
          </Box>
          <Box className="card-footer">
            <Group position="apart" mb={15} mt={15}>
              <Group align="center" spacing={4}>
                <Avatar src={company?.profile_image} size={35} radius="xl" />
                <Text>{company?.first_name}</Text>
              </Group>
              {isOlympiad && (
                <Group align="center" spacing={4}>
                  <IconCalendar size={13} color="#a3a3a3" stroke={1.5} />
                  <Text size={14} weight={400} color="#a3a3a3">
                    {formattedStartDate}
                  </Text>
                </Group>
              )}
            </Group>
            <Button component={Link} to={`/competitions/${slug}-${id}`} fullWidth className="view-button">
              {t('competitionCard.view')}
            </Button>
          </Box>
        </Box>
      </Box>
    </CardWrapper>
  );
};

export default CompetitionCard;

const CardWrapper = styled.div`
  height: 100%;

  .card-content {
    background: ${(props) => props.$isDone ? "#ececec" : "white"};
    padding: 20px;
    border-radius: 12px;
    box-shadow: ${(props) => props.$isDone ? "-3px 0px 20px 0px #0000000D" : "0px 3.65px 13.68px 0px #0000001a"};
    height: 90%;
    border: ${(props) => props.$isDone ? "1px solid #E4E4E4" : "none"};
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .level-badge {
    display: flex;
    align-items: center;
    gap: 8px;

    .diamond {
      width: 10px;
      height: 14px;
      background: ${(props) => props.$levelColor};
      clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
      border-radius: 4px;
    }
  }

  .students-section {
    margin-bottom: 20px;
  }

  .view-button {
    background-color: ${(props) => props.$isDone ? "#4E4E4E" : props.$mainColor};
    height: 40px;
    border-radius: 12px;
    transition: all 0.3s ease;
    font-size: 14px;
    font-weight: 500;

    &:hover {
      background-color: ${(props) => props.$isDone ? "#4E4E4E" : props.$mainColor};
      opacity: 0.9;
    }
  }
`;

const Tag = styled.div`
  padding: 6px 12px;
  background: ${(props) => `${props.$mainColor}10`};
  color: ${(props) => props.$mainColor};
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid ${(props) => props.$mainColor};
`;

const AvatarGroup = styled.div`
  display: flex;
  align-items: center;

  .mantine-Avatar-root {
    width: 32px;
    height: 36px;
    border-radius: 50%;
    margin-right: -8px;

    &:last-child {
      background-color: #f1fafa;
      color: #029199;
      font-size: 12px;
      font-weight: 500;
    }
  }
`;