import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import Banner from "../../Components/StudyAbroad/Banner";
import styled from "styled-components";
import UniversityList from "../../Components/StudyAbroad/Universities/index";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import LottieLoading from "../../Components/Loaders/LottieLoading";
import {
  Modal,
  Stack,
  Text,
  ThemeIcon,
  List,
  Button,
  Group,
  Paper,
  Box,
  Divider,
  SimpleGrid,
} from "@mantine/core";
import {
  InfoCircle,
  School,
  Check,
  Send,
  Search,
  ListCheck,
} from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";

const Universities = ({ props }) => {
  const { t } = useTranslation();
  const { applyTo } = props;
  const { aId } = useParams();

  // Fetch agent data if applyTo is "agents"
  const { data: agentData } = useQuery({
    queryKey: ["agent", aId],
    queryFn: async () => {
      if (applyTo === "agents") {
        const response = await axios.get(`/agent/${aId}/`);
        return response.data;
      }
      return null;
    },
    enabled: applyTo === "agents",
  });

  const [filterValues, setFilterValues] = useState({
    region: [],
    country: [],
    speciality: [],
    degree: [],
    price: 0,
    gs: "",
    search: "",
    page: 1,
    page_size: 5,
  });

  const queryValue = {};
  filterValues.region.length > 0 &&
    (queryValue.region = filterValues.region.join(","));
  filterValues.country.length > 0 &&
    (queryValue.country = filterValues.country.join(","));
  filterValues.speciality.length > 0 &&
    (queryValue.speciality = filterValues.speciality.join(","));
  filterValues.degree.length > 0 &&
    (queryValue.degree = filterValues.degree.join(","));
  filterValues.price > 0 && (queryValue.price = filterValues.price);
  filterValues.gs && (queryValue.is_scholarship = filterValues.gs);
  filterValues.search && (queryValue.search = filterValues.search);
  filterValues.page > 0 && (queryValue.page = filterValues.page);
  filterValues.page_size > 0 && (queryValue.page_size = filterValues.page_size);

  const { data: universities, isLoading: universityLoading } = useQuery({
    queryKey: ["universities", queryValue],
    queryFn: async () =>
      (
        await axios.get(`/university/filteringforuniversity/`, {
          params: queryValue,
        })
      ).data,
  });
  const { data: cost, isLoading: costLoading } = useQuery({
    queryKey: ["costs"],
    queryFn: async () => {
      const res = await axios.get("university/university_professions/cost/");
      return res.data;
    },
  });

  const getPageTitle = () => {
    if (applyTo === "agents") {
      const agentName = agentData?.first_name || t("unknown_agent");
      return t("universities_agent_page_title", { agentName });
    }
    return t("universities_page_title");
  };

  const getPageDescription = () => {
    if (applyTo === "agents") {
      const agentName = agentData?.first_name || t("unknown_agent");
      return t("universities_agent_page_description", { agentName });
    }
    return t("universities_page_description");
  };

  const [showInfoModal, setShowInfoModal] = useState(true);

  if (costLoading) {
    return <LottieLoading />;
  }

  return (
    <UniversityContainer>
      <Helmet>
        <title>{getPageTitle()}</title>
        <meta name="description" content={getPageDescription()} />
      </Helmet>

      <Banner props={{ type: "all", t }} />
      <UniversityList
        t={t}
        applyTo={applyTo}
        agentId={aId}
        universities={universities}
        cost={cost}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        universityLoading={universityLoading}
      />

      {applyTo === "agents" && (
        <InfoModal
          showInfoModal={showInfoModal}
          setShowInfoModal={setShowInfoModal}
          t={t}
        />
      )}
    </UniversityContainer>
  );
};

const InfoModal = ({ showInfoModal, setShowInfoModal, t }) => {
  const sLg = useMediaQuery("(min-width: 1200px)");
  const sMd = useMediaQuery("(max-width: 992px)");
  const sSm = useMediaQuery("(max-width: 768px)");
  return (
    <Modal
      opened={showInfoModal}
      onClose={() => setShowInfoModal(false)}
      size={sSm ? "95%" : sMd ? "80%" : sLg ? "850px" : "850px"}
      radius="lg"
      padding="xl"
      centered
      styles={(theme) => ({
        header: {
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
          paddingBottom: theme.spacing.md,
        },
        title: { width: "100%" },
      })}
      title={
        <Box sx={{ width: "100%" }}>
          <Group position="center" mb="md">
            <ThemeIcon size={56} radius="xl" color="blue" variant="light">
              <School size={30} />
            </ThemeIcon>
          </Group>
          <Text align="center" size="xl" weight={700} mb={5}>
            {t("how_to_apply")}
          </Text>
          <Text align="center" color="dimmed" size="sm">
            {t("application_process_description")}
          </Text>
        </Box>
      }
    >
      <Stack spacing="xl" mt="xl">
        <SimpleGrid
          cols={sLg ? "3" : "1"}
          spacing="lg"
        >
          <Paper withBorder p="lg" radius="md" shadow="sm">
            <ThemeIcon
              size={44}
              radius="md"
              color="blue"
              variant="light"
              mb="md"
            >
              <Search size={24} />
            </ThemeIcon>
            <Text weight={700} size="lg" mb={5}>
              {t("step_1_title")}
            </Text>
            <Text size="sm" color="dimmed" sx={{ lineHeight: 1.6 }}>
              {t("step_1_desc")}
            </Text>
          </Paper>

          <Paper withBorder p="lg" radius="md" shadow="sm">
            <ThemeIcon
              size={44}
              radius="md"
              color="green"
              variant="light"
              mb="md"
            >
              <ListCheck size={24} />
            </ThemeIcon>
            <Text weight={700} size="lg" mb={5}>
              {t("step_2_title")}
            </Text>
            <Text size="sm" color="dimmed" sx={{ lineHeight: 1.6 }}>
              {t("step_2_desc")}
            </Text>
          </Paper>

          <Paper withBorder p="lg" radius="md" shadow="sm">
            <ThemeIcon
              size={44}
              radius="md"
              color="orange"
              variant="light"
              mb="md"
            >
              <Send size={24} />
            </ThemeIcon>
            <Text weight={700} size="lg" mb={5}>
              {t("step_3_title")}
            </Text>
            <Text size="sm" color="dimmed" sx={{ lineHeight: 1.6 }}>
              {t("step_3_desc")}
            </Text>
          </Paper>
        </SimpleGrid>

        <Divider my="lg" />

        <Box sx={{ backgroundColor: "#f8f9fa" }} p="md" radius="md">
          <Text size="sm" color="dimmed" align="center" mb="xs">
            {t("pro_tip")}
          </Text>
          <List
            spacing="sm"
            size="sm"
            center
            icon={
              <ThemeIcon color="teal" size={20} radius="xl">
                <Check size={12} />
              </ThemeIcon>
            }
          >
            {["tip_1", "tip_2", "tip_3"].map((tip) => (
              <List.Item key={tip}>
                <Text size="sm">{t(tip)}</Text>
              </List.Item>
            ))}
          </List>
        </Box>

        <Group position="center" mt="xl">
          <Button
            onClick={() => setShowInfoModal(false)}
            size="md"
            radius="md"
            leftIcon={<School size={20} />}
            px={40}
            styles={(theme) => ({
              root: {
                backgroundColor: "#FE6027",
                "&:hover": {
                  backgroundColor: "#FE6027",
                  opacity: 0.8,
                },
              },
            })}
          >
            {t("start_selecting")}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default Universities;

const UniversityContainer = styled.div``;
