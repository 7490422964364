import { useQuery } from "@tanstack/react-query";
import { getHighlightPost } from "../../services/apiHighlight";


export const useGetHighlightPost = (communityId, highlightId, postId,type) => {
    return useQuery({
        queryKey: ['highlight-post',communityId, highlightId, postId],
        queryFn: () => getHighlightPost({community_id: communityId, highlight_id: highlightId, post_id: postId}),
        enabled: !!communityId && !!highlightId && !!postId && (type === "highlight" || type === "sharedPost" || type === "savedPosts"),
    })
}
