import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import HighlightCard from "./HighlightCard";
import { Group, Text, Skeleton } from "@mantine/core";
import { ArrowRight, Bulb, Plus } from "tabler-icons-react";
import { HighlightCarouselStyled } from "../../style";
import Button from "../../../../Components/FormComponent/Button";
import { useParams } from "react-router-dom";
import ModalCreateHighlight from "../modal/ModalCreateHighlight";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

const LoadingSkeleton = () => (
  <div style={{ width: "300px" }}>
    <div
      style={{
        padding: "7px 20px 7px 8px",
        border: "1px solid #b8b8b8",
        borderRadius: "7px",
        background: "#fff",
      }}
    >
      <div style={{ display: "flex", gap: "12px" }}>
        {/* Image container */}
        <div
          style={{
            width: "50px",
            height: "43px",
            borderRadius: "7px",
            flexShrink: 0,
            overflow: "hidden",
          }}
        >
          <Skeleton height="100%" radius={7} />
        </div>

        {/* Text content */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          {/* Title */}
          <div>
            <Skeleton height={16} width="100%" mb={8} />
          </div>

          {/* Meta info */}
          <div>
            <Skeleton height={14} width={60} mb={8} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

const HighlightCarousel = ({ highlights, isLoading, isOwner }) => {
  const { id: community_id } = useParams();
  const [opened, setOpened] = useState(false);

  const handleOpenModal = () => {
    setOpened(true);
  };
  const handleCloseModal = () => {
    setOpened(false);
  };
  const xs = useMediaQuery('(max-width: 768px)');
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <HighlightCarouselStyled>
        <Group align="center" spacing={8} mb={6}>
          <Bulb color="#707070" size={xs ? 18 : 22} />
          <Text mt={2} size={xs ? "16px" : "18px"} weight={500} color="#707070">
            {t("community-highlights")}
          </Text>
        </Group>
        <div className="highlight-scroll-container">
          {[1, 2, 3, 4, 5].map((item) => (
            <LoadingSkeleton key={item} />
          ))}
        </div>
      </HighlightCarouselStyled>
    );
  }

  return (
    <HighlightCarouselStyled>
      <Group noWrap align="center" position="apart">
        <Group align="center" spacing={8} mb={6}>
          <Bulb color="#707070" size={xs ? 18 : 22} />
          <Text mt={2} size={xs ? "16px" : "18px"} weight={500} color="#707070">
            {t("community-highlights")}
          </Text>
        </Group>
        {isOwner ? (
          <Button
            backgroundColor="transparent"
            border="none"
            color="#FE6513"
            leftIcon={<Plus size={xs ? 18 : 20} />}
            onClick={handleOpenModal}
            mb={xs ? 2 : 0}
          >
            {t("create")}
          </Button>
        ) : highlights?.results?.length > 3 ? (
          <Button
            backgroundColor="transparent"
            border="none"
            color="#FE6513"
            rightIcon={<ArrowRight size={xs ? 18 : 20} />}
            to={`/community/${community_id}/highlights`}
          >
            {t("all")}
          </Button>
        ) : null}
      </Group>

      <Swiper
        modules={[Autoplay]}
        spaceBetween={40}
        slidesPerView={2.5}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        loop={highlights?.results?.length > 2 ? true : false}
        className="highlight-swiper"
      >
        {highlights?.results?.map((highlight, index) => (
          <SwiperSlide key={`${highlight.id}-${index}`}>
            <HighlightCard
              highlight={{
                communityId: community_id,
                id: highlight.id,
                slug: highlight.slug,
                image: highlight.image,
                name: highlight.name,
                posts: highlight.posts_count || 0,
                date: highlight.created_at,
              }}
            />
          </SwiperSlide>
        ))}
        
      </Swiper>
      <ModalCreateHighlight
        firstTime={false}
        communityId={community_id}
        opened={opened}
        onClose={handleCloseModal}
      />
    </HighlightCarouselStyled>
  );
};

export default HighlightCarousel;
