// hooks/community/useCommunity.js
import { showNotification } from '@mantine/notifications';
import { useQueryClient } from '@tanstack/react-query';
import { useRoutes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const useCommunity = () => {
  const {t} = useTranslation()
  const queryClient = useQueryClient();

  // Ortaq Query Keys
  const queryKeys = {
    all: (page,params,queryfilterParams) => ['communities',page,params,queryfilterParams?.search,queryfilterParams?.category],
    detail: (id) => ['community', id],
    members: (id) => ['community', id, 'members'],
  };

  // Cache yeniləmə
  const invalidateCommunityQueries = (communityId) => {
    queryClient.invalidateQueries({ queryKey: queryKeys.all });
    if (communityId) {
      queryClient.invalidateQueries({ queryKey: queryKeys.detail(communityId) });
    }
  };

  const handleSuccess = (message) => {
    showNotification({
        title: t("success"),
        message,
        color: 'green',
    });
  }

  // Xəta idarəetmə
  const handleError = (error) => {
    showNotification({
        title: t("error"),
        message: error?.message || t("error_happened"),
        color: 'red',
    });
  };

  // API Response transformasiyası
  const transformResponse = (data) => ({
    ...data,
    members: Number(data.members),
    createdAt: new Date(data.created_at),
  });

  return {
    queryKeys,
    invalidateCommunityQueries,
    handleError,
    handleSuccess,
    transformResponse,
  };
};