import { IconChevronDown, IconLanguage } from "@tabler/icons-react";
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const DropdownButton = styled.button`
  background: ${(props) => (props.isOpen ? "#1a5059" : "transparent")};
  color: ${(props) => {
    if (props.isOpen) return "#ccf075";
    return props.isUser ? "#364152" : "white";
  }};
  padding: ${(props) =>
    props.isUser && props.isLanguage ? "10px" : "10px 16px"};
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: ${(props) => (props.isLanguage ? "12px" : "16px")};
  font-weight: 500;
  transition: all 0.3s ease;
  justify-content: center;
  border-radius: 14px;
  position: relative;
  z-index: 1001;
  width: fit-content;
  box-shadow: ${(props) =>
    props.isOpen ? "0px 4px 15px 0px rgba(0, 0, 0, 0.1)" : "none"};

  @media (max-width: 1280px) {
    padding: ${(props) =>
      props.isUser && props.isLanguage ? "8px" : "12px"};
    font-size: ${(props) => (props.isLanguage ? "12px" : "14px")};
  }
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 72%;
  left: 50%;
  transform: translateX(-50%);
  display: ${(props) => (props.isOpen ? "block" : "none")};
  background: #246d79;
  width: 100%;
  border-radius: 0px 0px 14px 14px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;

  @media (min-width: 769px) {
    &:hover {
      ${DropdownButton} {
        background: #1a5059;
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
        color: #ccf075;
      }

      ${DropdownContent} {
        display: block;
      }
    }
  }
`;

const DropdownItem = styled.div`
  position: relative;
  padding: 12px 16px;
  color: ${props => props.isActive ? '#ccf075' : 'white'};
  cursor: pointer;
  transition: background 0.2s;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.isLanguage ? "12px" : "14px"};

  a {
    text-decoration: none;
    color: ${props => props.isActive ? '#ccf075' : 'white'};
    &:hover {
      color: #ccf075;
    }
  }

  &::after {

    content: "";
    width: ${(props) => (props.isLanguage ? "40%" : "60%")};
    height: 1px;
    background: #ccf075;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: ${(props) => (props?.itemsCount > 1 ? "block" : "none")};
  }

  &:hover {
    color: #ccf075;
  }

  &:first-child {
    margin-top: 15px;
  }

  &:last-child {
    border-radius: 0 0 14px 14px;
    &::after {
      display: none;
    }
  }

  @media (max-width: 1280px) {
    padding: 10px 12px;
    font-size: ${(props) => (props.isLanguage ? "11px" : "13px")};
  }
`;

const Chevron = styled(IconChevronDown)`
  margin-left: 4px;
  display: inline-block;
  transform: ${(props) => (props.$isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.3s ease;

  ${DropdownContainer}:hover & {
    transform: rotate(180deg);
  }
`;

export const CustomDropdown = ({
  buttonLabel,
  menuItems,
  isLanguage,
  onItemClick,
  isNoneDropdown,
  link,
  className,
  isUser,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen && window.innerWidth <= 768) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleClick = () => {
    if (window.innerWidth <= 768) {
      setIsOpen(!isOpen);
    }
  };

  const handleItemClick = (value) => {
    if (onItemClick) {
      onItemClick(value);
    }
    setIsOpen(false);
  };

  if (isNoneDropdown) {
    return (
      <Link to={link}>
        <DropdownContainer>
          <DropdownButton isUser={isUser} className={className}>
            {buttonLabel}
          </DropdownButton>
        </DropdownContainer>
      </Link>
    );
  }
  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton
        className={className}
        isLanguage={isLanguage}
        isUser={isUser}
        onClick={handleClick}
        isOpen={isOpen}
      >
        {isLanguage && <IconLanguage size={18} />}
        {buttonLabel}
        {!isNoneDropdown && (
          <Chevron style={{ marginTop: "2px" }} size={14} $isOpen={isOpen} />
        )}
      </DropdownButton>
      <DropdownContent isOpen={isOpen}>
        {menuItems?.map((item, index) => {
          const itemLink = typeof item === 'string' ? '/' : item.link;
          const isActive = pathname === itemLink;
          
          return (
            <DropdownItem
              itemsCount={menuItems.length}
              isLanguage={isLanguage}
              key={index}
              isActive={isActive}
              onClick={() =>
                handleItemClick(
                  typeof item === "string" ? item.toLowerCase() : item.value
                )
              }
            >
              {isLanguage ? (
                typeof item === "string" ? (
                  item
                ) : (
                  item.label
                )
              ) : (
                <Link to={item.link || "/"}>
                  {typeof item === "string" ? item : item.label}
                </Link>
              )}
            </DropdownItem>
          );
        })}
      </DropdownContent>
    </DropdownContainer>
  );
};
