import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Tabs, Text, Modal, List, ThemeIcon } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { InfoCircle, CircleCheck } from "tabler-icons-react";
import Banner from "../../Components/StudyAbroad/Banner";
import PortalGraphic from "../../Components/StudyAbroad/Portal";

const UniversityPortal = () => {
  const { t } = useTranslation();
  const [infoModalOpen, setInfoModalOpen] = useState(true);

  useEffect(() => {
    // Scroll to the portal section with smooth behavior
    const portalSection = document.querySelector('.portal-section');
    if (portalSection) {
      portalSection.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Container>
      <Modal
        opened={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        title={
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <InfoCircle size={24} color="#364152" />
            <Text size="xl" weight={600} color="#364152">
            {t("portal_modal.title")}
            </Text>
          </div>
        }
        size="lg"
      >
        <ModalContent>
          <Text size="md" mb={20} color="#364152">
            {t("portal_modal.description")}
          </Text>
          
          <List
            spacing="md"
            size="md"
            icon={
              <ThemeIcon color="gray" size={24} radius="xl">
                <CircleCheck size={16} />
              </ThemeIcon>
            }
          >
            <List.Item>
              <Text weight={500}>{t("portal_modal.stages.title")}</Text>
              <Text size="sm" color="dimmed">
                {t("portal_modal.stages.description")}
              </Text>
            </List.Item>
            
            <List.Item>
              <Text weight={500}>{t("portal_modal.documents.title")}</Text>
              <Text size="sm" color="dimmed">
                {t("portal_modal.documents.description")}
              </Text>
            </List.Item>
            
            <List.Item>
              <Text weight={500}>{t("portal_modal.applications.title")}</Text>
              <Text size="sm" color="dimmed">
                {t("portal_modal.applications.description")}
              </Text>
            </List.Item>
            
            <List.Item>
              <Text weight={500}>{t("portal_modal.updates.title")}</Text>
              <Text size="sm" color="dimmed">
                {t("portal_modal.updates.description")}
              </Text>
            </List.Item>
          </List>
        </ModalContent>
      </Modal>

      <Banner props={{ type: "all", t }} />
      <div className="portal-section" style={{maxWidth: "95%", margin: "20px auto 60px auto" }}>
      <Text mb={20} mt={40} size="xl" weight={500} color="#364152">
        {t("portal_modal.title")}
      </Text>

        <Tabs  variant="pills" defaultValue="university">
          <Tabs.List>
            <Tabs.Tab value="university">UNIVERSITY</Tabs.Tab>
            <Tabs.Tab value="agent">AGENT</Tabs.Tab>
          </Tabs.List>

          <div
      style={{
        width: "100%",
        height: "2px",
        background :"#c8c8c8",
        margin  : "20px 0 20px 0"
      }}
      >

      </div>

          <Tabs.Panel value="university" pt="xs">
            <PortalGraphic type="university" />
          </Tabs.Panel>

          <Tabs.Panel value="agent" pt="xs">
            <PortalGraphic type ="agent"/>
          </Tabs.Panel>
        </Tabs>
      </div>
    </Container>
  );
};

export default UniversityPortal;

const Container = styled.div`
.mantine-2uszzl[data-active] {
  background-color :#696969;
  font-size :16px;
  font-weight :500;
  color: #fff;
}
.mantine-2uszzl {
  font-size :16px;
  font-weight :500;
  color :#7a7a7a;
}
`;

const ModalContent = styled.div`
  padding: 10px;
  
  .mantine-List-item {
    padding: 10px;
    border-radius: 8px;
    transition: background-color 0.2s;
    
    &:hover {
      background-color: #f8f9fa;
    }
  }
`;
