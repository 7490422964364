import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useHighlight } from "./useHighlight";
import { deleteHighlightPost } from "../../services/apiHighlight";
import { useTranslation } from "react-i18next";
export const useDeleteHighlightPost = (community_id, highlight_id,post_tab,label) => {
    const {handleError, handleSuccess} = useHighlight();
    const queryClient = useQueryClient();
    const {t} = useTranslation()
    return useMutation({
        mutationFn: ({community_id, highlight_id, post_id}) => deleteHighlightPost({community_id, highlight_id, post_id}),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['highlight-posts',community_id,highlight_id,post_tab]});
            handleSuccess(t("highlight_post_deleted_successfully"));
        },
        onError: (error) => handleError(error),
    })
}
