import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createHighlightPost } from "../../services/apiHighlight";
import { useHighlight } from "./useHighlight";
import { useTranslation } from "react-i18next";

export const useCreateHighlightPost = ({closeModal, resetStates,community_id,highlight_id,post_tab,label}) => {
    const {handleError, handleSuccess} = useHighlight();
    const queryClient = useQueryClient();
    const {t} = useTranslation()
    return useMutation({
        mutationFn: ({community_id, highlight_id, data}) => createHighlightPost({community_id, highlight_id, data}),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['highlight-posts',community_id,highlight_id,post_tab]});
            handleSuccess(t("post_created_successfully"));
            resetStates();
            closeModal();
        },
        onError: (error) => handleError(error),
    })
}
