import { useMutation, useQueryClient } from "@tanstack/react-query";
import { savePost } from "../../services/apiPost";
import { usePost } from "./usePost";
import { useHighlight } from "../highlight/useHighlight";
import { useTranslation } from "react-i18next";

export const useSavePost = (community_id,highlightId,postTab,label,type) => {
    const {queryKeys,invalidatePostQueries,handleSuccess} = usePost();
    const {invalidateHighlightQueries,} = useHighlight();
    const queryClient = useQueryClient();
    const {t} = useTranslation()
    return useMutation({
        mutationFn: ({community_id,post_id}) => savePost({community_id,post_id}),
        onSuccess: () => {
            handleSuccess(t("post_saved_successfully"));
            if(type === "community"){
                queryClient.invalidateQueries({queryKey: ['all',community_id,postTab]});
            } else if(type === "highlight"){
                queryClient.invalidateQueries({queryKey: ['highlight-posts',community_id,highlightId,postTab]});
            }
        },
        onError: (error) => {
        }
    })
}