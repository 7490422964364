import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePost } from "../usePost";
import { replyComment } from "../../../services/apiPost";


export const useReplyComment = (community_id,post_id,comment_id,page,replyingToReply) => {
    const { invalidatePostQueries, handleError } = usePost();
    const queryClient = useQueryClient();
    return useMutation({
        
        mutationFn: ({data}) => replyComment({community_id,post_id,comment_id:replyingToReply,data}),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['replies',community_id,post_id,comment_id,page]});
        },
        onError: (error) => {
            handleError(error);
        },
    })
}
