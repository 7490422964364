import React from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../Helpers/Axios";
import { useQuery } from "@tanstack/react-query";
import {
  Title,
  Stack,
  UnstyledButton,
  Text,
  Accordion,
  Grid,
} from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";

const RelatedPrograms = ({ props }) => {
  const { type } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sizeXs = useMediaQuery("(max-width: 756px)");

  let link =
    type === "courses"
      && "/bootcamp"

  const { data: courses } = useQuery([type], async () => {
    return await axiosInstance.get(link).then((res) => res.data.results);
  });

  const { data: steamProjects } = useQuery(["steam"], async () => {
    return await axiosInstance
      .get("/steam/steam")
      .then((res) => res.data.results);
  });

  const renderItems = (items, itemType, limit = 2) => {
    return items?.slice(0, limit).map((item, index) => (
      <React.Fragment key={item.id}>
        <Grid align="center"  component={Link} to={`/${itemType === "course" ? "bootcamp" : "steam"}/${item.slug}-${item.id}`}>
          <Grid.Col style={{display: "flex", alignItems: "center", justifyContent: "center"}} span={4}>
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "fill",
                borderRadius: "6px",
              }}
              src={itemType === "course" ? item?.bootcamp_image : item?.steam_image}
              alt={itemType === "course" ? item?.bootcamp_name : item?.steam_name}
            />
          </Grid.Col>
          <Grid.Col span={8}>
            <Text size={14} weight={400} color="#575757">
              {itemType === "course" ? item?.bootcamp_name : item?.steam_name}
            </Text>
          </Grid.Col>
        </Grid>
        {index !== limit - 1 && (
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#eeeeee",
            }}
          ></div>
        )}
      </React.Fragment>
    ));
  };

  return (
    <div>
      <style>
        {`
          .mantine-21wjnm:hover {
            border-radius: 15px;
          }
          
          .mantine-v4lv9f {
            border-bottom: none;
          }

          @media (max-width: 756px) {
            .mantine-1avyp1d {
              display: flex;
              flex-direction: column;
              gap: 5px;
              background: #ebebeb;
              box-shadow: none;
            }
          }
        `}
      </style>
      <Accordion radius={sizeXs ? 16 : 0} variant={sizeXs ? "separated" : "default"} defaultValue="courses">
        <Accordion.Item value="courses">
          <Accordion.Control>
            <Title
              color="#364152"
              w={500}
              size={16}
              sx={{ textTransform: "capitalize", display: "inline" }}
            >
              {t("intensive-course")}
            </Title>
          </Accordion.Control>
          <Accordion.Panel>
            <Stack>
              {renderItems(courses, "course")}
              <UnstyledButton
                onClick={() => navigate("/courses")}
                style={{
                  color: "#BBBBBB",
                  textAlign: "left",
                  fontSize: "14px", 
                  fontWeight: "500",
                }}
              >
                {t("show-more")}
              </UnstyledButton>
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>
        <div style={{width: "100%", height: "1px", backgroundColor: "#eeeeee"}}></div>
        <Accordion.Item value="steam">
          <Accordion.Control>
            <Title
              color="#364152"
              w={500}
              size={16}
              sx={{ textTransform: "capitalize", display: "inline" }}
            >
              {t("steam-projects")}
            </Title>
          </Accordion.Control>
          <Accordion.Panel>
            <Stack>
              {renderItems(steamProjects, "steam")}
              <UnstyledButton
                onClick={() => navigate("/steam")}
                style={{
                  color: "#BBBBBB",
                  textAlign: "left",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {t("show-more")}
              </UnstyledButton>
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default RelatedPrograms;
