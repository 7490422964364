import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import styled from "styled-components";

const ProgramMap = ({ data, xs, type }) => {

  const unit = type === "bootcamp" ? "section" : "week";

  return (
    <MapContainer type={type}>
      <Swiper
        modules={[Navigation]}
        breakpoints={{
          320: { slidesPerView: 1.2 },
          768: { slidesPerView: 2.5 },
          1024: { slidesPerView: 3.5 },
          1440: { slidesPerView: 3.5 },
        }}
        spaceBetween={20}
      >
        {data &&
          data[`${unit}s_output`].map((u, index) => (
            <SwiperSlide key={index}>
              <ProgramCard>
                <CardContent>
                  <Title>{u[`${unit}_title`]}</Title>
                  <Week>
                    {unit === "section"
                      ? `Section ${index + 1}`
                      : `Week ${index + 1}`}
                  </Week>
                </CardContent>
              </ProgramCard>
            </SwiperSlide>
          ))}
      </Swiper>
    </MapContainer>
  );
};

const MapContainer = styled.div`
  background: ${(props) => (props.type === "steam" ? "#e9f0f1" : "#D9F0A1")};
  margin-top: 2rem;
  padding: 2rem 0;
  .swiper-wrapper {
    display: flex;
    align-items: center;
    max-width: 95%;
    margin: 0 auto;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: #333;
    &:after {
      font-size: 1.5rem;
    }
  }
`;

const ProgramCard = styled.div`
  position: relative;
  padding: 1px;
  margin-right: 30px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #c1c9d2;
    clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%, 10% 50%);
    z-index: 1;
  }
`;

const CardContent = styled.div`
  position: relative;
  background: #ffffff;
  padding: 0.2rem 1.5rem 0.2rem 2.5rem;
  height: 84px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%, 10% 50%);
  box-shadow: 0px 1.2px 2.4px 0px #37415114;
  z-index: 2;

  &:hover {
    background: #f8f9fa;
    transition: all 0.3s ease;
  }
`;

const Title = styled.h3`
  font-size: 1rem;
  color: #00a3a3;
  margin-bottom: 0.5rem;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
  height: 2.8em;
`;

const Week = styled.p`
  font-size: 0.9rem;
  color: #00a3a3;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default ProgramMap;
