import React from "react";
import styled from "styled-components";
import { Skeleton } from "@mantine/core";

const TaskSectionSkeleton = () => {
  return (
    <TaskWrapper>
      {/* Task Title Skeleton */}
      <Skeleton height={30} width={150} mt={24} mb={30} />

      {/* Task Description Skeleton */}
      <TaskHeader>
        <Skeleton height={100} width="100%" mb={32} />
      </TaskHeader>

      {/* Files Section Skeleton */}
      <FileSection>
        <Skeleton height={20} width={60} mb={10} />
        <FileList>
          {[1, 2].map((item) => (
            <FileItem key={item}>
              <Skeleton height={40} width="100%" />
            </FileItem>
          ))}
        </FileList>
      </FileSection>

      {/* Questions Section Skeleton */}
      <QuestionSection>
        {[1, 2, 3].map((item) => (
          <QuestionWrapper key={item}>
            <Question>
              <Skeleton height={24} width="100%" mb={16} />
            </Question>
            <EditorWrapper>
              <Skeleton height={200} width="100%" />
            </EditorWrapper>
          </QuestionWrapper>
        ))}
      </QuestionSection>

      {/* Navigation Buttons Skeleton */}
      <TaskNavigation>
        <Skeleton height={40} width={120} />
        <Skeleton height={40} width={140} />
        <Skeleton height={40} width={120} />
      </TaskNavigation>
    </TaskWrapper>
  );
};

export default TaskSectionSkeleton;

// Styled Components
const TaskWrapper = styled.div`
  max-width: 90%;
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 90%;
    margin: 0 auto;
  }
`;

const TaskHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

const FileSection = styled.div`
  margin: 24px 0;
  border-radius: 8px;
`;

const FileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const FileItem = styled.div`
  padding: 6px;
  border-radius: 6px;
`;

const QuestionSection = styled.div``;

const QuestionWrapper = styled.div`
  margin-bottom: 32px;
`;

const Question = styled.div`
  display: flex;
  gap: 8px;
`;

const EditorWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
`;

const TaskNavigation = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 32px;
  @media (max-width: 408px) {
    align-items: center;
    justify-content: center;
  }
`;