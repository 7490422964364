import { Button, Group, Text } from "@mantine/core";
import { InfoCircle } from "tabler-icons-react";
import { useTranslation } from "react-i18next";

const CompleteProfileBanner = ({ setIsCompleteProfile }) => {
  const { t } = useTranslation();

  const handleLater = () => {
    localStorage.removeItem("complete_profile");
    setIsCompleteProfile(false);
  };

  return (
    <div
      style={{
        position: "sticky",
        top: 82,
        zIndex: 1000,
        border: "1px solid #FFDEB2",
        background: "#FFF3E3",
        boxShadow: "0px 1px 10px 0px #0000001A",
        borderRadius: 10,
        padding: "15px 28px",
        marginBottom: "20px",
        animation: "borderAnimation 2s infinite linear"
      }}
    >
      <Group position="apart" align="center">
        <Group align="center" spacing={10}>
          <InfoCircle fill="#FF9900" stroke="#fff" size={34} />
          <Text size={18} fw={500} color="#FF9900">
            IELTS və SAT sertifikatlarınız varsa, təsdiq olunmuş bacarıqlarınıza əlavə edə bilərsiniz
          </Text>
        </Group>
        <Button
          onClick={handleLater}
          variant="subtle"
          color="orange"
          styles={{
            root: {
              fontSize: "16px",
              fontWeight: 500,
              '&:hover': {
                backgroundColor: 'transparent',
                opacity: 0.8
              }
            }
          }}
        >
          Daha sonra
        </Button>
      </Group>

      <style jsx>{`
        @keyframes borderAnimation {
          0% {
            border-color: #FFDEB2;
          }
          50% {
            border-color: #FF9900;
          }
          100% {
            border-color: #FFDEB2;
          }
        }
      `}</style>
    </div>
  );
};

export default CompleteProfileBanner;
