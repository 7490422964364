import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import styled from "styled-components";
import {
  IconInfoCircle,
  IconShare2,
  IconExternalLink,
  IconCalendar,
  IconClock,
  IconHourglassHigh,
  IconCurrencyManat,
  IconAlertCircle,
  IconChecklist,
  IconUsers,
  IconShare,
} from "@tabler/icons-react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Accordion,
  Avatar,
  Modal,
  List,
  Text,
  Group,
} from "@mantine/core";
import TaskSection from "../../features/Competition/components/TaskSection";
import { useGetCompetition } from "../../features/Competition/hooks/useGetCompetition";
import { AppContext } from "../../Helpers/Context";
import ResultsSection from "../../features/Competition/components/ResultsSection";
import MyResultsSection from "../../features/Competition/components/MyResultsSection";
import { useQuery } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import { getSlugAndIdFromPath } from "../../features/Competition/utils";
import CompetitionDetailSkeleton from "../../features/Competition/components/skeletons/CompetitionDetailSkeleton";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mantine/hooks";
import { Helmet } from "react-helmet-async"; // react-helmet əvəzinə react-helmet-async
import NotificationModal from "../../Components/Modals/NotificationModal";
import i18next from "i18next";
import ShareDropdown from "../../features/Community/components/community-panel/post-panel/ShareDropdown";
import { Link } from "react-router-dom";

const NavigationItem = ({ id, label, isActive, onClick }) => (
  <NavItem onClick={() => onClick(id)} $isActive={isActive}>
    {label}
  </NavItem>
);

const CompetitionDetail = () => {
  const { pathname } = useLocation();
  const { slug, id } = getSlugAndIdFromPath(pathname);
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const [modalOpened, setModalOpened] = useState(!!status || false);

  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("description");
  const [isPaid, setIsPaid] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [isHackathon, setIsHackathon] = useState(false);
  const [showTasks, setShowTasks] = useState(false);
  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const [showRulesModal, setShowRulesModal] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const { user } = useContext(AppContext);
  const [untilStart, setUntilStart] = useState(null);
  const [showTimeWarningModal, setShowTimeWarningModal] = useState(false);
  const [showResultsPendingModal, setShowResultsPendingModal] = useState(false);
  const [showStartedModal, setShowStartedModal] = useState(false);
  const [showEndingSoonModal, setShowEndingSoonModal] = useState(false);
  const [hasShownEndWarning, setHasShownEndWarning] = useState(() => {
    const stored = localStorage.getItem(`competition_${id}_end_warning`);
    return stored ? JSON.parse(stored) : false;
  });
  const [timeRemaining, setTimeRemaining] = useState(null);

  const { data: studentResults } = useQuery({
    queryKey: ["studentResults", id],
    queryFn: async () =>
      await axios
        .get(`/olympiad/${slug}-${id}/students/`)
        .then((res) => res.data),
    enabled: !!id && !!slug && !!user,
  });

  const { data: myResults } = useQuery({
    queryKey: ["myResults", id],
    queryFn: async () =>
      await axios
        .get(`/olympiad/${slug}-${id}/student-result/`)
        .then((res) => res.data),
    enabled: !!id && !!slug && !!user && !!isPaid,
  });

  const { data: relatedCourses } = useQuery({
    queryKey: ["relatedCourses", id],
    queryFn: async () =>
      await axios
        .get(`/olympiad/${slug}-${id}/related-courses/`)
        .then((res) => res.data),
  });

  const [isShowResults, setIsShowResults] = useState(false);

  const size1280 = useMediaQuery("(max-width: 1280px)");
  const size990 = useMediaQuery("(max-width: 990px)");

  const {
    data: competition,
    isLoading,
    isError,
    refetch,
  } = useGetCompetition(slug, id);
  useEffect(() => {
    if (competition?.is_joined) {
      setIsPaid(true);
      setShowStartedModal(false);
    }
  }, [competition]);

  const { t } = useTranslation();

  const navigationItems = useMemo(
    () => [
      ...(!isEnded && showTasks
        ? [{ id: "tasks", label: t("competitionDetail.task") }]
        : []),
      ...(isEnded && isPaid && user
        ? [
            { id: "results", label: t("competitionDetail.results") },
            { id: "myResults", label: t("competitionDetail.myResults") },
          ]
        : []),
      { id: "description", label: t("competitionDetail.description") },
      { id: "requirements", label: t("competitionDetail.requirements") },
      ...(competition?.menthors_list?.length > 0
        ? [{ id: "mentors", label: t("competitionDetail.mentors") }]
        : []),
      {
        id: "hostingOrganization",
        label: t("competitionDetail.hostingOrganization"),
      },
      { id: "location", label: t("competitionDetail.location") },
      { id: "dateAndTime", label: t("competitionDetail.dateAndTime") },
    ],
    [showTasks, isEnded, t, competition?.menthors_list, isPaid, user]
  );

  useEffect(() => {
    if (competition && competition?.is_completed) {
      setIsShowResults(true);
      setActiveSection("results");
    } else {
      setActiveSection("description");
    }
  }, [competition]);
  const scrollToSection = (sectionId) => {
    // Show results pending modal if competition is not completed
    if (
      ["results", "myResults"].includes(sectionId) &&
      !competition?.is_completed
    ) {
      setShowResultsPendingModal(true);
      return;
    }

    // Show results pending modal if results aren't available
    if (
      sectionId === "results" &&
      (!studentResults || studentResults.length === 0)
    ) {
      setShowResultsPendingModal(true);
      return;
    }

    // Show results pending modal if my results aren't available
    if (sectionId === "myResults" && !myResults) {
      setShowResultsPendingModal(true);
      return;
    }

    // Handle tasks section separately
    if (sectionId === "tasks") {
      setActiveSection(sectionId);
      return;
    }

    // Normal section scrolling behavior
    const element = document.getElementById(sectionId);
    if (element) {
      setActiveSection(sectionId);
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offset = 200;
      const additionalOffset = sectionId === "partners" ? -80 : 0;

      window.scrollTo({
        top: elementPosition - offset - additionalOffset,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 250; // Header və navigation-ın hündürlüyünü nəzərə alırıq
      let currentSection = null;
      let minDistance = Infinity;

      // Bütün section-ları yoxlayırıq
      for (const { id } of navigationItems) {
        const element = document.getElementById(id);
        if (element) {
          const rect = element.getBoundingClientRect();
          const elementTop = window.scrollY + rect.top;
          const elementBottom = elementTop + element.offsetHeight;
          const distance = Math.abs(scrollPosition - elementTop);

          // Scroll position section-un yuxarı və aşağı sərhədləri arasındadırsa
          if (
            scrollPosition >= elementTop - 250 &&
            scrollPosition <= elementBottom
          ) {
            if (distance < minDistance) {
              minDistance = distance;
              currentSection = id;
            }
          }
        }
      }

      // Əgər aktiv section tapılıbsa, onu set edirik
      if (currentSection) {
        setActiveSection(currentSection);
      }
    };

    window.addEventListener("scroll", handleScroll);
    // Component unmount olduqda event listener-i təmizləyirik
    return () => window.removeEventListener("scroll", handleScroll);
  }, [navigationItems]);

  useEffect(() => {
    if (competition) {
      setTasks(competition.olympiad_tasks);
      setIsHackathon(competition.is_hackathon);
    }
  }, [competition]);
  // const start_date  = "2025-02-04T15:00:00Z"
  // const end_date = "2025-02-04T15:06:00Z"
  // const start_time = "15:00"
  // const end_time = "15:06"

  const hasRefetched = useRef(false);

  useEffect(() => {
    if (!competition?.olympiad_dates?.[0]) return;

    const checkTime = () => {
      try {
        const utcOffset = 4 * 60 * 60 * 1000;

        const now = new Date();
        const startUTC = new Date(
          new Date(competition.olympiad_dates[0].start_date).getTime() -
            utcOffset
        );
        const endUTC = new Date(
          new Date(competition.olympiad_dates[0].end_date).getTime() - utcOffset
        );

        const minutesUntilStart = Math.ceil(
          (startUTC.getTime() - now.getTime()) / (1000 * 60)
        );
        const minutesUntilEnd = Math.ceil(
          (endUTC.getTime() - now.getTime()) / (1000 * 60)
        );
        const secondsUntilStart = Math.ceil(
          (startUTC.getTime() - now.getTime()) / 1000
        );
        const secondsUntilEnd = Math.ceil(
          (endUTC.getTime() - now.getTime()) / 1000
        );

        // Update time remaining display
        if (minutesUntilEnd > 0 && minutesUntilStart <= 0) {
          setTimeRemaining({
            minutes: Math.floor(secondsUntilEnd / 60),
            seconds: secondsUntilEnd % 60,
            isLastTwoMinutes: minutesUntilEnd <= 2,
          });
        } else {
          setTimeRemaining(null);
        }

        // Show rules modal 30 seconds before start
        if (secondsUntilStart <= 59 && secondsUntilStart > 0) {
          setShowRulesModal(true);
          setShowTasks(false);
          setUntilStart(secondsUntilStart);
        }

        // Competition starts
        if (minutesUntilStart <= 0 && minutesUntilEnd > 0) {
          setShowRulesModal(false);
          if (!isPaid) {
            setShowStartedModal(true);
          } else {
            setShowTasks(true);
            setShowStartedModal(false);
            setActiveSection("tasks");

            // Ref istifadə edərək bir dəfə refetch edirik
            if (!hasRefetched.current) {
              refetch();
              hasRefetched.current = true;
            }
          }
        }

        // Show warning 2 minutes before end
        if (minutesUntilEnd === 2 && !hasShownEndWarning) {
          setShowEndingSoonModal(true);
          setHasShownEndWarning(true);
          localStorage.setItem(`competition_${id}_end_warning`, "true");
        }

        // Competition ends
        if (minutesUntilEnd <= 0) {
          setIsEnded(true);
          setShowTasks(false);
          if (activeSection === "tasks") {
            setActiveSection("results");
          }
          localStorage.removeItem(`competition_${id}_end_warning`);
        }
      } catch (error) {
        console.error("Error in checkTime:", error);
      }
    };

    checkTime();
    const interval = setInterval(checkTime, 1000);
    return () => clearInterval(interval);
  }, [competition?.olympiad_dates, isPaid, id, hasShownEndWarning]);

  const formatRemainingTime = (minutes, seconds) => {
    const totalSeconds = minutes * 60 + seconds;

    // Convert to days, hours, minutes, seconds
    const days = Math.floor(totalSeconds / (24 * 60 * 60));
    const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
    const remainingMinutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const remainingSeconds = totalSeconds % 60;

    // Format based on largest non-zero unit
    if (days > 0) {
      return `${days}g ${hours}s ${remainingMinutes}d`;
    } else if (hours > 0) {
      return `${hours}s ${remainingMinutes}d`;
    } else if (remainingMinutes > 0) {
      return `${remainingMinutes}d ${remainingSeconds}s`;
    } else {
      return `${remainingSeconds}s`;
    }
  };

  const months = {
    az: [
      "Yan",
      "Fev",
      "Mar",
      "Apr",
      "May",
      "İyun",
      "İyul",
      "Avq",
      "Sen",
      "Okt",
      "Noy",
      "Dek",
    ],
    en: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    ru: [
      "Янв",
      "Фев",
      "Мар",
      "Апр",
      "Май",
      "Июн",
      "Июл",
      "Авг",
      "Сен",
      "Окт",
      "Ноя",
      "Дек",
    ],
    tr: [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ],
  };

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const monthIndex = date.getMonth();
      const year = date.getFullYear();
      const currentLang = i18next.language || "az";

      return `${day} ${months[currentLang][monthIndex]} ${year}`;
    } catch (error) {
      console.error("Date formatting error:", error);
      return dateString;
    }
  };

  const getTooltipMessage = (olympiadType) => {
    switch (olympiadType) {
      case 1:
        return t("competitionDetails.offlineTooltip");
      case 2:
        return t("competitionDetails.hybridExternalTooltip");
      case 3:
        return t("competitionDetails.hybridTaskoolTooltip");
      case 4:
        return t("competitionDetails.onlineExternalTooltip");
      case 5:
        return t("competitionDetails.onlineTaskoolTooltip");
      default:
        return t("competitionDetails.defaultTooltip");
    }
  };

  if (isLoading) {
    return <CompetitionDetailSkeleton />;
  }

  if (isError) return <div>Error</div>;

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    setShowShareDropdown(true);
    // You might want to add a toast notification here
  };

  const handleGetTickets = () => {
    navigate(`/competition/${slug}-${id}/payment`);
  };

  const handleCloseEndWarning = () => {
    setShowEndingSoonModal(false);
    setHasShownEndWarning(true);
    localStorage.setItem(`competition_${id}_end_warning`, "true");
  };
  const closeModal = () => {
    setModalOpened(false);
    if (status === "Accepted") {
      navigate(`/competitions/${slug}-${id}`);
      // setActiveSection("tasks");
    }
  };

  const levelColor =
    competition?.olympiad_level === "Easy"
      ? "#00BD90"
      : competition?.olympiad_level === "Medium"
      ? "#F3CF02"
      : "#FE6513";

  const getShareUrl = () => {
    const baseUrl = window.location.origin;
    return `${baseUrl}/competitions/${slug}-${id}`;
  };

  return (
    <StyledCompetitionDetail>
      <Helmet>
        <title>
          {competition?.name || "Competition Detail"} |{" "}
          {competition?.is_hackathon ? "Hackathon" : "Olympiad"}
        </title>
        <meta
          name="description"
          content={
            competition?.content?.slice(0, 155) ||
            "Join our exciting competition and showcase your skills"
          }
        />
        <meta
          property="og:title"
          content={`${competition?.name || "Competition Detail"} | ${
            competition?.is_hackathon ? "Hackathon" : "Olympiad"
          }`}
        />

        <meta
          property="og:description"
          content={
            competition?.content?.slice(0, 155) ||
            "Join our exciting competition and showcase your skills"
          }
        />
        <meta
          property="og:image"
          content={competition?.cover_image_url || ""}
        />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Banner backgroundImage={competition?.cover_image_url}>
        <BannerInner
          backgroundImage={competition?.cover_image_url}
        ></BannerInner>
        <BannerTitle>{competition?.name}</BannerTitle>
      </Banner>
      <Navigation>
        <nav>
          {navigationItems.map((item) => (
            <NavigationItem
              key={item.id}
              id={item.id}
              label={item.label}
              isActive={activeSection === item.id}
              onClick={scrollToSection}
            />
          ))}
          {timeRemaining && (
            <TimeRemaining $isLastTwoMinutes={timeRemaining.isLastTwoMinutes}>
              {t("competitionDetail.timeRemaining")}{" "}
              {formatRemainingTime(
                timeRemaining.minutes,
                timeRemaining.seconds
              )}
            </TimeRemaining>
          )}
        </nav>
      </Navigation>

      <GridContainer isPaid={isPaid} isEnded={isEnded}>
        {!isEnded && activeSection === "tasks" && (
          <Section id="tasks">
            {showTasks ? (
              <TaskSection
                isHackathon={isHackathon}
                competitionId={id}
                slug={slug}
                tasks={tasks}
              />
            ) : showRulesModal ? (
              <CountdownWrapper>
                <IconClock size={48} color="#029199" />
                <Text size="xl" weight={600} color="#364152">
                  {t("competitionDetail.competitionStartsIn")}
                </Text>
                <CountdownTimer></CountdownTimer>
              </CountdownWrapper>
            ) : null}
          </Section>
        )}

        {(activeSection !== "tasks" || isEnded) && (
          <ContentWrapper $isPaid={isPaid} $isEnded={isEnded}>
            {isShowResults && (
              <>
                {studentResults && studentResults.length > 0 && (
                  <Section id="results" $isActive={activeSection === "results"}>
                    <SectionTitle
                      className={activeSection === "results" ? "active" : ""}
                    >
                      {t("competitionDetail.results")}
                    </SectionTitle>
                    <ResultsSection
                      dummyResults={studentResults}
                      competitionId={id}
                    />
                  </Section>
                )}

                {isShowResults && isPaid && (
                  <Section
                    id="myResults"
                    $isActive={activeSection === "myResults"}
                  >
                    <SectionTitle
                      style={{ marginTop: "24px" }}
                      className={activeSection === "myResults" ? "active" : ""}
                    >
                      {t("competitionDetail.myResults")}
                    </SectionTitle>
                    <MyResultsSection
                      myScore={myResults}
                      slug={slug}
                      competitionId={id}
                      userId={user?.id}
                    />
                  </Section>
                )}
              </>
            )}

            <Section
              $isActive={activeSection === "description"}
              id="description"
            >
              <SectionTitle
                className={activeSection === "description" ? "active" : ""}
              >
                {t("competitionDetail.description")}
              </SectionTitle>
              <LevelBadge>
                <Diamond $color={levelColor} />
                {t("competitionDetail.level")} -{" "}
                {competition?.olympiad_level === "Easy"
                  ? t("competitionDetail.easy")
                  : competition?.olympiad_level === "Medium"
                  ? t("competitionDetail.medium")
                  : t("competitionDetail.hard")}
              </LevelBadge>
              <div
                className="description-content"
                style={{ color: "#697586" }}
                dangerouslySetInnerHTML={{ __html: competition?.content }}
              />
            </Section>

            <Section
              $isActive={activeSection === "requirements"}
              id="requirements"
            >
              <SectionTitle
                className={activeSection === "requirements" ? "active" : ""}
              >
                {t("competitionDetail.requirements")}
              </SectionTitle>
              <Group mb={24} align="center">
                {competition?.skills_list?.map((skill) => (
                  <SkillBadge key={skill.id}>{skill.skill}</SkillBadge>
                ))}
              </Group>
              <div
                className="description-content"
                style={{ color: "#697586" }}
                dangerouslySetInnerHTML={{ __html: competition?.skills_text }}
              />
            </Section>

            {competition?.menthors_list?.length > 0 && (
              <Section $isActive={activeSection === "mentors"} id="mentors">
                <SectionTitle
                  className={activeSection === "mentors" ? "active" : ""}
                >
                  {t("competitionDetail.mentors")}
                </SectionTitle>
                {competition?.menthors_list?.map((mentor) => (
                  <MentorCard
                    component={Link}
                    to={`${
                      mentor?.guid
                        ? `/profile/${mentor?.guid}`
                        : `/competitions/${slug}-${id}`
                    }`}
                    key={mentor.id}
                  >
                    <Avatar
                      radius="100%"
                      size={size990 ? 40 : size1280 ? 60 : 100}
                      src={mentor.image}
                      alt={mentor.name}
                    />
                    <MentorInfo>
                      <MentorName>
                        {mentor.first_name} {mentor.last_name}
                      </MentorName>
                      <Text className="description-content" color="#697586">
                        {mentor?.bio}
                      </Text>
                    </MentorInfo>
                  </MentorCard>
                ))}
              </Section>
            )}

            <Section
              $isActive={activeSection === "hostingOrganization"}
              id="hostingOrganization"
            >
              <SectionTitle
                className={
                  activeSection === "hostingOrganization" ? "active" : ""
                }
              >
                {t("competitionDetail.hostingOrganization")}
              </SectionTitle>
              <OrganizationCard
                component={Link}
                to={`${
                  competition?.company_profile?.guid
                    ? `/profile/${competition?.company_profile?.guid}`
                    : `/competitions/${slug}-${id}`
                }`}
              >
                <Avatar
                  radius="100%"
                  size={size990 ? 40 : size1280 ? 60 : 100}
                  src={competition?.company_profile?.profile_image}
                  alt="company-logo"
                />
                <OrganizationInfo>
                  <OrganizationName>
                    {competition?.company_profile?.first_name}{" "}
                    {competition?.company_profile?.last_name}
                  </OrganizationName>
                  <Text className="description-content" color="#697586">
                    {competition?.company_profile?.bio}
                  </Text>
                </OrganizationInfo>
              </OrganizationCard>
            </Section>

            <Section $isActive={activeSection === "location"} id="location">
              <SectionTitle
                className={activeSection === "location" ? "active" : ""}
              >
                {t("competitionDetail.location")}
              </SectionTitle>
              <OnlineBadgeWrapper>
                <OnlineBadge>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    {competition?.olympiad_type_output?.id === 4 ||
                    competition?.olympiad_type_output?.id === 5 ? (
                      <>
                        {t("competitionDetail.online")}{" "}
                        <a
                          className="description-content"
                          href={competition?.link}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <IconExternalLink size={18} color="#029199" />
                        </a>
                      </>
                    ) : competition?.olympiad_type_output?.id === 3 ||
                      competition?.olympiad_type_output?.id === 2 ? (
                      <>
                        {t("competitionDetail.hybrid")} -{" "}
                        {competition?.location}{" "}
                        <a
                          className="description-content"
                          href={competition?.link}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <IconExternalLink size={18} color="#029199" />
                        </a>
                      </>
                    ) : (
                      <div className="description-content">
                        {t("competitionDetail.offline")} -{" "}
                        {competition?.location}
                      </div>
                    )}
                  </span>
                  <TooltipWrapper>
                    <IconInfoCircle
                      style={{ marginTop: "2px" }}
                      fill="#FFA523"
                      color="#fff"
                      size={24}
                    />
                    <Tooltip>
                      {getTooltipMessage(competition?.olympiad_type_output?.id)}
                    </Tooltip>
                  </TooltipWrapper>
                </OnlineBadge>
              </OnlineBadgeWrapper>
            </Section>

            <Section
              $isActive={activeSection === "dateAndTime"}
              id="dateAndTime"
            >
              <SectionTitle
                className={activeSection === "dateAndTime" ? "active" : ""}
              >
                {t("competitionDetail.dateAndTime")}
              </SectionTitle>
              <TimelineContainer>
                <TimelineItem>
                  <TimeIcon>
                    <IconCalendar size={24} stroke={2} color="#029199" />
                  </TimeIcon>
                  <TimeDetails>
                    <TimeLabel>{t("competitionDetail.startDate")}</TimeLabel>
                    <TimeValue>
                      {formatDate(competition?.olympiad_dates[0]?.start_date)}
                    </TimeValue>
                    <TimeSubValue>
                      {competition?.olympiad_dates[0]?.start_time}
                    </TimeSubValue>
                  </TimeDetails>
                </TimelineItem>

                <TimelineDivider>
                  <DurationBadge>
                    {(() => {
                      try {
                        const utcOffset = 4 * 60 * 60 * 1000;
                        const startUTC = new Date(
                          new Date(
                            competition?.olympiad_dates[0]?.start_date
                          ).getTime() - utcOffset
                        );
                        const endUTC = new Date(
                          new Date(
                            competition?.olympiad_dates[0]?.end_date
                          ).getTime() - utcOffset
                        );
                        const diff = Math.abs(
                          (endUTC.getTime() - startUTC.getTime()) / (1000 * 60)
                        );

                        const days = Math.floor(diff / (24 * 60));
                        const hours = Math.floor((diff % (24 * 60)) / 60);
                        const minutes = Math.floor(diff % 60);

                        const parts = [];
                        if (days > 0) {
                          if (days === 1) {
                            parts.push(
                              `${days} ${t("competitionDetail.day.one")}`
                            );
                          } else {
                            parts.push(
                              `${days} ${t("competitionDetail.day.other")}`
                            );
                          }
                        }
                        if (hours > 0) {
                          if (hours === 1) {
                            parts.push(
                              `${hours} ${t("competitionDetail.hour.one")}`
                            );
                          } else {
                            parts.push(
                              `${hours} ${t("competitionDetail.hour.other")}`
                            );
                          }
                        }
                        if (minutes > 0 || parts.length === 0) {
                          if (minutes === 1) {
                            parts.push(
                              `${minutes} ${t("competitionDetail.minute.one")}`
                            );
                          } else {
                            parts.push(
                              `${minutes} ${t(
                                "competitionDetail.minute.other"
                              )}`
                            );
                          }
                        }

                        return (
                          <>
                            <IconHourglassHigh size={16} stroke={2} />
                            {parts.join(" ")}
                          </>
                        );
                      } catch (error) {
                        console.error("Time calculation error:", error);
                        return "0 dəqiqə";
                      }
                    })()}
                  </DurationBadge>
                </TimelineDivider>

                <TimelineItem>
                  <TimeIcon>
                    <IconClock size={24} stroke={2} color="#029199" />
                  </TimeIcon>
                  <TimeDetails>
                    <TimeLabel>{t("competitionDetail.endDate")}</TimeLabel>
                    <TimeValue>
                      {formatDate(competition?.olympiad_dates[0]?.end_date)}
                    </TimeValue>
                    <TimeSubValue>
                      {competition?.olympiad_dates[0]?.end_time}
                    </TimeSubValue>
                  </TimeDetails>
                </TimelineItem>
              </TimelineContainer>
            </Section>
          </ContentWrapper>
        )}
        {!isPaid && !isEnded && (
          <RightSideWrapper>
            <PriceSection>
              <PriceInfo>
                <Group style={{ width: "100%" }} align="center" position="apart">
                  <PriceLabel>{t("competitionDetail.price")}</PriceLabel>
                  <ShareButton onClick={handleShare}>
                    <IconShare2 size={20} />
                  </ShareButton>
                </Group>
                <PriceWrapper>
                  <PriceRow>
                    <OldPrice>
                      {competition.old_price} <IconCurrencyManat size={20} />
                    </OldPrice>
                    {competition?.old_price && (
                      <DiscountBadge>
                        -
                        {Math.round(
                          (1 - competition.price / competition.old_price) * 100
                        )}
                        % {t("competitionDetail.discount")}
                      </DiscountBadge>
                    )}
                  </PriceRow>
                  {competition?.old_price && (
                    <CurrentPrice>
                      {competition?.price} <IconCurrencyManat size={24} />
                    </CurrentPrice>
                  )}
                </PriceWrapper>
              </PriceInfo>
              <Box style={{ padding: "0 20px" }} className="price-buttons">
                <Button onClick={handleGetTickets}>
                  {t("competitionDetail.getTickets")}
                </Button>
                {competition?.community_link && (
                  <CommunityButton
                    href={getShareUrl()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconUsers size={20} />
                    {t("competitionDetail.joinCommunity")}
                  </CommunityButton>
                )}
              </Box>
            </PriceSection>

            <RelatedCoursesSection>
              <Accordion defaultValue="courses">
                <Accordion.Item value="courses">
                  <StyledAccordionControl>
                    {t("competitionDetail.intensiveCourses")}
                  </StyledAccordionControl>
                  <Accordion.Panel>
                    <CoursesList>
                      <CoursesScrollArea>
                        {relatedCourses?.map((course) => (
                          <CourseItem key={course.id}>
                            <CourseImage
                              src={course?.bootcamp_image}
                              alt={course?.id}
                            />
                            <CourseInfo>
                              <CourseTitle>{course?.bootcamp_name}</CourseTitle>
                            </CourseInfo>
                          </CourseItem>
                        ))}
                      </CoursesScrollArea>
                      <ShowMoreButton onClick={() => navigate("/bootcamps")}>
                        {t("competitionDetail.showMore")}
                      </ShowMoreButton>
                    </CoursesList>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </RelatedCoursesSection>
            <ShareDropdown
              isOpen={showShareDropdown}
              onClose={() => setShowShareDropdown(false)}
              url={getShareUrl()}
            />
          </RightSideWrapper>
        )}
      </GridContainer>

      {/* Starting Soon Modal */}

      <Modal
        opened={showRulesModal}
        onClose={() => {}}
        closeOnClickOutside={false}
        closeOnEscape={false}
        withCloseButton={false}
        size="lg"
        centered
      >
        <ModalContent style={{ textAlign: "left" }}>
          <ModalHeader>
            <IconAlertCircle size={48} color="#029199" />
            <Text size="xl" weight={600} mb={20}>
              {t("competitionDetail.competitionStartingSoon")}
            </Text>
          </ModalHeader>

          <Text size="md" mb={30} color="#364152">
            {t("competitionDetail.pleaseReviewRules")}
          </Text>

          <List
            spacing="md"
            size="md"
            icon={<IconChecklist size={24} color="#029199" />}
          >
            <List.Item>
              {t(
                "competitionDetail.rules.checkInternet",
                "İnternetinizi yoxlayın, əmin olun ki, internetə bağlısınız"
              )}
            </List.Item>
            <List.Item>
              {t(
                "competitionDetail.rules.taskNavigation",
                "Tasklar arasında keçid edə bilərsiniz"
              )}
            </List.Item>
            <List.Item>
              {t(
                "competitionDetail.rules.readCarefully",
                "Taskın suallarını diqqətlə oxuyun"
              )}
            </List.Item>
            <List.Item>
              {t(
                "competitionDetail.rules.completeButton",
                "Hər bir taskın suallarını bitirdikdən sonra ən sonda Complete Task butonu var, ona click edin, əks halda bütün cavablarınız 0 hesablanacaq"
              )}
            </List.Item>
            <List.Item>
              {t(
                "competitionDetail.rules.timeLimit",
                "Yarışmanın süresi sınırlıdır. Zamanınızı etkili kullanın."
              )}
            </List.Item>
          </List>

          <CountdownText>
            {t("competitionDetail.competitionStartsIn")}{" "}
            {formatRemainingTime(0, untilStart)}
          </CountdownText>
        </ModalContent>
      </Modal>

      {/* Time Warning Modal */}
      <Modal
        opened={showTimeWarningModal}
        onClose={() => setShowTimeWarningModal(false)}
        size="md"
        centered
        withCloseButton={false}
      >
        <ModalContent>
          <ModalHeader>
            <IconAlertCircle size={48} color="#FFA523" />
            <ModalTitle>{t("competitionDetail.timeIsAlmostUp")}</ModalTitle>
          </ModalHeader>
          <ModalText>{t("competitionDetail.timeWarning")} </ModalText>
          <ModalButton onClick={() => setShowTimeWarningModal(false)}>
            {t("competitionDetail.continueWorking")}
          </ModalButton>
        </ModalContent>
      </Modal>

      {/* Already Started Modal */}
      {showStartedModal && (
        <Modal
          opened={showStartedModal}
          onClose={() => setShowStartedModal(false)}
          size="md"
          centered
        >
          <ModalContent>
            <ModalHeader>
              <IconAlertCircle size={48} color="#FFA523" />
              <ModalTitle>
                {t("competitionDetail.competitionInProgress")}
              </ModalTitle>
            </ModalHeader>
            <ModalText>{t("competitionDetail.registerToPay")}</ModalText>
            <Button onClick={handleGetTickets}>
              {t("competitionDetail.registerNow")}
            </Button>
          </ModalContent>
        </Modal>
      )}

      {/* Ending Soon Modal */}
      <Modal
        opened={showEndingSoonModal}
        onClose={handleCloseEndWarning}
        size="md"
        centered
      >
        <ModalContent>
          <ModalHeader>
            <IconAlertCircle size={48} color="#FFA523" />
            <ModalTitle>
              {t("competitionDetail.twoMinutesRemaining")}
            </ModalTitle>
          </ModalHeader>
          <ModalText>{t("competitionDetail.completeAllTasks")} </ModalText>
          <Button onClick={handleCloseEndWarning}>
            {t("competitionDetail.continueWorking")}
          </Button>
        </ModalContent>
      </Modal>

      {/* Results Pending Modal */}
      <Modal
        opened={showResultsPendingModal}
        onClose={() => setShowResultsPendingModal(false)}
        size="md"
        centered
      >
        <ModalContent>
          <ModalHeader>
            <IconHourglassHigh size={48} color="#029199" />
            <ModalTitle>
              {t("competitionDetail.resultsBeingProcessed")}
            </ModalTitle>
          </ModalHeader>
          <ModalText>
            {activeSection === "myResults"
              ? t("competitionDetail.personalResultsReview")
              : t("competitionDetail.competitionResultsProcessing")}
          </ModalText>

          <Button onClick={() => setShowResultsPendingModal(false)}>
            {t("competitionDetail.close")}
          </Button>
        </ModalContent>
      </Modal>

      {status && (
        <NotificationModal
          modalOpened={modalOpened}
          closeModal={closeModal}
          status={status}
        />
      )}
    </StyledCompetitionDetail>
  );
};

export default CompetitionDetail;

const StyledCompetitionDetail = styled.div``;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.isPaid || props.isEnded ? "1fr" : "2fr 1fr"};

  gap: 24px;
  @media (max-width: 990px) {
    grid-template-columns: 1fr;
  }
`;

const Banner = styled.div`
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  height: 362px;
  position: relative;
  padding: 40px 120px 0 120px;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(12, 8, 15, 0.4);
    backdrop-filter: blur(20px);
  }

  @media (max-width: 768px) {
    height: 200px;
    padding: 20px 20px 0 20px;
  }
`;

const BannerInner = styled.div`
  height: 100%;
  position: relative;
  background-image: url(${(props) => props.backgroundImage});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  overflow: hidden;
`;

const BannerTitle = styled.h1`
  position: absolute;
  color: white;
  font-size: 36px;
  font-weight: 600;
  bottom: 40px;
  left: 40px;
  z-index: 99;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    font-size: 24px;
    bottom: 20px;
    left: 20px;
  }
`;

const Navigation = styled.div`
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  position: sticky;
  top: 70px;
  z-index: 9;
  transition: top 0.3s ease-in-out;

  @media (max-width: 768px) {
    top: 50px;
    padding: 5px 0;
  }

  nav {
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    gap: 40px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    @media (max-width: 768px) {
      gap: 24px;
      padding: 0 16px;
      white-space: nowrap;

      &::after {
        content: "";
        padding-right: 16px;
      }
    }
  }
`;

const NavItem = styled.button`
  background: none;
  border: none;
  padding: 10px 0px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  transition: color 0.3s;
  color: ${(props) => (props.$isActive ? "#029199" : "#979797")};

  &:hover {
    color: #029199;
  }

  @media (max-width: 1280px) {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 0;
  }
  @media (max-width: 990px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const ContentWrapper = styled.div`
  width: ${(props) => (props.$isPaid || props.$isEnded ? "90%" : "85%")};
  margin: 0 auto;
  padding: 0px 0 40px 0;

  @media (max-width: 1280px) {
    width: 90%;
    padding: 0px 0 30px 0;
  }
  @media (max-width: 990px) {
    width: 88%;
  }
`;

const Section = styled.section`
  margin-bottom: 12px;
  scroll-margin-top: 140px;
  padding: 20px 0;

  .description-content {
    color: #697586;
    font-size: 16px;
    @media (max-width: 1280px) {
      font-size: 14px;
    }
    @media (max-width: 990px) {
      font-size: 12px;
    }
  }

  &:first-child {
    padding-top: 40px;
  }

  &:last-child {
    padding-bottom: 100px;
    @media (max-width: 768px) {
      padding-bottom: 0px;
    }
  }
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #364152;
  margin-bottom: 24px;
  transition: color 0.3s;

  &.active {
    color: #029199;
  }

  @media (max-width: 1280px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
  @media (max-width: 990px) {
    font-size: 18px;
    margin-bottom: 16px;
  }
`;

// constText = styled.p`
//   color: #575757;
//   font-size: 16px;
//   line-height: 1.5;
//   margin-bottom: 16px;
// `;

const LevelBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  color: #575757;
  font-size: 14px;
`;

const Diamond = styled.div`
  width: 10px;
  height: 14px;
  background: ${(props) => props.$color};
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  border-radius: 4px;
`;

const Language = styled.div`
  color: #029199;
  font-size: 14px;
  font-weight: 500;
`;

const MentorCard = styled(Box)`
  display: flex;
  gap: 24px;
  align-items: flex-start;

  @media (max-width: 1280px) {
    gap: 16px;
  }
  @media (max-width: 990px) {
    align-items: center;
  }
`;

const MentorImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;

  @media (max-width: 1280px) {
    width: 100px;
    height: 100px;
  }
`;

const MentorInfo = styled.div`
  flex: 1;
`;

const MentorName = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: #029199;
  margin-bottom: 12px;

  @media (max-width: 1280px) {
    font-size: 16px;
    margin-bottom: 8px;
  }
  @media (max-width: 990px) {
    font-size: 14px;
    margin-bottom: 6px;
  }
`;

const OrganizationCard = styled(MentorCard)``;

const OrganizationLogo = styled.img`
  width: 120px;
  height: 120px;
  object-fit: contain;

  @media (max-width: 1280px) {
    width: 100px;
    height: 100px;
  }
  @media (max-width: 990px) {
    width: 80px;
    height: 80px;
  }
`;

const OrganizationInfo = styled(MentorInfo)``;

const OrganizationName = styled(MentorName)`
  font-size: 18px;
  font-weight: 600;
  color: #029199;
  margin-bottom: 12px;

  @media (max-width: 1280px) {
    font-size: 16px;
    margin-bottom: 8px;
  }
  @media (max-width: 990px) {
    font-size: 14px;
    margin-bottom: 6px;
  }
`;

const OnlineBadgeWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
`;

const OnlineBadge = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0px;
  border-radius: 8px;
  color: #697586;
  font-size: 14px;
  font-weight: 500;

  @media (max-width: 576px) {
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }

  a {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: inherit;

    &:hover {
      opacity: 0.8;
    }
  }
`;
const Tooltip = styled.div`
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background: #334155;
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 12px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  z-index: 1000;
  width: max-content;
  max-width: 200px;

  /* Mətn məhdudlaşdırma əlavə edildi */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: #334155 transparent transparent transparent;
  }

  @media (max-width: 576px) {
    text-align: center;
    width: 200px;
    left: auto;
    right: -10px;
    transform: none;

    &::before {
      left: auto;
      right: 12px;
      transform: none;
    }
  }
`;
const TooltipWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;

  &:hover ${Tooltip} {
    opacity: 1;
    visibility: visible;
  }
`;

const DateTimeInfo = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

const DateTimeItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #364152;
  font-size: 14px;
  @media (max-width: 990px) {
    font-size: 12px;
  }
`;

const RightSideWrapper = styled.div`
  position: sticky;
  top: 160px;
  height: fit-content;
  padding-top: 0px;
  width: 70%;
  margin: 10px auto 40px auto;
  z-index: 9;

  @media (max-width: 1280px) {
    width: 80%;
    margin: 10px auto 30px auto;
  }

  @media (max-width: 990px) {
    width: 95%;
    position: relative;
    z-index: 1;
    margin: 0 auto 40px auto;
    top: 0;
  }
`;

const PriceSection = styled.div`
  background: #fff;
  border-radius: 16px;
  margin-bottom: 24px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
  overflow: hidden;
`;

const PriceInfo = styled.div`
  padding: 24px;
  border-bottom: 1px solid #f1f5f9;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-top: 12px;
`;

const PriceRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

const PriceLabel = styled.span`
  color: #64748b;
  font-size: 15px;
  font-weight: 500;
`;

const OldPrice = styled.span`
  color: #ff0b0b;
  font-size: 30px;
  text-decoration: line-through;
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 4px;
`;

const CurrentPrice = styled.span`
  font-size: 24px;
  font-weight: 600;
  color: #000;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const DiscountBadge = styled.span`
  background: #dcfce7;
  color: #15803d;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 600;
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  background: #029199;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 12px;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }
`;

const CommunityButton = styled.a`
  background: #fff;
  color: #202224;
  border: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  border-radius: 12px;
  margin-bottom: 12px;
  gap: 8px;

  &:hover {
    background: #f9fafb;
  }
`;

const ShareButton = styled.button`
  background: #fff;
  color: #202224;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  border-radius: 12px;
  margin-bottom: 12px;
  gap: 8px;
  cursor: pointer;

  &:hover {
    background: #fff;
  }
`;

const RelatedCoursesSection = styled.div`
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 1px 8px 0px #0000001f;

  .mantine-Accordion-content {
    padding: 0;
  }

  .mantine-Accordion-item {
    border: none;
  }
`;

const StyledAccordionControl = styled(Accordion.Control)`
  padding: 16px;
  &:hover {
    background: transparent;
  }

  .mantine-Accordion-chevron {
    color: #029199;
  }
`;

const CoursesList = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const CoursesScrollArea = styled.div`
  max-height: 144px;
  overflow-y: scroll;
  padding: 0 16px;
  margin-right: 6px;

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 14px;
  }

  &::-webkit-scrollbar-thumb {
    background: #029199;
    border-radius: 4px;
    min-height: 40px;
  }

  /* Firefox üçün scroll stili */
  scrollbar-width: thin;
  scrollbar-color: #029199 transparent;
`;

const CourseItem = styled.div`
  display: flex;
  gap: 12px;
  padding: 12px 0;
  height: 72px;
  border-radius: 8px;
  transition: background 0.2s;
  cursor: pointer;
  align-items: center;

  @media (max-width: 1280px) {
    height: 60px;
    gap: 8px;
    padding: 8px 0;
  }

  &:hover {
    background: #f9fafb;
  }
`;

const CourseImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  object-fit: cover;

  @media (max-width: 1280px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 990px) {
    width: 32px;
    height: 32px;
  }
`;

const CourseInfo = styled.div`
  flex: 1;
`;

const CourseTitle = styled.h3`
  font-size: 14px;
  font-weight: 400;
  color: #575757;
  margin: 0;
  line-height: 1.4;
  display: -webkit-box;

  @media (max-width: 1280px) {
    font-size: 13px;
    line-height: 1.3;
  }
  @media (max-width: 990px) {
    font-size: 12px;
  }
`;

const ShowMoreButton = styled.button`
  width: 100%;
  padding: 12px 16px;
  background: none;
  border: none;
  color: #029199;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-align: left;

  &:hover {
    background: #f9fafb;
  }
`;

const CountdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  gap: 20px;
  padding: 40px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const CountdownTimer = styled.div`
  font-size: 48px;
  font-weight: 700;
  color: #029199;
  font-family: monospace;
  padding: 20px 40px;
  background: #f8fafc;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(2, 145, 153, 0.1);

  @media (max-width: 1280px) {
    font-size: 36px;
    padding: 16px 32px;
  }
  @media (max-width: 990px) {
    font-size: 24px;
    padding: 16px 24px;
  }
`;

const ModalContent = styled.div`
  padding: 32px;
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
`;

const ModalTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #202939;
  margin: 0;
`;

const ModalText = styled.p`
  font-size: 16px;
  color: #364152;
  margin-bottom: 32px;
  line-height: 1.5;
`;

const ModalButton = styled.button`
  width: 100%;
  padding: 12px;
  background: #029199;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #027b82;
  }
`;

const InfoSection = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: #f8fafc;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
`;

const CountdownText = styled.div`
  margin-top: 30px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #029199;
  padding: 16px;
  background: #f1fafa;
  border-radius: 8px;
  font-family: monospace;
`;

const SkillBadge = styled.div`
  background: #eafeff;
  border-radius: 9px;
  border: 0.5px solid #029199;
  padding: 6px 14px;
  font-size: 14px;
  font-weight: 500;
  color: #029199;
`;

const TimeRemaining = styled.div`
  margin-left: auto;
  width: max-content;
  padding: 8px 16px;
  background: ${(props) => (props.$isLastTwoMinutes ? "#FEE2E2" : "#F3F4F6")};
  color: ${(props) => (props.$isLastTwoMinutes ? "#DC2626" : "#374151")};
  border-radius: 8px;
  font-weight: 600;
  font-family: monospace;
  font-size: 16px;
`;

const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 0;
  // background: #F8FAFC;
  border-radius: 12px;
  margin-top: 16px;
`;

const TimelineItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const TimeIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const TimeDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TimeLabel = styled.span`
  font-size: 14px;
  color: #697586;
  font-weight: 500;
`;

const TimeValue = styled.span`
  font-size: 18px;
  color: #364152;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const TimeSubValue = styled.span`
  font-size: 14px;
  color: #029199;
  font-weight: 500;
`;

const TimelineDivider = styled.div`
  padding: 8px 0 8px 64px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 23px;
    top: -24px;
    bottom: -24px;
    width: 2px;
    background: #e2e8f0;
  }
`;

const DurationBadge = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: white;
  border-radius: 20px;
  font-size: 14px;
  color: #029199;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;
