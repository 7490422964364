import { useQuery } from "@tanstack/react-query";
import { useHighlight } from "./useHighlight";
import { getHighlight } from "../../services/apiHighlight";



export const useGetHighlight = (communityId, highlightId) => {
    return useQuery({
        queryKey: ['highlight',communityId, highlightId],
        queryFn: () => getHighlight({community_id: communityId, highlight_id: highlightId}),
        enabled: !!communityId && !!highlightId,
    })
}
