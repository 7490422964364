import { useMutation } from "@tanstack/react-query"
import { joinHighlight } from "../../services/apiHighlight"
import { useHighlight } from "./useHighlight";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const useJoinHighlight = (community_id,highlight_id) => {

    const {handleError,handleSuccess} = useHighlight();
    const navigate = useNavigate();
    const {t} = useTranslation()


    return useMutation({
        mutationFn: () => joinHighlight({community_id,highlight_id}),
        onSuccess: () => {
            handleSuccess(t("highlight_joined"));
            navigate(`/community/${community_id}/highlight/${highlight_id}`);
        },
        onError: (error) => handleError(error),
    })
}

