import axiosInstance from "../../Helpers/Axios";
import { Button, TextInput } from "@mantine/core";
import { PromoCodeStyle } from "./PaymentStyle";
import greenCheck from "../../Assets/SVGs/payment/check-green.svg";
import { X } from "tabler-icons-react";

export function PromoCode({ props }) {
  const {
    t,
    links,
    promoEntered,
    setPromoEntered,
    promoResponse,
    setPromoResponse,
    promoDoesNotExist,
    setPromoDoesNotExist,
    entryData,
    setCoursePrice,
    setDiscountPrice
  } = props;

  async function checkPromo(e) {
    e.preventDefault();

    if (promoEntered) {
      const response = await axiosInstance.get(links.get_promocode);

      if (promoDoesNotExist === true) {
        setPromoDoesNotExist(false);
      }

      setPromoResponse(response.data);
      if (response?.data?.price || response?.data?.price === 0) {
        setCoursePrice(response?.data?.price);
        setDiscountPrice(response?.data?.price)
      } else {
        setCoursePrice(entryData?.price);
        setDiscountPrice(entryData?.price)
      }

      if (response.status === 204) {
        setPromoDoesNotExist(true);
      }
    }
  }

  return (
    <PromoCodeStyle>
      {entryData?.id === 13 && <p> {t("pay_less")} </p>}

      <form style={{ width: "100%" }} onSubmit={(e) => checkPromo(e)}>
        <TextInput
          id="promo"
          value={promoEntered}
          placeholder={t("enter_promocode")}
          onChange={(e) => setPromoEntered(e.target.value)}
        />
        <Button
          className="apply-btn"
          variant="default"
          onClick={(e) => checkPromo(e)}
        >
          {t("check")}
        </Button>
      </form>

      {(promoDoesNotExist || promoResponse) && (
        <div className="submit">

           {promoDoesNotExist && (
            <p style={{display:"flex" ,alignItems : "flex-start"}} className="promo-code-note">
              <span className="promocode-company">Taskool</span>
              <span className="promocode-message promocode-red">{t('doesnt_exist_promocode')}</span>
              <span className="promocode-discount">
                <X color="red" strokeWidth={1.75} />
              </span>
            </p>
          )}
          {promoResponse && (
            <p className="promo-code-note">
              <span className="promocode-company">Taskool</span>
              <span className="promocode-message promocode-green ">{t('promo_code_applied')}</span>
              <span style={{textTransform : "lowercase"}} className="promocode-discount">
                <img src={greenCheck} alt="" /> -{promoResponse?.promocode[0]?.discount_percent}% {t('discount')}
              </span>
            </p>
          )}
         
        </div>
      )}
    </PromoCodeStyle>
  );
}
