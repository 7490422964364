import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Select,
  TextInput,
  FileInput,
  Button,
  Grid,
  Group,
  Loader,
} from "@mantine/core";
import { Eye, Paperclip } from "tabler-icons-react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../../../../Helpers/Axios";

const SkillEndorsementForm = ({
  t,
  handleActiveCreateForm,
  handleActiveEditForm,
  selectDatas,
  user,
  handleCreateUserSkill,
  existEndorsement,
  formType,
  endorsementId,
  handleActiveIcons,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const queryClient = useQueryClient();
  const fileInputRef = React.useRef(null);

  const { mutate: editUserSkill, isLoading } = useMutation({
    mutationFn: async (data) => {
      return await axios.patch(`/api/userskill/${endorsementId}/`, data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["userSkills"]);
      handleActiveEditForm(false);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("user", user?.id);
    formData.append("skill", data.skill);
    if (data.certificate) {
      formData.append("certificate", data.certificate);
    }
    formData.append("point", data.point);

    if (formType === "create") {
      handleCreateUserSkill(formData);
      handleActiveCreateForm(false);
    } else {
      editUserSkill(formData);
    }
  };

  useEffect(() => {
    if (formType === "edit" && existEndorsement) {
      setValue("skill", existEndorsement.skill.id);
      setValue("point", existEndorsement.point);
    }
  }, [existEndorsement, formType, setValue]);

  const handleBack = () => {
    if (formType === "edit") {
      handleActiveEditForm(false);
      handleActiveIcons(false);
    } else {
      handleActiveCreateForm(false);
    }
  };

  return (
    <Container formType={formType}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Col md={6}>
            <Controller
              name="skill"
              control={control}
              rules={{ required: t("certification_name_required") }}
              render={({ field }) => (
                <Select
                  radius={10}
                  size="md"
                  label={t("certificate-name")}
                  placeholder={t("select")}
                  onChange={(skill) => {
                    field.onChange(skill);
                    setValue("skill", skill);
                  }}
                  data={
                    selectDatas
                      ? selectDatas.map((selectData) => ({
                          value: selectData.id,
                          label: selectData.skill,
                        }))
                      : []
                  }
                  styles={{ marginBottom: 20 }}
                  value={field.value}
                  error={errors.skill?.message}
                />
              )}
            />
          </Grid.Col>

          <Grid.Col md={6}>
            <TextInput
              label={t("grade")}
              size="md"
              radius={10}
              placeholder={t("grade")}
              {...register("point", { required: t("grade_required") })}
              value={watch("point") || ""}
              styles={{ marginBottom: 20 }}
              error={errors.point?.message}
            />
          </Grid.Col>

          <Grid.Col md={12}>
            <StyledFileInput
              radius={10}
              size="md"
              placeholder={t("pdf-file")}
              label={t("certificate")}
              {...register("certificate", {
                required:
                  formType === "create" ? t("certificate_required") : false,
              })}
              onChange={(e) => setValue("certificate", e)}
              ref={fileInputRef}
              rightSection={
                formType === "create" ? (
                  <Button
                    variant="outline"
                    size="sm"
                    radius={10}
                    rightIcon={
                      <Paperclip
                        style={{ rotate: "-45deg" }}
                        size={15}
                        strokeWidth={1.5}
                        color={"black"}
                      />
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      fileInputRef.current?.click();
                    }}
                  >
                    {t("upload-file")}
                  </Button>
                ) : (
                  <Button
                    variant="outline"
                    component="a"
                    href={existEndorsement?.certificate}
                    target="_blank"
                    size="sm"
                    radius={10}
                    rightIcon={
                      <Eye size={15} strokeWidth={1.5} color={"black"} />
                    }
                  >
                    {t("preview")}
                  </Button>
                )
              }
              error={errors.certificate?.message}
            />
          </Grid.Col>
        </Grid>

        <Group mt={5} position="right">
          <Button
            styles={(theme) => ({
              root: {
                background: "#f9fafc",
                border: "1px solid #cdd5df",
                borderRadius: "10px",
                height: "43px",
                width: "135px",
                color: "#364152",
                fontSize: "16px",
                fontWeight: "500",
                "&:hover": {
                  background: "#f9fafc",
                },
              },
            })}
            mt={15}
            type="button"
            onClick={handleBack}
          >
            {t("back")}
          </Button>
          <Button
            styles={(theme) => ({
              root: {
                background: "#FE6027",
                borderRadius: "10px",
                height: "43px",
                width: "135px",
                color: "#fff",
                fontSize: "16px",
                fontWeight: "500",
                "&:hover": {
                  background: "#FE6027",
                },
              },
            })}
            mt={15}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader color="#fff" style={{ color: "#fff" }} variant="dots" />
            ) : (
              t("save")
            )}
          </Button>
        </Group>
      </form>
    </Container>
  );
};

export default SkillEndorsementForm;

const Container = styled.div`
  .mantine-o3oqoy {
    right: ${(props) =>
      props.formType === "create" ? "75px !important" : "56px !important"};
    button {
      border: 1px solid #cdd5df;
      font-size: 16px;
      font-weight: 500;
      color: #1f2a37;
    }
  }

  .mantine-rxtk87 {
    border: 1px solid #cdd5df;
    font-size: 16px;
    font-weight: 500;
    color: #1f2a37;
  }
`;

const StyledFileInput = styled(FileInput)`
  .mantine-FileInput-input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1 !important;
  }
`;
