import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePost } from "./usePost";
import { createPost } from "../../services/apiPost";
import { useTranslation } from "react-i18next";

export const useCreatePost = ({closeModal, resetStates,communityId,postTab,label}) => {
    const { handleSuccess, handleError} = usePost();
    const queryClient = useQueryClient();
    const {t} = useTranslation()
    return useMutation({
        mutationFn: ({id, data}) => {
            return createPost({id, data});
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['all',communityId,postTab] });
            handleSuccess(t("post_created_successfully"));
            resetStates();
            closeModal();
        },
        onError: (error) => handleError(error),
    });
};
    
