import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";

    export const useCategory = () => {
    const {t} = useTranslation()
    const queryKeys = {
        all: ['categories'],
        cover: (id) => ['category', id, 'cover'],
    }

    const handleError = (error) => {
        showNotification({
            title: t("error"),
            message: error?.message || t("error_happened"),
            color: 'red',
        });
    }   

    return {
        queryKeys,
        handleError,
    }
}