import React from "react"
import ContentLoader from "react-content-loader"

const UniversityListSkeleton = (props) => (
  <ContentLoader 
    speed={2}
    width="100%"
    height={400}
    viewBox="0 0 400 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    preserveAspectRatio="none"
    {...props}
  >
    <rect x="0" y="10" rx="2" ry="2" width="100%" height="62" /> 
    <rect x="0" y="82" rx="2" ry="2" width="100%" height="62" /> 
    <rect x="0" y="154" rx="2" ry="2" width="100%" height="62" /> 
    <rect x="0" y="226" rx="2" ry="2" width="100%" height="62" /> 
    <rect x="0" y="298" rx="2" ry="2" width="100%" height="62" /> 
    <rect x="0" y="370" rx="2" ry="2" width="100%" height="62" /> 

  </ContentLoader>
)

export default UniversityListSkeleton
