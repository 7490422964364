import React, { useContext } from "react";
import styled from "styled-components";
import Filter from "./Filter";
import Universities from "./UniversityList";
import { Button } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import CustomSearch from "./UniversityList/CustomSearch";
import { StudyAbroadContext } from "../../../Helpers/Contexts/StudyAbroadContext";
import { Link, useNavigate } from "react-router-dom";

const AllUniversities = ({
  t,
  applyTo,
  agentId,
  universities,
  cost,
  filterValues,
  setFilterValues,
  universityLoading,
}) => {
  const { professionIds, setProfessionIds } = useContext(StudyAbroadContext);

  const handleClearAll = () => {
    setFilterValues((prevValues) => ({
      ...prevValues,
      region: [],
      country: [],
      speciality: [],
      degree: [],
      price: 0,
      gs: "",
      search: "",
    }));
    setProfessionIds([]);
  };

  const xS = useMediaQuery("(max-width: 576px)");
  const navigate = useNavigate();
  const handleSendToAgents = () => {
    localStorage.setItem("professions", JSON.stringify(professionIds));
    navigate(`/agents/${agentId}/apply-form`);
  };

  return (
    <Container>
      {xS && <CustomSearch t={t} setFilterValues={setFilterValues} />}
      <Flex>
        <FilterContainer>
          <Filter
            props={{ t, filterValues, setFilterValues, handleClearAll, cost }}
          />

          {professionIds.length > 0 && applyTo === "agents" && (
            <Button
              radius={10}
              size="md"
              fullWidth
              onClick={handleSendToAgents}
              style={{
                backgroundColor: "#FE6027",
                fontSize: "16px",
                fontWeight: 400,
                marginTop: "15px",
              }}
            >
              {t("send-to-agents")}
            </Button>
          )}
        </FilterContainer>

          <UniversitiesContainer>
            <Universities
              props={{
                t,
                type: "apply_university",
                universities,
                professionIds,
                filterValues,
                setFilterValues,
                setProfessionIds,
                applyTo,
                universityLoading
              }}
            />
          </UniversitiesContainer>
       
      </Flex>
    </Container>
  );
};

export default AllUniversities;

const Container = styled.div`
  max-width: 92%;
  margin: 10px auto 50px auto;
  position: relative;
  @media (max-width: 576px) {
    margin: 20px auto 50px auto;
  }
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
`;
const FilterContainer = styled.div`
  width: 26.5%;
  position: sticky;
  top: 100px;
  height: 100%;
  @media (max-width: 1350px) {
    width: 25.5%;
  }
  @media (max-width: 1250px) {
    width: 24.5%;
  }

  @media (max-width: 1200px) {
    width: 100%;
    position: relative;
    top: 0;
  }
`;
const UniversitiesContainer = styled.div`
  width: 72%;
  @media (max-width: 1350px) {
    width: 73%;
  }
  @media (max-width: 1250px) {
    width: 74%;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
`;
