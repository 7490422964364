import React, { useState } from "react";
import steamLand from "../Assets/Images/steamland.png";
import audience from "../Assets/Images/audience.png";

import {
  Box,
  Text,
  Title,
  TextInput,
  Button,
  Textarea,
  Grid,
} from "@mantine/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  IconBuilding,
  IconBuildingBank,
  IconSchool,
} from "@tabler/icons-react";
import {
  AnimatedSVG,
  AnimatedXsSVG,
} from "../Components/Animation/AnimatedSVG";
import { useMediaQuery } from "@mantine/hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import axios from "../Helpers/Axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { RightBox } from "../Components/SteamLand/RightBox";
import { useForm } from "react-hook-form";
import NotificationModal from "../Components/Modals/NotificationModal";
import {
  BannerSkeleton,
  RightBoxSkeleton,
  AudienceSkeleton,
  PartnersSkeleton,
  ContactFormSkeleton,
} from "../Components/Skeletons/SteamLandSkeletons";

const SteamLand = () => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width: 768px)");
  const lg = useMediaQuery("( max-width: 1280px)");
  const md = useMediaQuery("(max-width: 1024px)");
  const xl = useMediaQuery("(min-width: 1281px)");

  const { data: steamLandData, isLoading } = useQuery({
    queryKey: ["steamLandData"],
    queryFn: () => axios.get(`steam/steamland/`),
  });

  const { mutate: contactForm } = useMutation({
    mutationFn: (data) => axios.post(`steam/forms/send-form/`, data),
  });

  const generalSectionData = steamLandData?.data?.steamland?.find(
    (item) => item.general_text_title
  );
  const rightBoxDatas = steamLandData?.data?.steamland?.filter(
    (item) => item.right_text_title
  );
  const ourMissionData = steamLandData?.data?.steamland?.find(
    (item) => item.bottom_text_title
  );
  const partnersData = steamLandData?.data?.partners;

  const audienceData = {
    title: "Audience",
    image: audience,
    targets: [
      {
        icon: <IconBuilding />,
        label: "Məktəb şagirdləri",
        color: "#FF7F51",
        bgColor: "#FFF2EE",
      },
      {
        icon: <IconBuildingBank />,
        label: "Universitet tələbələri",
        color: "#A7CA52",
        bgColor: "#FAFFEF",
      },
      {
        icon: <IconSchool />,
        label: "Məzunlar",
        color: "#029199",
        bgColor: "#F5FEFF",
      },
    ],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const onSubmit = (data) => {
    contactForm(data, {
      onSuccess: () => {
        setIsSuccessModalOpen(true);
        reset();
      },
      onError: (error) => {
        console.error("Form submission error:", error);
      },
    });
  };
  if (isLoading) {
    return (
      <>
        <BannerSkeleton />
        <RightBoxSkeleton />
        <AudienceSkeleton />
        <PartnersSkeleton />
        <ContactFormSkeleton />
      </>
    );
  }
  return (
    <>
      <Banner>
        <div className="banner-content">
          <Title
            mb={10}
            size={matches ? "28px" : lg ? "35px" : "45px"}
            color="#fff"
            weight={600}
            style={{
              textAlign: "left",
              lineHeight: matches ? "40px" : "58px",
            }}
          >
            STEAMLAND Edtech Factory
          </Title>
          <Text
            size={matches ? "17px" : lg ? "20px" : "22px"}
            color="#fff"
            weight={500}
            style={{
              textAlign: "left",
              lineHeight: matches ? "28px" : lg ? "30px" : "34px",
            }}
          >
            Biz məktəblərdən universitetlərə STEAM əsaslı körpü qururuq.
            <br />
            (Məktəblər, universitetlər və onlayn platformalar üçün STEAM əsaslı
            təhsil kontenti)
          </Text>
        </div>
      </Banner>

      <div style={{ paddingTop: "100px" }}>
        <RightBox props={{ t, rightBoxDatas, generalSectionData }} />
      </div>

      <Audience>
        <div className="audience-content">
          <Title
            component="h3"
            size={xl ? "28px" : lg ? "24px" : md && "20px"}
            weight={700}
            color="#1A5059"
            mb={20}
          >
            {audienceData.title}
          </Title>
          <img src={audienceData.image} alt={audienceData.title} />
        </div>
        <div className="audience-targets">
          {audienceData.targets.map((target, index) => (
            <AudienceTarget
              color={target.color}
              bgcolor={target.bgColor}
              className="audience-target"
              key={index}
            >
              <div className="audience-target-icon">{target.icon}</div>
              <div className="audience-target-label">{target.label}</div>
            </AudienceTarget>
          ))}
        </div>
      </Audience>

      <OurMission>
        <div className="our-mission-content">
          <div
            dangerouslySetInnerHTML={{ __html: ourMissionData?.content }}
            className="our-mission-description"
          />
        </div>
        <div className="our-mission-image">
          {matches ? <AnimatedXsSVG /> : <AnimatedSVG />}
        </div>
      </OurMission>

      <Title
        component="h3"
        size={xl ? "28px" : lg ? "24px" : md && "20px"}
        weight={700}
        color="#1A5059"
        mb={20}
        align={matches ? "left" : "center"}
        style={{
          maxWidth: matches ? "90%" : "100%",
          margin: matches ? "0 auto" : "0",
        }}
      >
        Partners

      </Title>



      <Box
        sx={{
          backgroundColor: "#fff",
          boxShadow: "0px 4px 15px 0px #0000001a",
          margin: "40px 0 80px 0",
          padding: "30px 20px",
        }}
      >
        {/* Grid Layout for Larger Screens */}
        <Grid
          gutter="md"
          sx={(theme) => ({
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
            alignItems: "center",
            gap: "20px",
            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
              display: "none",
            },
          })}
        >
          {partnersData?.map((partner) => (
            <Box
              key={partner.id}
              component="a"
              href={partner.link}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textAlign: "center" }}
            >
              <div dangerouslySetInnerHTML={{ __html: partner.svg_icon }} />
            </Box>
          ))}
        </Grid>

        {/* Swiper for Mobile Screens */}
        <StyleBox
          sx={(theme) => ({
            display: "none",
            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
              display: "block",
            },
          })}
        >
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            slidesPerView={1}
            modules={[Navigation, Autoplay]}
            speed={10000}
            loop={partnersData?.length > 3}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            }}
            style={{
              width: "100%",
              height: "100%",
              padding: "0",
            }}
            className="partners-swiper"
          >
            {partnersData?.map((partner) => {
              const svgBlob = partner?.svg_icon
                ? new Blob([partner.svg_icon], { type: "image/svg+xml" })
                : null;
              const svgUrl = svgBlob ? URL.createObjectURL(svgBlob) : null;
              return (
                <SwiperSlide
                  key={partner.id}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "80%",
                    height: "80%",
                  }}
                >
                  <Box
                    component="a"
                    href={partner.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <object
                      type="image/svg+xml"
                      data={svgUrl}
                      style={{ 
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        pointerEvents: "none",
                        width: "140px",
                        height: "auto",
                        transition: "all 0.3s ease",


                      }}
                      aria-label={partner?.name || "Partner icon"}
                    />
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </StyleBox>
      </Box>

      <ContactFormSection>
        <Title
          component="h3"
          size={xl ? "28px" : lg ? "24px" : md && "20px"}
          weight={700}
          color="#1A5059"
          mb={40}
          align="center"
        >
          Contact Form
        </Title>
        <ContactFormWrapper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>
              <FormField>
                <TextInput
                  {...register("name", { required: "Name is required" })}
                  label="Name"
                  placeholder="Enter your name"
                  error={errors.name?.message}
                  styles={{
                    label: {
                      color: "#707070",
                      fontWeight: 500,
                      marginBottom: "8px",
                    },
                    input: {
                      height: "45px",
                      border: "1px solid #D7D7D7",
                      "&:focus": {
                        borderColor: "#029199",
                      },
                    },
                  }}
                />
              </FormField>
              <FormField>
                <TextInput
                  {...register("surname", { required: "Surname is required" })}
                  label="Surname"
                  placeholder="Enter your surname"
                  error={errors.surname?.message}
                  styles={{
                    label: {
                      color: "#707070",
                      fontWeight: 500,
                      marginBottom: "8px",
                    },
                    input: {
                      height: "45px",
                      border: "1px solid #D7D7D7",
                      "&:focus": {
                        borderColor: "#029199",
                      },
                    },
                  }}
                />
              </FormField>
            </InputGroup>

            <InputGroup>
              <FormField>
                <TextInput
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  type="email"
                  label="Email"
                  placeholder="Enter your email"
                  error={errors.email?.message}
                  styles={{
                    label: {
                      color: "#707070",
                      fontWeight: 500,
                      marginBottom: "8px",
                    },
                    input: {
                      height: "45px",
                      border: "1px solid #D7D7D7",
                      "&:focus": {
                        borderColor: "#029199",
                      },
                    },
                  }}
                />
              </FormField>
              <FormField>
                <TextInput
                  {...register("phone", { required: "Phone is required" })}
                  label="Phone"
                  placeholder="Enter your phone number"
                  error={errors.phone?.message}
                  styles={{
                    label: {
                      color: "#707070",
                      fontWeight: 500,
                      marginBottom: "8px",
                    },
                    input: {
                      height: "45px",
                      border: "1px solid #D7D7D7",
                      "&:focus": {
                        borderColor: "#029199",
                      },
                    },
                  }}
                />
              </FormField>
            </InputGroup>

            <FormField>
              <TextInput
                {...register("company_name", {
                  required: "Company name is required",
                })}
                label="Company name"
                placeholder="Enter company name"
                error={errors.company_name?.message}
                styles={{
                  label: {
                    color: "#707070",
                    fontWeight: 500,
                    marginBottom: "8px",
                  },
                  input: {
                    height: "45px",
                    border: "1px solid #D7D7D7",
                    "&:focus": {
                      borderColor: "#029199",
                    },
                  },
                }}
              />
            </FormField>

            <FormField>
              <Textarea
                {...register("message", { required: "Message is required" })}
                label="Message"
                placeholder="Write your message here..."
                error={errors.message?.message}
                minRows={4}
                styles={{
                  label: {
                    color: "#707070",
                    fontWeight: 500,
                    marginBottom: "8px",
                  },
                  input: {
                    border: "1px solid #D7D7D7",
                    "&:focus": {
                      borderColor: "#029199",
                    },
                  },
                }}
              />
            </FormField>

            <SubmitButton type="submit">SEND</SubmitButton>
          </form>
        </ContactFormWrapper>
      </ContactFormSection>

      <NotificationModal
        modalOpened={isSuccessModalOpen}
        closeModal={() => setIsSuccessModalOpen(false)}
        status="Accepted"
        message="Mesajınız qəbul edildi. Sizinlə tezliklə əlaqə saxlanılacaq."
      />
    </>
  );
};

export default SteamLand;

const Partners = styled(Box)`
  background-color: #fff;
  box-shadow: 0px 4px 15px 0px #0000001a;
  margin: 40px 0 80px 0;
`;

const StyleBox = styled(Box)`
 .swiper-wrapper {
  display: flex;
  align-items:center;
 }
`;


const Banner = styled(Box)`
  background-image: url(${steamLand});

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 362px;
  object-fit: cover;
  .banner-content {
    padding: 140px 0 0 60px;
    @media (max-width: 990px) {
      padding: 0;
      max-width: 90%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 990px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Audience = styled(Box)`
  padding-top: 50px;
  @media (max-width: 768px) {
    padding-top: 90px;
  }
  .audience-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      max-width: 90%;
      margin: 0 auto;
      align-items: flex-start;
    }
  }

  .audience-targets {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 50px;
    @media (max-width: 768px) {
      max-width: 90%;
      margin: 50px auto 0 auto;
      flex-direction: column;
      gap: 20px;
      justify-content: flex-start;
      align-items: flex-start;
    }

  }
`;

const AudienceTarget = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid ${(props) => props.color};
  padding: 15px 55px;
  border-radius: 10px;
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.color};
  .audience-target-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .audience-target-title {
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => props.color};
  }
  @media (max-width: 768px) {
    padding: 10px 25px;
  }
`;

const OurMission = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  max-width: 90%;
  margin: 100px auto;

  h3 {
    font-size: 22px;
    font-weight: 700;
    color: #1a5059;
    margin-bottom: 20px;
    @media (max-width: 1280px) {
      font-size: 24px;
    }
    @media (max-width: 1024px) {
      font-size: 20px;
    }
  }
  p {
    font-size: 16px;
    font-weight: 400;
    color: #797979;
    margin-bottom: 20px;
    @media (max-width: 1280px) {
      font-size: 15px;
    }
  }

  .our-mission-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 80%;
    @media (max-width: 768px) {
      max-width: 100%;
      align-items: center;
      justify-content: center;
    }
  }
  .our-mission-image {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 40px;

    @media (max-width: 1280px) {
      width: 75%;
      height: 75%;
      padding-right: 0;
      margin: 0 auto 20px auto;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    @media (max-width: 768px) {
      padding-right: 0;
      justify-content: center;
      width: 70%;
      height: 70%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      display: none;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;

    .our-mission-description {
      margin-top: 35px;
      h3 {
        text-align: left;
      }
      p {
        text-align: left;
      }
    }
  }
`;

const ContactFormSection = styled.div`
  max-width: 90%;
  margin: 0 auto 80px auto;
  @media (max-width: 768px) {
    margin: 180px auto 80px auto;
  }
`;

const ContactFormWrapper = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 32px;
  box-shadow: 0px 0px 10px 0px #00000014;
  max-width: 45%;
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const InputGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
    gap: 0px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 0px;
  }
`;

const FormField = styled.div`
  margin-bottom: 24px;
  @media (max-width: 1280px) {
    margin-bottom: 15px;
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;
  height: 45px;
  background-color: #fe6027;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;

  &:hover {
    background-color: #ff6b38;
    opacity: 0.9;
  }
`;
