import React, { useState } from "react";
import { ViewContainer } from ".";
import CustomAccordion from "../../../Components/Profile/CustomAccordion";
import EndorsementView from "../../../Components/Profile/EndorsementView";
import CertificateForm from "./ProfileForms/CertificateForm";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";
import { Group, Stack } from "@mantine/core";

const Certificate = ({t, user,publicUser }) => {
  const [isCreateFormActive, setIsCreateFormActive] = useState(false);
  const [isEditFormActive, setIsEditFormActive] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [selectedCertificateId, setSelectedCertificateId] = useState();
  const queryClient = useQueryClient();

  const link = publicUser ? `/api/certificate?guid=${publicUser.guid}` : `/api/certificate/`;
  const { data: userCertificates } = useQuery({
    queryKey: publicUser ? ["userCertificates",publicUser.guid] : ["userCertificates"],
    queryFn: async () => {
      try {
        const response = await axios.get(link);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
  });

  const { mutate: deleteCertificate } = useMutation({
    mutationFn: async (id) => {
      await axios.delete(`/api/certificate/${id}/`);
    },
    onSuccess: () => {
      setSelectedCertificateId(null);
      queryClient.invalidateQueries(["userCertificates"]);
    },
  });


  return (
    <ViewContainer>
      <div style={{ padding: "10px 20px" }}>
        <CustomAccordion
          defaultValue="certificate"
          value="certificate"
          data={userCertificates}
          title={t('certificate')}
          handleActiveCreateForm={setIsCreateFormActive}
          isCreateFormActive={isCreateFormActive}
          handleActiveIcons={setShowIcons}
          isShowIcons={showIcons}
          handleId={setSelectedCertificateId}
          isPublic = {publicUser ? true : false}
        >
          {isCreateFormActive || isEditFormActive ? (
            <CertificateForm
            t={t}
              user={user}
              certificateId={selectedCertificateId}
              formType={isCreateFormActive ? "create" : "edit"}
              handleActiveCreateForm={setIsCreateFormActive}
              handleActiveEditForm={setIsEditFormActive}
              handleActiveIcons={setShowIcons}

            />
          ) : (
            <Stack spacing={10} align="flex-start">
              {userCertificates?.map((cert, index) => (
                  <EndorsementView
                    key={cert?.id}
                    t={t}
                    handleDataId={setSelectedCertificateId}
                    handleActiveEditForm={setIsEditFormActive}
                    handleDeleteUserSkill={deleteCertificate}
                    isShowIcons={showIcons}
                    data={cert}
                    type="certificate"
                  />
              ))}
            </Stack>
          )}
        </CustomAccordion>
      </div>
    </ViewContainer>
  );
};

export default Certificate;
