import Lottie from "lottie-react";
import emailVerify from "../../Assets/Lottie/letterJSon.json";
import { useNavigate } from "react-router-dom";
import { Button, Stack, Text, Title } from "@mantine/core";
import styled from "styled-components";
import { useMutation } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import {
  showNotification,
} from "@mantine/notifications";
import { Confetti } from "tabler-icons-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
const EmailVerify = () => {
  const {pathname} = window.location;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mutate: verifyEmail } = useMutation({
    mutationFn: async () =>
      await axios.get(`/email-verify?token=${pathname.slice(20)}`),
    onSuccess: () => {
      showNotification({
        icon: <Confetti />,
        title: t("congratulations"),
        message: t("you_have_successfully_confirmed"),
        color: "teal",
      });
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  useEffect(() => {}, []);
  return (
      <Container>
        <Lottie
          animationData={emailVerify}
          style={{
            width: "30%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        />
        <Content>
          <Stack align="center">
            <Title>Verify your email address</Title>
            <Text align="center">
              Please verify this email address by clicking button below
            </Text>
            <Button className="verify-btn" onClick={verifyEmail}>
              Verify your email
            </Button>
          </Stack>
        </Content>
      </Container>
  );
};

export default EmailVerify;

const Container = styled.div`
  .verify-btn {
    background-color: #f5df4d;
    color: #463401;
    border-radius: 10px;
  }
`;
const Content = styled.div`
  width: 50%;
  margin: 0px auto 40px auto;
`;
