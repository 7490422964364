import { useMutation } from "@tanstack/react-query";
import { joinCommunity } from "../../services/apiCommunity";
import { useCommunity } from "./useCommunity";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export const useJoinCommunity = (community_id) => {
  const {t} = useTranslation()
  const { handleError, handleSuccess } = useCommunity();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: () => joinCommunity(community_id),
    onSuccess: () => {
      handleSuccess(t("community_joined"));
      navigate(`/community/${community_id}`);
    },
    onError: (error) => {
    //   handleError(error?.response?.data);
    if(error?.response?.data[0] === "User joined this community.")
    navigate(`/community/${community_id}`);
    },
  });
};
