
import { getAllHighlight } from '../../services/apiHighlight'
import { useQuery } from '@tanstack/react-query'

const useGetAllHighligts = (id,page=1) => {
    return useQuery({
        queryKey:['highlights',id,page],
        queryFn:()=>getAllHighlight(id,page),
        enabled:!!id
    })
}

export default useGetAllHighligts